import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BsCameraVideo, BsTelephone, BsMicFill, BsFillImageFill, BsSend, BsPaperclip, BsEmojiSmile, BsCheck, BsCheckAll } from "react-icons/bs"; // Add BsCheck and BsCheckAll icons
import { FaFileAlt } from "react-icons/fa";
import EmojiPicker from 'emoji-picker-react';
import "./chatPage.css";

const ChatPage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { avatar, userName } = state || {};

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, messageId: null });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    const fetchChatMessages = async () => {
      const chatMessages = [
        { id: 1, sender: "me", text: "Hey there!", timeStamp: "10:00 AM", status: "read" },
        { id: 2, sender: userName, text: "Hello!", timeStamp: "10:01 AM", status: "delivered" },
        { id: 3, sender: "me", text: "How are you?", timeStamp: "10:02 AM", status: "sent" },
        { id: 4, sender: userName, text: "I'm good, thanks! How about you?", timeStamp: "10:03 AM", status: "sent" },
      ];
      setMessages(chatMessages);
    };
    fetchChatMessages();
  }, [id, userName]);

  const handleSendMessage = (message) => {
    if (message && message.trim() !== "") {
      const newMessageObj = {
        id: messages.length + 1,
        sender: "me",
        text: message,
        timeStamp: new Date().toLocaleTimeString(),
        status: "sent", // Initial status is "sent"
      };
      setMessages([...messages, newMessageObj]);
      setNewMessage("");

      console.log("Message sent:", newMessageObj);

      // Simulate the message being delivered and read
      setTimeout(() => {
        updateMessageStatus(newMessageObj.id, "delivered");
      }, 1000); // Simulate delivery after 1 second

      setTimeout(() => {
        updateMessageStatus(newMessageObj.id, "read");
      }, 2000); // Simulate read after 2 seconds
    }
  };

  const updateMessageStatus = (messageId, newStatus) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message.id === messageId ? { ...message, status: newStatus } : message
      )
    );

    console.log(`Message ${messageId} status updated to:`, newStatus);
  };

  const toggleUploadOptions = () => {
    setShowUploadOptions(!showUploadOptions);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Uploaded file:", file);
      // Kingsley, you can add your BE logic to handle file upload logic here
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    // Add BE logic to start recording
  };

  const stopRecording = () => {
    setIsRecording(false);
    // Add the BE logic to stop recording and save the audio file
  };

  const handleContextMenu = (event, messageId) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      messageId,
    });
  };

  const handleTouchStart = (messageId) => {
    setContextMenu({
      visible: true,
      x: 0,
      y: 0,
      messageId,
    });
  };

  const handleOptionClick = (option) => {
    const messageId = contextMenu.messageId;
    if (option === "delete") {
      setMessages(messages.filter(message => message.id !== messageId));
    }
    // I use this for a user to share and forward messages
    setContextMenu({ visible: false, x: 0, y: 0, messageId: null });
  };

  const handleEmojiClick = (emojiObject) => {
    if (emojiObject && emojiObject.emoji) {
      setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
    }
    setShowEmojiPicker(false);
  };

  const renderMessageStatus = (status) => {
    switch (status) {
      case "sent":
        return <BsCheck />;
      case "delivered":
        return <BsCheckAll />;
      case "read":
        return <BsCheckAll style={{ color: "green" }} />;
      default:
        return null;
    }
  };

  return (
    <div className="chat-page">
      <div className="chat-page-header">
        {avatar && <img src={avatar} alt="User Avatar" className="avatar-image" />}
        <div className="chat-page-header-info">
          <h2>{userName}</h2>
          <p>Last seen at 10:00 AM</p>
        </div>
        <div className="chat-page-header-icons">
          <BsCameraVideo size={24} />
          <BsTelephone size={24} />
        </div>
      </div>
      <div className="chat-page-messages">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message ${message.sender === "me" ? "sent" : "received"}`}
            onContextMenu={(e) => handleContextMenu(e, message.id)}
            onTouchStart={() => handleTouchStart(message.id)}
          >
            <p>{message.text}</p>
            <span className="message-timestamp">
              {message.timeStamp} {message.sender === "me" && renderMessageStatus(message.status)}
            </span>
          </div>
        ))}
        {contextMenu.visible && (
          <div
            className="context-menu"
            style={{ top: contextMenu.y, left: contextMenu.x }}
          >
            <div onClick={() => handleOptionClick("delete")}>Delete</div>
            <div onClick={() => handleOptionClick("share")}>Share</div>
            <div onClick={() => handleOptionClick("forward")}>Forward</div>
          </div>
        )}
      </div>
      <div className="chat-input-container">
        <BsFillImageFill size={24} className="chat-input-icon" onClick={toggleUploadOptions} />
        {showUploadOptions && (
          <div className="upload-options">
            <label>
              <BsFillImageFill size={24} />
              <input type="file" accept="image/*" onChange={handleFileUpload} hidden />
              <span>Gallery</span>
            </label>
            <label>
              <BsCameraVideo size={24} />
              <input type="file" accept="video/*" onChange={handleFileUpload} hidden />
              <span>Camera</span>
            </label>
            <label>
              <BsMicFill size={24} />
              <input type="file" accept="audio/*" onChange={handleFileUpload} hidden />
              <span>Audio</span>
            </label>
            <label>
              <FaFileAlt size={24} />
              <input type="file" onChange={handleFileUpload} hidden />
              <span>Documents</span>
            </label>
          </div>
        )}
        <BsMicFill size={24} className="chat-input-icon" onClick={isRecording ? stopRecording : startRecording} />
        <div className="input-wrapper">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message"
            className="chat-input"
          />
          <BsEmojiSmile size={24} className="emoji-icon" onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
          {showEmojiPicker && (
            <EmojiPicker onEmojiClick={handleEmojiClick} pickerStyle={{ position: 'absolute', bottom: '50px', right: '0px' }} />
          )}
        </div>
        <BsSend size={24} className="chat-input-icon send-icon" onClick={() => handleSendMessage(newMessage)} />
      </div>
    </div>
  );
};

export default ChatPage;
