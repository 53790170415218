import React, { useEffect, useState } from "react";
import "./livePage.css";
import video from "../../assets/video-live.svg";
import toast from "react-hot-toast";
import { LocalUser } from "agora-rtc-react";
import {
	FaMicrophone,
	FaMicrophoneSlash,
	FaCamera,
	FaPhone,
	FaPhoneSlash,
} from "react-icons/fa";
import { MdOutlineVideocamOff } from "react-icons/md";

const LivePage = ({
	stopLive,
	Go_Live,
	audioTrack,
	cameraOn,
	micOn,
	videoTrack,
	setMic,
	setCamera,
	setCalling,
	calling,
	setStartLive,
	channel,
}) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const [showVideo, setShowVideo] = useState(false);
	const StartLive = () => {
		if (title.trim() !== "" && description.trim() !== "") {
			Go_Live({ title, description });
			setShowVideo(true);
			return;
		}
		toast.error("Title/Description cannot be blank");
	};
	useEffect(() => {
		return () => {
			if (calling) {
				stopLive(channel);
			}
		};
	}, []);
	return (
		<div>
			{showVideo === false && (
				<div className="stop_live">
					<div
						onClick={() => {
							setStartLive(false);
						}}
					>
						back
					</div>
				</div>
			)}

			<div className="livepage">
				{showVideo ? (
					<LocalUser
						audioTrack={audioTrack}
						cameraOn={cameraOn}
						micOn={micOn}
						videoTrack={videoTrack}
						volume={0}
						cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
					>
						<samp className="user-name">You</samp>
					</LocalUser>
				) : (
					<div className="livepage__video">
						<img
							src={video}
							alt="live video"
						/>
					</div>
				)}

				{showVideo ? (
					<div className="control">
						<div className="left-control">
							<button
								className="btn-vc"
								onClick={() => setMic((a) => !a)}
							>
								{micOn ? (
									<FaMicrophone className="icon-mic" />
								) : (
									<FaMicrophoneSlash className="icon-mic-off" />
								)}
							</button>
							<button
								className="btn-vc"
								onClick={() => setCamera((a) => !a)}
							>
								{cameraOn ? (
									<FaCamera className="icon-camera" />
								) : (
									<MdOutlineVideocamOff className="icon-camera-off" />
								)}
							</button>
						</div>
						<button
							className={`btn-vc btn-vc-phone ${
								calling ? "btn-vc-phone-active" : ""
							}`}
							onClick={() => setCalling((a) => !a)}
						>
							{calling ? (
								<FaPhoneSlash
									className="icon-phone-hangup"
									onClick={() => {
										stopLive(channel);
									}}
								/>
							) : (
								<FaPhone className="icon-phone" />
							)}
						</button>
					</div>
				) : (
					<div className="livepage__video__button">
						<input
							type="text"
							placeholder="title"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
						<input
							type="text"
							placeholder="description"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>

						<div onClick={StartLive}>Go Live Now</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LivePage;
