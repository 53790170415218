import React, { useEffect, useState } from "react";
import "./signUp.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../../store/slices/auth";
import { countries } from "../../Profile/countries";
import { Loader } from "../../../utils/loader/loader";

const Signup = () => {
	const dispatch = useDispatch();
	const [showPopup, setShowPopup] = useState(false);
	const [formData, setFormData] = useState({
		username: "",
		fullname: "",
		gender: "",
		dob: "",
		occupation: "",
		country: "",
		phone: "",
		email: "",
		password: "",
		confirmPassword: "",
	});
	const navigate = useNavigate();
	const shouldRedirect = useSelector((state) => {
		return state.entities.auth.signup.shouldRedirect;
	});
	const loading = useSelector((state) => {
		return state.entities.auth.signup.loading;
	});
	useEffect(() => {
		if (shouldRedirect) {
			setTimeout(() => {
				navigate("/");
			}, 2000);
		}
		return () => {};
	}, [dispatch, shouldRedirect]);

	const handleChange = (e) => {
		const { id, name, value } = e.target;

		if (name === "gender") {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
			return;
		}
		if (name === "username") {
			setFormData((prevData) => ({
				...prevData,
				[id]: value.trim().replace(/\s+/g, ""),
			}));
			return;
		}
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handleSignUpClick = (e) => {
		e.preventDefault();
		setShowPopup(true);
	};

	const handleYesClick = () => {
		setShowPopup(false);
		dispatch(signup(formData));
	};

	const handleNoClick = () => {
		setShowPopup(false);
	};

	return (
		<div className="signup__container">
			<div className="login__form">
				<h2>Sign Up</h2>
				<input
					type="text"
					id="username"
					placeholder="Enter Username"
					value={formData.username}
					onChange={handleChange}
				/>
				<input
					type="text"
					id="fullname"
					placeholder="Enter Fullname"
					value={formData.fullname}
					onChange={handleChange}
				/>
				<p>Gender</p>
				<div className="gender-field">
					<label htmlFor="male">Male</label>
					<input
						type="radio"
						id="male"
						name="gender"
						value="male"
						checked={formData.gender === "male"}
						onChange={handleChange}
					/>
					<label htmlFor="female">Female</label>
					<input
						type="radio"
						id="female"
						name="gender"
						value="female"
						checked={formData.gender === "female"}
						onChange={handleChange}
					/>
					<label htmlFor="custom">Custom</label>
					<input
						type="radio"
						id="custom"
						name="gender"
						value="custom"
						checked={formData.gender === "custom"}
						onChange={handleChange}
					/>
				</div>
				<label htmlFor="dob">Date of Birth</label>
				<input
					type="date"
					id="dob"
					placeholder="Date Of Birth(MM/DD/YYYY)"
					value={formData.dob}
					onChange={handleChange}
				/>
				<input
					type="text"
					id="occupation"
					placeholder="Your Occupation"
					value={formData.occupation}
					onChange={handleChange}
				/>
				<div className="select-wrapper">
					<select
						id="country"
						value={formData.country}
						onChange={handleChange}
					>
						<option
							value=""
							disabled
						>
							Select Your Country
						</option>
						{countries.map((country, index) => (
							<option
								key={index}
								value={country.name}
							>
								{country.name}
							</option>
						))}
					</select>
				</div>
				<input
					type="text"
					id="phone"
					placeholder="Your Phone Number (e.g., +380)"
					value={formData.phone}
					onChange={handleChange}
				/>
				<input
					type="text"
					id="email"
					placeholder="Your Email"
					value={formData.email}
					onChange={handleChange}
				/>
				<input
					type="password"
					id="password"
					placeholder="Enter Password"
					value={formData.password}
					onChange={handleChange}
				/>
				<input
					type="password"
					id="confirmPassword"
					placeholder="Confirm Password"
					value={formData.confirmPassword}
					onChange={handleChange}
				/>
				{loading ? (
					<p className="No-post">
						{Loader("syncloader", loading, undefined, 10, "gray")}
					</p>
				) : (
					<button onClick={handleSignUpClick}>Sign Up</button>
				)}

				<div className="login__form__info">
					<h3>
						Already have an account?
						<span>
							<Link to="/Login"> Log In</Link>
						</span>
					</h3>
				</div>
			</div>

			{showPopup && (
				<div className="popup">
					<div className="popup__content">
						<p>
							By signing up you agree to our
							<Link
								to="/TermsOfServices"
								className="link"
							>
								{" "}
								Terms{" "}
							</Link>
							and
							<Link
								to="/PrivacyPolicy"
								className="link"
							>
								{" "}
								Privacy Policy
							</Link>
						</p>
						<div className="popup__buttons">
							<button onClick={handleYesClick}>Yes</button>
							<button onClick={handleNoClick}>No</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Signup;
