import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { LoginFalied, initateLogin, LoginSuccessful } from "./auth";
import { useSelector } from "react-redux";

const subUrl = "/pins";
const all_tabs = [
	"allPosts",
	"imagePosts",
	"audioPosts",
	"videoPosts",
	"starredPosts",
];

const Profile = createSlice({
	name: "userProfile",
	initialState: {
		show: {
			// allposts is the default value
			tab: "allposts",
		},

		allPosts: {
			posts: [],
			totalPosts: 0,
			loading: false,
			show: "allposts",
		},
		starredPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "starredposts",
		},
		textPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "textposts",
		},
		imagePosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "imageposts",
		},
		videoPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "videoposts",
		},
		audioPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "audioposts",
		},
		makePost: {
			post: {},
			loading: false,
		},
		otherLoadingOptions: {
			comment: false,
			react: false,
			save: false,
		},
		OtherUserProfile: {
			profileData: {},
		},
	},

	reducers: {
		initiateMakePost: (profile, action) => {
			profile.makePost.loading = true;
		},
		makePostSuccessful: (profile, action) => {
			profile.makePost.loading = false;
		},
		makePostFailed: (profile, action) => {
			profile.makePost.loading = false;
		},
		tabSelect: (profile, action) => {
			const { show } = action.payload;
			if (show) profile.show = show;
		},
		// videoposts
		initiateLoadVideoPost: (profile, action) => {
			profile.videoPosts.loading = true;
		},
		loadVideoPostSuccessful: (profile, action) => {
			profile.videoPosts.posts = action.payload.posts;
			profile.videoPosts.pageNo = action.payload.pageNo;
			profile.videoPosts.loading = false;
		},
		loadVideoPostFailed: (profile, action) => {
			profile.videoPosts.loading = false;
		},

		// audio posts
		initateLoadAudioPost: (profile, action) => {
			profile.audioPosts.loading = true;
		},
		loadAudioPostSuccessful: (profile, action) => {
			profile.audioPosts.posts = action.payload.posts;
			profile.audioPosts.pageNo = action.payload.pageNo;
			profile.audioPosts.loading = false;
		},
		loadAudioPostFailed: (profile, action) => {
			profile.audioPosts.loading = false;
		},

		// for starred posts
		initiateLoadStarredPost: (profile, action) => {
			profile.starredPosts.loading = true;
		},
		loadStarredPostSuccessful: (profile, action) => {
			profile.starredPosts.posts = action.payload.posts;
			profile.starredPosts.pageNo = action.payload.pageNo;
			profile.starredPosts.loading = false;
		},
		LoadStarredPostFailed: (profile, action) => {
			profile.starredPosts.loading = true;
		},
		initiateLoadImagePost: (profile, action) => {
			profile.imagePosts.loading = true;
		},
		loadImagePostSuccessful: (profile, action) => {
			profile.imagePosts.posts = action.payload.posts;
			profile.imagePosts.pageNo = action.payload.pageNo;
			profile.imagePosts.loading = false;
		},
		loadImagePostFailed: (profile, action) => {
			profile.imagePosts.loading = false;
		},

		// New actions for text posts
		initiateLoadTextPost: (profile, action) => {
			profile.textPosts.loading = true;
		},
		loadTextPostSuccessful: (profile, action) => {
			profile.textPosts.posts = action.payload.posts;
			profile.textPosts.pageNo = action.payload.pageNo;
			profile.textPosts.loading = false;
		},
		loadTextPostFailed: (profile, action) => {
			profile.textPosts.loading = false;
		},

		// Actions for all posts
		initiateLoadAllPost: (profile, action) => {
			profile.allPosts.loading = true;
		},
		loadAllPostSuccessful: (profile, action) => {
			const { posts, totalPosts } = action.payload;
			profile.allPosts.posts = posts;
			profile.allPosts.totalPosts = totalPosts;
			profile.allPosts.loading = false;
		},
		loadAllPostFailed: (profile, action) => {
			profile.allPosts.loading = false;
		},

		follow_or_unfollow: (profile, action) => {},
		commentInitiated: (profile, action) => {
			profile.otherLoadingOptions.comment = true;
		},
		commentSuccessful: (profile, action) => {
			const { postId, comment } = action.payload;

			const postIndex = profile.allPosts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			profile.allPosts.posts[postIndex].comment.push(comment);
			profile.otherLoadingOptions.comment = false;
		},
		commentFailed: (profile, action) => {
			profile.otherLoadingOptions.comment = false;
		},
		deletecomment: (profile, action) => {
			const { commentId, postId } = action.payload;
			const postIndex = profile.allPosts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			profile.allPosts.posts[postIndex].comment = profile.allPosts.posts[
				postIndex
			].comment.filter((a) => a._id.toString() !== commentId);
		},
		saveInitiated: (profile, action) => {
			profile.otherLoadingOptions.save = true;
		},
		saveSuccessful: (profile, action) => {
			const { postId } = action.payload;
			all_tabs.forEach((v) => {
				let postIndex = profile[v].posts.findIndex(
					(a) => a._id.toString() === postId
				);
				if (postIndex !== -1) {
					profile[v].posts[postIndex].saves++;
				}
			});

			profile.otherLoadingOptions.save = false;
		},
		saveFailed: (profile, action) => {
			profile.otherLoadingOptions.save = false;
		},
		reactionInitiated: (profile, action) => {
			profile.otherLoadingOptions.react = true;
		},
		reactionSuccessful: (profile, action) => {
			const { postId, likes } = action.payload;

			all_tabs.forEach((v) => {
				let postIndex = profile[v].posts.findIndex(
					(a) => a._id.toString() === postId
				);
				if (postIndex !== -1) {
					profile[v].posts[postIndex].likes = likes;
				}
			});

			profile.otherLoadingOptions.react = false;
		},
		reactionFailed: (profile, action) => {
			profile.otherLoadingOptions.react = false;
		},
		reactToComment: (profile, action) => {
			const { commentId, postId, userId } = action.payload;
			const postIndex = profile.allPosts.posts.findIndex(
				(a) => a._id.toString() === postId
			);

			const commentIndex = profile.allPosts.posts[postIndex].comment.findIndex(
				(a) => a._id.toString() !== commentId
			);

			if (
				profile.allPosts.posts[postIndex].comment[commentIndex].likes.includes(
					userId
				)
			) {
				profile.allPosts.posts[postIndex].comment[commentIndex].likes =
					profile.allPosts.posts[postIndex].comment[commentIndex].likes.filter(
						(a) => a._id.toString() !== userId
					);
			} else {
				profile.allPosts.posts[postIndex].comment[commentIndex].likes.push(
					userId
				);
			}
		},
		deletePost: (profile, action) => {
			const { postId } = action.payload;

			all_tabs.forEach((v) => {
				profile.allPosts.posts = profile[v].posts.filter(
					(a) => a._id.toString() !== postId
				);
			});
		},
		toggleFavourite: (profile, action) => {
			const { post } = action.payload;
			const postId = post._id;

			all_tabs.forEach((v) => {
				// Find the post by its ID
				const postIndex = profile[v].posts.findIndex(
					(a) => a._id.toString() === postId
				);

				if (postIndex !== -1) {
					// Update the isFavourite property for the post
					profile[v].posts[postIndex].isUserFavourite = post.isUserFavourite;
				}
			});
		},


	},
});

export const {
	initateLoadAudioPost,
	initiateLoadAllPost,
	initiateLoadImagePost,
	initiateLoadTextPost,
	initiateLoadVideoPost,
	initiateLoadStarredPost,
	LoadStarredPostFailed,
	loadAllPostFailed,
	loadAllPostSuccessful,
	loadAudioPostFailed,
	loadAudioPostSuccessful,
	loadImagePostFailed,
	loadImagePostSuccessful,
	loadTextPostFailed,
	loadTextPostSuccessful,
	loadVideoPostFailed,
	loadVideoPostSuccessful,
	loadStarredPostSuccessful,
	initiateMakePost,
	makePostFailed,
	makePostSuccessful,
	tabSelect,
	commentInitiated,
	commentFailed,
	commentSuccessful,
	reactionInitiated,
	reactionSuccessful,
	reactionFailed,
	saveInitiated,
	saveSuccessful,
	saveFailed,
	deletecomment,
	reactToComment,
	deletePost,
	toggleFavourite,
	LoadUserProfile,
} = Profile.actions;

export const profile_Reducer = Profile.reducer;

export const LoadMyPosts = (data) => (dispatch, getState) => {
	// const currentState = getState().entities.profile;
	// if (currentState.allPosts.posts.length > 0) return;
	const { pageNo, userId } = data;
	console.log("data", data);
	dispatch(
		apiCallBegan({
			url: `${subUrl}/mypins/${userId}?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadAllPost.type,
			onSuccess: loadAllPostSuccessful.type,
			onError: loadAllPostFailed.type,
		})
	);
};
export const LoadMyMetaData = () => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: "/profile",
			method: "get",
			onStart: initateLogin.type,
			onSuccess: LoginSuccessful.type,
			onError: LoginFalied.type,
		})
	);
};

export const loadImagePosts = (data) => (dispatch, getSatate) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/${"image"}?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadImagePost.type,
			onSuccess: loadImagePostSuccessful.type,
			onError: loadImagePostFailed.type,
		})
	);
};

export const loadVideoPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/${"video"}?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadVideoPost.type,
			onSuccess: loadVideoPostSuccessful.type,
			onError: loadVideoPostFailed.type,
		})
	);
};

export const loadAudioPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/${"audio"}?page=${pageNo}`,
			method: "get",
			onStart: initateLoadAudioPost.type,
			onSuccess: loadAudioPostSuccessful.type,
			onError: loadAudioPostFailed.type,
		})
	);
};

export const loadTextPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/text?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadTextPost.type,
			onSuccess: loadTextPostSuccessful.type,
			onError: loadTextPostFailed.type,
		})
	);
};

// starred posts are the same as favourite posts
export const loadStarredPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `/pins/${userId}/favourite-pins?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadStarredPost.type,
			onSuccess: loadStarredPostSuccessful.type,
			onError: LoadStarredPostFailed.type,
		})
	);
};
export const Post = (data) => (dispatch, getState) => {
	const category = JSON.parse(data.get("data")).category;
	console.log(category);
	dispatch(
		apiCallBegan({
			url: `/create-pin/${category}`,
			method: "post",
			onStart: initiateMakePost.type,
			onSuccess: makePostSuccessful.type,
			onError: makePostFailed.type,
			values: data,
		})
	);
};

export const tabSwitch = (tab) => (dispatch, getState) => {
	// get current open tab
	const tabState = getState().entites.userProfile().show.tab;

	if (tabState === tab) {
		return;
	} else if (tabState !== tab && tabState.length > 0) {
		return dispatch({ action: "/profile", show: tab });
	}
	switch (tab) {
		case "allposts":
			break;
		case "starredposts":
			break;
		case "textposts":
			break;
		case "imageposts":
			break;
		case "audioposts":
			break;
		case "videoposts":
			break;
	}
};

export const comment = (commentInfo) => (dispatch, getState) => {
	const { username, userId, userImage } = getState().entities.profile;
	const spreadComment = { username, userImage, userId, ...commentInfo };

	dispatch(
		apiCallBegan({
			url: `${subUrl}/comment/:${commentInfo.postId}`,
			method: "post",
			onStart: commentInitiated.type,
			onSuccess: commentSuccessful.type,
			onError: commentFailed.type,
			spreadComment,
		})
	);
};

export const save = (postId) => (dispatch, getState) => {
	// const { userId } = getState().entities.profile;

	dispatch(
		apiCallBegan({
			url: `${subUrl}/save/${postId}`,
			method: "post",
			onStart: saveInitiated.type,
			onSuccess: saveSuccessful.type,
			onError: saveFailed.type,
		})
	);
};
export const react = (postId) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/like`,
			method: "put",
			onStart: reactionInitiated.type,
			onSuccess: reactionSuccessful.type,
			onError: reactionFailed.type,
		})
	);
};

// export const delete_comment = (commentInfo) => (dispatch, getState) => {
// 	const { userId } = getState().entities.profile;
// 	const spreadCommentInfo = { ...commentInfo, userId };
// 	dispatch(
// 		apiCallBegan({
// 			url: `${subUrl}/:${commentInfo.postId}/delete/:${commentInfo.commentId}`,
// 			method: "delete",

// 			onSuccess: deleteComment.type,

// 			spreadCommentInfo,
// 		})
// 	);
// };
export const react_to_comment = (commentInfo) => (dispatch, getState) => {
	const { userId } = getState().entities.profile;
	const spreadCommentInfo = { ...commentInfo, userId };
	dispatch(
		apiCallBegan({
			url: `${subUrl}/:${commentInfo.postId}/like/:${commentInfo.commentId}`,
			method: "put",
			onSuccess: reactToComment.type,
			spreadCommentInfo,
		})
	);
};

export const delete_post = (postId) => (dispatch, getState) => {
	// commentId, postId, replyId
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}`,
			method: "delete",
			onSuccess: deletePost.type,
		})
	);
};

export const toggleFavouriteStatus = (postId) => (dispatch, getState) => {
	// commentId, postId, replyId

	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/favourite-pins`,
			method: "patch",
			onSuccess: toggleFavourite.type,
		})
	);
};

export const LoadProfile = (username) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `/profile/${username}`,
			method: "get",
			onSuccess: LoadUserProfile.type,
		})
	);
};
