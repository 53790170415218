import React from "react";
import { Link } from "react-router-dom";
import { FaUser, FaHeart, FaComment } from "react-icons/fa";
import { MdVerified } from "react-icons/md";

const SearchResultsPage = ({ searchResults, searchQuery, onClose }) => {
	const renderPostResult = (post) => (
		<div
			className="search-result post-result"
			key={post._id}
		>
			<Link
				to={`/post/${post._id}`}
				onClick={onClose}
				className="result-link__post"
			>
				<h3 className="result-title__post">{post.catalog}</h3>
				<p className="result-excerpt__post">{post.message.slice(0, 40)}</p>
				<div className="result-meta__post">
					<span className="space-react_icons">
						<FaHeart />
						<span>{post.likes.length}</span>
					</span>
					<span className="space-react_icons">
						<FaComment />
						<span>{post.comment.length}</span>
					</span>
					<span>by {post.user.username}</span>
				</div>
			</Link>
		</div>
	);

	const renderUserResult = (user) => (
		<div className="search-result user-result">
			<Link
				to={`/user?username=${user.username}`}
				onClick={onClose}
				className="result-link"
			>
				<div className="user-avatar-wrapper">
					<img
						src={user.picture}
						alt={user.name}
						className="user-avatar"
					/>
				</div>
				<div className="user-info">
					<h3 className="result-title">
						{user.name}{" "}
						{user.subscription?.role === "subscribed" ? (
							<MdVerified 
								className="feed__badge"
								size={18}
							/>
						) : (
							""
						)}
					</h3>

					<div className="result-meta">
						<p className="result-excerpt">@{user?.username}</p>
						<span className="followers-count">
							<FaUser className="icon" />
							{user.followers.length} followers
						</span>
					</div>
				</div>
			</Link>
		</div>
	);

	return (
		<div className="search-results-page">
			<h2 className="search-results-title">
				Search Results for "{searchQuery}"
			</h2>
			<div className="search-results-container">
				{searchResults.users.map((result) => renderUserResult(result))}
				{searchResults.posts.map((result) => renderPostResult(result))}
			</div>
			{searchResults.length === 0 && (
				<p className="no-results">No results found for "{searchQuery}"</p>
			)}
		</div>
	);
};

export default SearchResultsPage;
