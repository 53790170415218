import React from "react";
import "./advertWidget.css";
import { Link } from "react-router-dom";

const AdvertWidget = () => {
	return (
		<div className="advertCont-relative">
			<div className="advert-widget">
				<video
					className="background-video"
					autoPlay
					loop
					muted
				>
					<source
						src={"https://www.w3schools.com/html/mov_bbb.mp4"}
						type="video/mp4"
					/>
					Your browser does not support the video tag.
				</video>
				<div className="content">
					<h2>Advertise With Us</h2>
					<Link to="/advert">
						<button>Advertise Here</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AdvertWidget;
