import React from "react";
import "./navbar.css";
import { Logo } from "../../assets";
import { MdSearch } from "react-icons/md";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo">
        <p>Me</p>
        <img src={Logo} alt="logo" />
      </div>
      <div className="search-n-toggle">
        <div className="search">
          <input type="search" name="search" id="search" />
          <MdSearch className="search-icon" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
