import React, { useEffect, useState } from "react";
import "./page.css";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { LoadMyMetaData } from "../../store/slices/profile";
import { useDispatch } from "react-redux";
import { Search, Sidebar, Widgets } from "../../components";
import PostFeed from "../../components/PostFeed/PostFeed";
import CreatePost from "../Create/CreatePost/CreatePost";
import TrendingPost from "../Trending/TrendingPost/TrendingPost";
import Profile from "../Profile/Profile";
import Explore from "../Explore/Explore";
import Settings from "../settings/Settings";
import AboutPage from "../AboutPage/AboutPage";
import PrivacyPolicy from "../privacypolicy/PrivacyPolicy";
import Support from "../support/Support";
import ActivityPage from "./activity/ActivityPage";
import TimeCapsule from "../timecapsule/TimeCapsule";
import PinDetails from "../pindetails/PinDetails";
import PromoteCatalog from "../promotecatalog/PromoteCatalog";
import AgoraLiveWidget from "../livestart/LivestartInAgora";
import Subscribe from "../subscribe/Subscribe";
import OthersProfile from "../ProfileForOthers/Profile";
import PremiumSuccess from "../payment-successful/Premiumsubscription";
import PromotedPostMessage from "../payment-successful/Promotepost";
import AccountBoostSuccessful from "../payment-successful/BoostAccount";
import SplashPage from "../splashpage/SplashPage";
import SuggestedAccount from "../../components/suggestedaccount/SuggestedAccount";
import SuggestedWidgets from "../../components/Widgets/Suggested Widgets/SuggestedWidgets";
import logo from "../../assets/logo2.jpg";
/* import UserLiveWidgets from "../../components/UserLive Widgets/UserLiveWidgets"; */

const Page = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [isSearchBoxOpen, setSearchBoxOpen] = useState(false);
	const [isSettingOpen, setIsSettingOpen] = useState(false);
	const dispatch = useDispatch();
	const openSearchBox = () => {
		setSearchBoxOpen(true);
	};

	const closeSearchBox = () => {
		setSearchBoxOpen(false);
	};
	const openModal = () => {
		setModalOpen(true);
	};
	const closeModal = () => {
		setModalOpen(false);
	};
	const openSettingsModal = () => {
		setIsSettingOpen(true);
	};
	const closeSettingsModal = () => {
		console.log("clicked");
		setIsSettingOpen(false);
	};
	useEffect(() => {
		dispatch(LoadMyMetaData());
		return () => {};
	}, [dispatch]);

	return (
		<div className="main-site__container">
			<div className="site-header">
				{" "}
				<span>Me</span>{" "}
				<span className="logo-sidebar">
					<img
						src={logo}
						alt=""
					/>
				</span>
			</div>
			<div className="page">
				<Sidebar
					openSearchBox={openSearchBox}
					setModalOpen={setModalOpen}
					isModalOpen={isModalOpen}
					openModal={openModal}
					closeModal={closeModal}
					openSettingsModal={openSettingsModal}
					closeSettingsModal={closeSettingsModal}
				/>
				{isSearchBoxOpen && <Search onClose={closeSearchBox} />}
				{isModalOpen && <CreatePost onClose={closeModal} />}
				{isSettingOpen && <Settings onClose={closeSettingsModal} />}

				
					<Routes>
						<Route
							path="/timecapsule"
							element={<TimeCapsule />}
						/>
						{/* <Route
					path="/logout"
					element={<Logout />}
				/> */}
						<Route
							path="/post/:postId"
							element={<PinDetails />}
						/>

						<Route
							path="/trending"
							element={<TrendingPost />}
						/>

						<Route
							path="/live"
							element={<AgoraLiveWidget />}
						/>
						<Route
							path="/profile"
							element={<Profile />}
						/>
						<Route
							path="/trending"
							element={<TrendingPost />}
						/>
						<Route
							path="/explore"
							element={<Explore />}
						/>

						<Route
							path="/discover"
							element={<PostFeed />}
						/>
						<Route
							path="/about"
							element={<AboutPage />}
						/>
						<Route
							path="/user"
							element={<OthersProfile />}
						/>
						<Route
							path="/privacyPolicy"
							element={<PrivacyPolicy />}
						/>
						<Route
							path="/support"
							element={<Support />}
						/>
						<Route
							path="/activities"
							element={<ActivityPage />}
						/>
						<Route
							path="/suggestedaccounts"
							element={<SuggestedWidgets />}
						/>

						<Route
							path="*"
							element={<PostFeed />}
						/>
					</Routes>
			

				<Widgets />
				{/* <UserLiveWidgets /> */}
			</div>
		</div>
	);
};

export default Page;
