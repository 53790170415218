import React, { useState, useEffect } from "react";
import "./sidebar.css";
import { SidebarUser, SidebarMenu, Search } from "..";
import { GoHomeFill } from "react-icons/go";
import {
	BiSolidTimeFive,
	BiTrendingUp,
	BiSolidCompass,
	BiSolidUser,
	BiBell,
} from "react-icons/bi";
import { MdAddBox } from "react-icons/md";
import { RiLiveFill } from "react-icons/ri";
import { AiFillSetting } from "react-icons/ai";
import { IoLogOut } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import controlPNG from "../../assets/control.png";
import Logo from "../../assets/logo2.jpg";
import { useSelector } from "react-redux";
import { removeAuthToken } from "../../store/helpers/helper";

const LogoutModal = ({ onConfirm, onCancel }) => (
	<div className="modal">
		<div className="modal-content">
			<h2>Are you sure to logout?</h2>
			<div className="modal-buttons_main">
				<button onClick={onConfirm}>Yes</button>
				<button onClick={onCancel}>No</button>
			</div>
		</div>
	</div>
);

const Sidebar = ({ openSearchBox, openModal, openSettingsModal }) => {
	const [activeLink, setActiveLink] = useState("Discover");
	const [open, setOpen] = useState(true);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const navigate = useNavigate();

	const handleClick = (link) => {
		setActiveLink(link);
		if (link === "Search") {
			openSearchBox();
		} else if (link === "chat") {
			navigate("/chat");
		}
	};

	const handleLogoutClick = () => {
		setShowLogoutModal(true);
	};

	const handleConfirmLogout = () => {
		setShowLogoutModal(false);
		removeAuthToken();
		setTimeout(() => {
			navigate("/login");
		}, 3000);
	};

	const handleCancelLogout = () => {
		setShowLogoutModal(false);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (isMobile) {
			setOpen(false);
		}
	}, [isMobile]);

	return (
		<>
			<div
				className={`sidebar ${
					open ? "w-300" : "w-20"
				} min-w-20  relative duration-100`}
			>
      
				<img
					src={controlPNG}
					className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full ${
						!open && "rotate-180 side-icon"
					}`}
					onClick={() => setOpen(!open)}
          />
				{open && (
					<>
						<SidebarUser />
						<br />
					</>
				)}
				<div className="sidebar-menus">
					<SidebarMenu
						to="/discover"
						text={"Discover"}
						icon={<GoHomeFill />}
						isActive={activeLink === "Discover"}
						onClick={() => {
							handleClick("Discover");
						}}
						open={open}
					/>
					<SidebarMenu
						to="timecapsule"
						text={"Time capsule"}
						icon={<BiSolidTimeFive />}
						isActive={activeLink === "Time capsule"}
						onClick={() => {
							handleClick("Time capsule");
						}}
						open={open}
					/>
					<SidebarMenu
						to="/trending"
						text={"Trending"}
						icon={<BiTrendingUp />}
						isActive={activeLink === "Trending"}
						onClick={() => {
							handleClick("Trending");
						}}
						open={open}
					/>
					<SidebarMenu
						to="/discover"
						text={"Create"}
						icon={<MdAddBox />}
						isActive={activeLink === "Create"}
						onClick={() => {
							openModal();
							handleClick("Create");
						}}
						open={open}
					/>
					<SidebarMenu
						text={"Search"}
						icon={<BsSearch />}
						isActive={activeLink === "Search"}
						onClick={() => {
							handleClick("Search");
						}}
						open={open}
					/>
					{/* <SidebarMenu
					to="/chat"
					text={"Chat"}
					icon={<HiChatBubbleOvalLeft />}
					isActive={activeLink === "Chat"}
					onClick={() => handleClick("Chat")}
					open={open}
				/> */}
					<SidebarMenu
						to="/live"
						text={"Live"}
						icon={<RiLiveFill />}
						isActive={activeLink === "Live"}
						onClick={() => {
							handleClick("Live");
						}}
						open={open}
					/>
					<SidebarMenu
						to="/explore"
						text={"Explore"}
						icon={<BiSolidCompass />}
						isActive={activeLink === "Explore"}
						onClick={() => {
							handleClick("Explore");
						}}
						open={open}
					/>
					<SidebarMenu
						to="/profile"
						text={"Profile"}
						icon={<BiSolidUser />}
						isActive={activeLink === "Profile"}
						onClick={() => {
							handleClick("Profile");
						}}
						open={open}
					/>
					<SidebarMenu
						text={"Setting"}
						icon={<AiFillSetting />}
						isActive={activeLink === "Setting"}
						onClick={() => {
							handleClick("Setting");
							openSettingsModal();
						}}
						open={open}
					/>
					{/* <SidebarMenu
					to="/activities"
					text={"Activities"}
					icon={<BiBell />}
					isActive={activeLink === "Activities"}
					onClick={() => {
						handleClick("Activities");
					}}
					open={open}
				/> */}
					<SidebarMenu
						// to="/logout"
						text={"Log Out"}
						icon={<IoLogOut />}
						isActive={activeLink === "Log Out"}
						onClick={() => {
							handleLogoutClick();
						}}
						open={open}
					/>
				</div>
				<div className="sidebar__micro-links">
					<ul className="max-w-md space-y-1 list-none list-inside">
						<li className="overflow-hidden max-w-md whitespace-nowrap overflow-ellipsis">
							<Link to="/about">About</Link>
						</li>
						<li className="overflow-hidden max-w-md whitespace-nowrap overflow-ellipsis">
							<Link to="/TermsOfServices">Terms</Link>
						</li>
						<li className="overflow-hidden max-w-md whitespace-nowrap overflow-ellipsis">
							<Link to="/PrivacyPolicy">Privacy Policy</Link>
						</li>
						<li className="overflow-hidden max-w-md whitespace-nowrap overflow-ellipsis">
							<Link to="/support">Support</Link>
						</li>
					</ul>
				</div>
			</div>
			{showLogoutModal && (
				<LogoutModal
					onConfirm={handleConfirmLogout}
					onCancel={handleCancelLogout}
				/>
			)}
		</>
	);
};
export default Sidebar;
