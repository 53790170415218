import React from "react";
import "./sidebarMenu.css";
import { Link } from "react-router-dom";

const SidebarMenu = ({ to, text, icon, isActive, onClick, open }) => {
  const menuClass = isActive ? "active" : "inactive";
  return (
    // <ul className="pt-6">
    //   <li
    //     key={text}
    //     className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4
    //         "mt-2"} ${isActive && "bg-light-white"} `}
    //   >
    //     <Link to={to} onClick={onClick} >
    //       {icon}
    //       <span className={`${!open && "hidden"} origin-left duration-200`}>
    //         {text}
    //       </span>
    //     </Link>
    //   </li>
    // </ul>
    <Link
      to={to}
      className={`sidebar-menu ${menuClass} flex  rounded-md p-2 cursor-pointer`}
      onClick={onClick}
    >
     <span className="rotate-icons">{icon}</span> 
      <span className={`${!open && "hidden"} origin-left duration-200`}>
        {text}
      </span>
    </Link>
  );
};

export default SidebarMenu;
