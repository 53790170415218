import React from "react";
import LiveStart from "./LiveStart";
/**Adding agora SDK */
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";

function AgoraLiveWidget() {
	const client = AgoraRTC.createClient({
		mode: "live",
		codec: "vp8",
	});
	return (
		<AgoraRTCProvider client={client}>
			<LiveStart client={client} />
		</AgoraRTCProvider>
	);
}

export default AgoraLiveWidget;
