import numeral from "numeral";
import { countries } from "../../pages/Profile/countries";

class user {
	username = (username, arrayItem, findItem, getItem) => {
		const arrayIndex = countries.findIndex((v) => {
			return v[arrayItem] === findItem;
		});
		if (arrayIndex !== -1) {
			return username + "." + countries[arrayIndex][getItem];
		} else {
			return "";
		}
	};
	isUserSubscribed = (info) => {
		if (info === "subscribed") {
			return true;
		}
		return false;
	};

	formatNumber = (num) => {
		console.log(num, "num");
		// check if its exists and type convert
		if (!num || typeof Number(num) !== Number) {
			return 0;
		}

		return numeral(Number(num)).format("0.0a");
	};
}
export const user_Helper = new user();
