import React, { useEffect, useState, useRef } from "react";
import "./post.css";
import { MdVerified } from "react-icons/md";
import {
	FaHeart,
	FaRegHeart,
	FaArrowRight,
	FaRetweet,
	FaStar,
	FaRegStar,
	FaTrash,
} from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFileExtension, getMediaType } from "../../utils/media";
import FullscreenModal from "../../pages/Profile/ProfilePost/fullScreen";
import { followOrUnfollow, suggestAccounts } from "../../store/slices/auth";
import moment from "moment";
import { decodedAuthToken } from "../../store/helpers/helper";
import { useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { BsMegaphone } from "react-icons/bs";
import { IoShareSocialOutline } from "react-icons/io5";

import { PiChatsCircleDuotone } from "react-icons/pi";
import { AiOutlineDelete } from "react-icons/ai";
import { CiBookmarkMinus, CiBookmarkPlus } from "react-icons/ci";
import toast from "react-hot-toast";
import { user_Helper } from "../../store/helpers/user";
import SuggestedWidgets from "../Widgets/Suggested Widgets/SuggestedWidgets";
import Pagination from "../pagination/Pagination";
import AdvertWidget from "../Widgets/Advert Widget/AdvertWidget";

const PostComponent = ({
	posts,
	dispatch,
	react,
	save,
	star,
	deletePost,
	removeScroll,
	scrollTo,
	contRef,
	loadMorePosts, 
	parentId,
}) => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.entities.auth);
	const suggestedAccounts = useSelector(
		(state) => state.entities.auth.suggestedAccounts
	);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [fullscreenImageSrc, setFullscreenImageSrc] = useState("");
	const [currentImageIndexes, setCurrentImageIndexes] = useState({}); // Store image indexes for each post
	const mediaRefs = useRef({});
	const location = useLocation();
	const [pathname, setPathname] = useState(location.pathname);
	const iconColor = {
		charcoal_grey: "#5555e9",
	};
	console.log(location.pathname, "location");
	const handleImageClick = (src) => {
		setFullscreenImageSrc(src);
		setIsFullscreen(true);
	};

	const handleCloseFullscreen = () => {
		setIsFullscreen(false);
		setFullscreenImageSrc("");
	};

	const copyToClipboard = (postId) => {
		const url = `${window.location.origin}/post/${postId}`;
		navigator.clipboard.writeText(url).then(() => {
			toast.success("Link copied");
		});
	};
  
	// Function to update the current image index for a specific post
	const updateCurrentImageIndex = (postId, index) => {
		setCurrentImageIndexes((prevIndexes) => ({
			...prevIndexes,
			[postId]: index,
		}));
	};

	useEffect(() => {
		const handleMediaAutoplay = (entries) => {
			entries.forEach((entry) => {
				const mediaElement = entry.target;
				// Check if mediaElement is video or audio before trying to play/pause
				if (
					mediaElement.tagName === "VIDEO" ||
					mediaElement.tagName === "AUDIO"
				) {
					if (entry.isIntersecting) {
						mediaElement.play();
					} else {
						mediaElement.pause();
					}
				}
			});
		};

		const observer = new IntersectionObserver(handleMediaAutoplay, {
			threshold: 0.5, // Autoplay when at least 50% of the media is visible
		});

		Object.values(mediaRefs.current).forEach((mediaArray) => {
			mediaArray.forEach((media) => {
				if (media) observer.observe(media);
			});
		});

		return () => {
			Object.values(mediaRefs.current).forEach((mediaArray) => {
				mediaArray.forEach((media) => {
					if (media) observer.unobserve(media);
				});
			});
		};
	}, []);
	useEffect(() => {
		dispatch(suggestAccounts());
		return () => {};
	}, [dispatch]);
	return (
		<div
			id="postFeedScroll"
			ref={contRef}
			className={removeScroll ? "" : "post-feed1"}
		>
			<div className="feed_1">
				{isFullscreen && (
					<FullscreenModal
						src={fullscreenImageSrc}
						onClose={handleCloseFullscreen}
					/>
				)}
				{posts && posts.length > 0 ? (
					<>
						{posts.map((post, index) => {
							// Get the current image index for this specific post
							const currentImageIndex = currentImageIndexes[post._id] || 0;
							const currentMediaUrl = post.media[currentImageIndex];
							const mediaType = getMediaType(currentMediaUrl);

							const prevImage = () => {
								updateCurrentImageIndex(
									post._id,
									currentImageIndex === 0
										? post.media.length - 1
										: currentImageIndex - 1
								);
							};

							const nextImage = () => {
								updateCurrentImageIndex(
									post._id,
									currentImageIndex === post.media.length - 1
										? 0
										: currentImageIndex + 1
								);
							};
							if (
								index === 5 &&
								suggestedAccounts.length > 0 &&
								pathname === "/discover"
							) {
								return (
									<div
										className="mb-2 suggestion-block"
										key={post._id}
									>
										<SuggestedWidgets />
									</div>
								);
							} else {
								return (
									<div
										key={post._id}
										className="feed_start1"
									>
										<div className="feed_header__follow">
											<div className="feed_header__container">
												<div className="feed__avatar">
													<img
														src={post.user?.picture}
														alt=""
														onClick={() => {
															navigate(`/user?username=${post.user.username}`);
														}}
													/>
												</div>
												<div className="feed__headerText">
													<h3>
														<span
															className="feed__name"
															onClick={() => {
																navigate(
																	`/user?username=${post.user.username}`
																);
															}}
														>
															{post.user?.name}{" "}
														</span>
														<span className="feed__headerSpecial">
															{post.user.subscription?.role === "subscribed" ? (
																<MdVerified
																	className="feed__badge"
																	size={18}
																/>
															) : (
																""
															)}
															<br />@
															{user_Helper.username(
																post.user.username,
																"name",
																post.user.country,
																"code"
															)}{" "}
															<span className="dot">·</span>{" "}
															{moment(post.date).fromNow()}
														</span>
													</h3>
												</div>
											</div>

											{post.promote?.isPromoted ? (
												<div className="promoted-badge">Promoted</div>
											) : (
												decodedAuthToken().id !== post.user._id?.toString() && (
													<div
														className="follow-btn"
														onClick={(e) => {
															e.stopPropagation();
															dispatch(followOrUnfollow(post.user.username));
														}}
													>
														{" "}
														{user.following.members.includes(post.user._id)
															? "Unfollow"
															: decodedAuthToken().id === post.user._id
															? ""
															: "Follow"}
													</div>
												)
											)}
										</div>
										<div className="feed__body">
											<div className="feed__header">
												<div
													className="feed__headerDescription"
													onClick={() => {
														navigate(`/post/${post._id}`);
														if (scrollTo) {
															document.getElementById(scrollTo).scrollTo({
																top: 0,
																behavior: "smooth",
															});
														}
													}}
												>
													<p>
														{post.message.slice(0, 150)}{" "}
														{post.message.slice(150).length > 0 && "..."}
														{post?.hashtags && (
															<div>
																<br />
																{post?.hashtags}
															</div>
														)}
													</p>
												</div>
											</div>
											<div
												className="post__media"
												onClick={() => {
													navigate(`/post/${post._id}`);
													if (scrollTo) {
														document.getElementById(scrollTo).scrollTo({
															top: 0,
															behavior: "smooth",
														});
													}
												}}
											>
												<div className="image-carousel">
													{post.media.length > 1 && (
														<FaArrowLeft
															className="carousel-arrow left"
															onClick={(event) => {
																event.stopPropagation();
																prevImage();
															}}
														/>
													)}
													{mediaType === "image" && (
														<img
															src={currentMediaUrl}
															alt={`Media ${currentImageIndex + 1}`}
															className="fullscreen-media"
														/>
													)}
													{mediaType === "video" && (
														<video
															controls
															className="fullscreen-media"
															ref={(el) =>
																(mediaRefs.current[post._id] = [
																	...(mediaRefs.current[post._id] || []),
																	el,
																])
															}
														>
															<source
																src={currentMediaUrl}
																type={`video/${getFileExtension(
																	currentMediaUrl
																)}`}
															/>
															Your browser does not support the video tag.
														</video>
													)}
													{mediaType === "audio" && (
														<div className="fullscreen-media_audio">
															<audio
																controls
																className=""
																ref={(el) =>
																	(mediaRefs.current[post._id] = [
																		...(mediaRefs.current[post._id] || []),
																		el,
																	])
																}
															>
																<source
																	src={currentMediaUrl}
																	type={`audio/${getFileExtension(
																		currentMediaUrl
																	)}`}
																/>
																Your browser does not support the audio tag.
															</audio>
														</div>
													)}
													{post.media.length > 1 && (
														<FaArrowRight
															className="carousel-arrow right"
															onClick={(event) => {
																event.stopPropagation();
																nextImage();
															}}
														/>
													)}
												</div>
											</div>

											<div className="tweet-actions">
												<div className="action-container">
													<div className="action-icon">
														{post.likes.includes(user.userId) ? (
															<FaHeart
																onClick={() => {
																	dispatch(react(post._id));
																}}
																color="var(--crimson)"
															/>
														) : (
															<FaRegHeart
																color={iconColor.charcoal_grey}
																onClick={() => {
																	dispatch(react(post._id));
																}}
															/>
														)}
													</div>
													<div className="action-count">
														{post.likes.length}
													</div>
												</div>

												<Link
													to={`/post/${post._id}`}
													onClick={() => {
														if (scrollTo) {
															document.getElementById(scrollTo).scrollTo({
																top: 0,
																behavior: "smooth",
															});
														}
													}}
												>
													<div className="action-container">
														<div className="action-icon">
															<PiChatsCircleDuotone
																color={iconColor.charcoal_grey}
															/>
														</div>
														<div className="action-count">
															{post.comment.length}
														</div>
													</div>
												</Link>

												{post.user._id === decodedAuthToken().id &&
													!post.promote?.isPromoted && (
														<Link to={`/promote-catalog?postId=${post._id}`}>
															<div className="action-container">
																<div className="action-icon">
																	<BsMegaphone
																		color={iconColor.charcoal_grey}
																	/>
																</div>
																<div className="action-count"></div>
															</div>
														</Link>
													)}

												<div className="action-container">
													<div className="action-icon">
														{user.saved.includes(post._id) ? (
															<CiBookmarkPlus
																onClick={() => {
																	dispatch(save(post._id));
																}}
																color="var(--crimson)"
															/>
														) : (
															<CiBookmarkMinus
																onClick={() => {
																	dispatch(save(post._id));
																}}
																color={iconColor.charcoal_grey}
																fontWeight={800}
															/>
														)}
													</div>
													<div className="action-count">{post.saves}</div>
												</div>
												{post.user._id === decodedAuthToken().id && (
													<div className="action-container">
														<div className="action-icon">
															{post.isUserFavourite?.includes(
																decodedAuthToken().id
															) ? (
																<FaStar
																	onClick={() => {
																		dispatch(star(post._id));
																	}}
																	color="var(--crimson)"
																/>
															) : (
																<FaRegStar
																	onClick={() => {
																		dispatch(star(post._id));
																	}}
																	color={iconColor.charcoal_grey}
																/>
															)}
														</div>
														<div className="action-count">{post.stars}</div>
													</div>
												)}
												{post.user._id === decodedAuthToken().id && (
													<div className="action-container">
														<div className="action-icon">
															<AiOutlineDelete
																color={iconColor.charcoal_grey}
																onClick={() => {
																	dispatch(deletePost(post._id));
																}}
															/>
														</div>
													</div>
												)}

												<div className="action-container">
													<div className="action-icon">
														<IoShareSocialOutline
															color={iconColor.charcoal_grey}
															onClick={() => {
																copyToClipboard(post._id);
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							}
						})}
						<div className="mt-2 show-hide">
							<AdvertWidget />
						</div>
						<Pagination
							loadMorePosts={loadMorePosts}
							parentId={parentId}
						/>
					</>
				) : (
					<div className="empty-feed">
						<div className="empty-feed-content">
							<div className="empty-feed-image">
								<img
									src="/assets/noPosts.jpg"
									alt="no-posts"
								/>
							</div>
							<div className="empty-feed-text">No posts yet.</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PostComponent;
