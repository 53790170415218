import React from "react";
import "./chatWidget.css";
import { FaVideo, FaPhoneAlt } from "react-icons/fa";
import { image01 } from "../../../assets";

const ChatWidget = ({ selectedUser }) => {
  if (!selectedUser) {
    return null; // Don't display anything if no user is selected
  }

  const { avatar, userName } = selectedUser;

  return (
    <div className="user-profile">
      <div className="profile-header">
        <div className="cover-photo">
          <img
            src={image01}
            alt="Cover"
            className="cover-photo-img"
          />
        </div>
        <div className="profile-picture-container">
          <img src={avatar} alt={`${userName} avatar`} className="profile-picture" />
          <div className="profile-info">
            <h2>{userName}</h2>
            <p className="status">Online</p>
          </div>
        </div>
      </div>
      <div className="profile-actions">
        <FaVideo className="action-icon" title="Video Call" />
        <FaPhoneAlt className="action-icon" title="Voice Call" />
      </div>
      <div className="profile-details">
        <p>Phone: +380 456 7890</p>
        <p>Email: anikageorge@outlook.com</p>
        <p>Location: Kyiev, Ukraine</p>
        <p>About: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor.</p>
      </div>
    </div>
  );
};

export default ChatWidget;
