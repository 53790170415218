import React, { useEffect, useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../../store/slices/auth";
import { baseUrl } from "../../../store/middleware/api";
import { Loader } from "../../../utils/loader/loader";

const Login = () => {
	const [identifier, setIdentifier] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const shouldRedirect = useSelector((state) => {
		return state.entities.auth.login.shouldRedirect;
	});
	const loading = useSelector((state) => {
		return state.entities.auth.login.loading;
	});

	const handleSubmit = () => {
		dispatch(login({ identifier, password }));
	};

	// Redirect functions
	const login__Google = () => {
		window.location.href = baseUrl + "/auth/google";
	};

	const login__LinkedIn = () => {
		window.location.href = baseUrl + "/auth/linkedin";
	};

	const login__Facebook = () => {
		window.location.href = baseUrl + "/auth/facebook";
	};

	const login__Instagram = () => {
		window.location.href = baseUrl + "/auth/instagram";
	};

	useEffect(() => {
		if (shouldRedirect) {
			setTimeout(() => {
				navigate("/discover");
			}, 1000);
		}
	}, [dispatch, shouldRedirect]);

	return (
		<div className="login">
			<div className="login__form">
				<h2>Log In</h2>
				<input
					type="text"
					id="identifier"
					placeholder="Enter Username or Email"
					value={identifier}
					onChange={(e) => setIdentifier(e.target.value)}
				/>
				<input
					type="password"
					id="password"
					placeholder="Enter Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{loading ? (
					<p className="No-post">
						{Loader("syncloader", loading, undefined, 10, "gray")}
					</p>
				) : (
					<button onClick={handleSubmit}>Log In</button>
				)}

				<div className="login__form__info">
					<p>
						<Link to="/resetPassword">Forgot Password?</Link>
					</p>
				</div>

				{/* <div className="login__social">
					<h3>Or log in with:</h3>
					<button
						onClick={login__LinkedIn}
						className="login__social__button linkedin"
					>
						Login with LinkedIn
					</button>
					<button
						onClick={login__Facebook}
						className="login__social__button facebook"
					>
						Login with Facebook
					</button>
					<button
						onClick={login__Instagram}
						className="login__social__button instagram"
					>
						Login with Instagram
					</button>
					<button
						onClick={login__Google}
						className="login__social__button google"
					>
						Login with Google
					</button>
				</div> */}

				<div className="login__signup">
					<h3>
						Don't have a ME account?
						<span>
							<Link to="/SignUp">Sign Up</Link>
						</span>
					</h3>
				</div>
			</div>
		</div>
	);
};

export default Login;
