import React, { useState } from "react";
import "./settings.css";
import {
	FaUser,
	FaLock,
	FaTrashAlt,
	FaSignOutAlt,
	FaTimes,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAccount } from "../../store/slices/auth";
import { removeAuthToken } from "../../store/helpers/helper";

const Settings = ({ onClose }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [identifier, setIdentifier] = useState("");
	const [password, setPassword] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showForm, setShowForm] = useState(false);

	const loading = useSelector(
		(state) => state.entities.auth.otherLoadings.deletingAccount
	);

	const handleUpdateProfile = () => navigate("/update-profile");
	const handleChangePassword = () => navigate("/change-password");
	const handleDeleteAccount = () => setShowModal(true);
	const handleLogout = () => {
		removeAuthToken();
		setTimeout(() => {
			navigate("/login");
		}, 3000);
	};

	const handleModalYes = () => {
		setShowModal(false);
		setShowForm(true);
	};

	const handleModalNo = () => {
		setShowModal(false);
		setIdentifier("");
		setPassword("");
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		dispatch(DeleteAccount({ identifier, password }));
		setIdentifier("");
		setPassword("");
		setShowForm(false);
	};

	const handleFormCancel = () => {
		setShowForm(false);
		setIdentifier("");
		setPassword("");
	};

	return (
		<div className="settings-overlay">
			<div className="settings-container">
				<button
					className="close-button"
					onClick={onClose}
				>
					<FaTimes />
				</button>
				<h2>Account Settings</h2>
				<div className="settings-options">
					<button
						className="settings-button"
						onClick={handleUpdateProfile}
					>
						<FaUser />
						<span>Update Profile</span>
					</button>
					<button
						className="settings-button"
						onClick={handleChangePassword}
					>
						<FaLock />
						<span>Change Password</span>
					</button>
					<button
						className="settings-button delete"
						onClick={handleDeleteAccount}
					>
						<FaTrashAlt />
						<span>Delete Account</span>
					</button>
					<button
						className="settings-button"
						onClick={handleLogout}
					>
						<FaSignOutAlt />
						<span>Log Out</span>
					</button>
				</div>
			</div>

			{(showModal || showForm) && (
				<div className="modal-overlay">
					<div className="modal-content">
						{showModal ? (
							<>
								<h3>Confirm Account Deletion</h3>
								<p>
									Are you sure you want to delete your account? This action
									cannot be undone.
								</p>
								<div className="modal-buttons">
									<button
										className="modal-button cancel"
										onClick={handleModalNo}
									>
										No, Keep My Account
									</button>
									<button
										className="modal-button confirm"
										onClick={handleModalYes}
									>
										Yes, Delete My Account
									</button>
								</div>
							</>
						) : (
							<form onSubmit={handleFormSubmit}>
								<h3>Account Deletion</h3>
								<div className="form-group">
									<label htmlFor="identifier">Username or Email</label>
									<input
										type="text"
										id="identifier"
										value={identifier}
										onChange={(e) => setIdentifier(e.target.value)}
										required
									/>
								</div>
								<div className="form-group">
									<label htmlFor="password">Password</label>
									<input
										type="password"
										id="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								</div>
								<div className="modal-buttons">
									<button
										type="button"
										className="modal-button cancel"
										onClick={handleFormCancel}
									>
										Cancel
									</button>
									<button
										type="submit"
										className="modal-button confirm"
										disabled={loading}
									>
										{loading ? "Deleting..." : "Confirm Deletion"}
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Settings;
