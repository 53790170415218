import React, { useEffect, useRef, useState } from "react";
import "./liveStart.css";
import { MdVerified } from "react-icons/md";
import Avatar from "../../assets/Avatar.svg";
import VideoLive from "../../assets/video-live.svg";
import eye from "../../assets/eye.svg";
import Socket from "./socket";
import { getAuthToken } from "../../store/helpers/helper";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { comment } from "../../store/slices/explore";
import LivePage from "../live/LivePage";

/**Adding agora SDK */
import {
	useJoin,
	useLocalMicrophoneTrack,
	useLocalCameraTrack,
	usePublish,
} from "agora-rtc-react";
import UserLiveNow from "../../components/userlivenow/UserLiveNow";
import { user_Helper } from "../../store/helpers/user";
import moment from "moment";
import Pagination from "../../components/pagination/Pagination";
import { Loader } from "../../utils/loader/loader";

const LiveStart = ({ client }) => {
	const authtoken = getAuthToken();
	const socketRef = useRef(null);
	const [startLive, setStartLive] = useState(false);
	const [joinLive, setjoinLive] = useState(false);
	const [loadingLive, setLoadingLive] = useState(true);
	const [allStreams, setAllStreams] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [liveInfo, setLiveInfo] = useState({ title: "", description: "" });

	const [calling, setCalling] = useState(false);
	const [appId, setAppId] = useState("08c3fd0fa4784b79bb3f1837898ac128");
	const [channel, setChannel] = useState("minimie");
	const [token, setToken] = useState("");

	useJoin(
		{
			appid: appId,
			channel: channel,
			token: token ? token : null,
		},
		calling
	);

	// Local user
	const [micOn, setMic] = useState(false);
	const [cameraOn, setCamera] = useState(false);
	const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
	const { localCameraTrack } = useLocalCameraTrack(cameraOn);
	// Remote user
	usePublish([localMicrophoneTrack, localCameraTrack]);

	//

	// initiate a ws connection to the livestream Endpoint
	useEffect(() => {
		if (socketRef.current === null) {
			socketRef.current = Socket(authtoken, "live");
		}
		return () => {};
	}, [authtoken]);

	//  listen for server calls
	useEffect(() => {
		if (!socketRef.current) return;
		/* Return all ongoing livstream*/

		socketRef.current.on("get_livestreams", (data) => {
			setLoadingLive(false);
			// I am maintaining a local state for all the livestreams
			setAllStreams(data);
		});
		/* exit to livstream*/
		socketRef.current.on("exit_Livestream", (channel) => {
			setAllStreams((streams) => {
				const updatedStreams = [...streams];
				const channelIndex = updatedStreams.findIndex(
					(v) => v.channel === channel
				);

				if (channelIndex === -1) return updatedStreams;
				if (updatedStreams[channelIndex].currentViewers > 0) {
					updatedStreams[channelIndex].currentViewers -= 1;
				}
				return updatedStreams;
			});
		});

		return () => {
			socketRef.current.off("get_livestreams");
			socketRef.current.off("exit_Livestream");
		};
	}, []);

	const JoinLivestream = (channel, description, title) => {
		socketRef.current.emit("join_livestream", channel, (error, message) => {
			if (error) return toast.error(error);
			toast.success(message);
			setChannel(channel);
			client.setClientRole("audience");
			setCalling(true);
			setjoinLive(true);
			setStartLive(false);

			setLiveInfo({
				title,
				description,
				channel,
			});
		});
		client.on("user-published", async (user, mediaType) => {
			await client.subscribe(user, mediaType);
			console.log(`Subscribed to user ${user.uid}'s ${mediaType}`);

			if (mediaType === "video") {
				const remoteVideoTrack = user.videoTrack;
				const remoteAudioTrack = user.audioTrack;

				// Attach the remote video track to a DOM element
				const remotePlayerContainer = document.getElementById(`vid1`);
				if (remoteVideoTrack && remotePlayerContainer) {
					remoteVideoTrack.play(remotePlayerContainer);
				}

				// Play remote audio
				if (remoteAudioTrack) {
					remoteAudioTrack.play();
				} else {
					console.log("No remote audio track found.");
				}
			}
		});

		client.on("user-unpublished", (user) => {
			joinLive(false);
			const remotePlayerContainer = document.getElementById(`vid1`);
			if (remotePlayerContainer) {
				remotePlayerContainer.remove();
			}
		});
	};

	const EndLiveStream = (channel) => {
		socketRef.current.emit(
			"end_livestream",
			{ channel: channel },
			(error, message) => {
				if (error) return toast.error(error);
				toast.success(message);
				setChannel("");
				setCalling(false);
				setjoinLive(false);
				setStartLive(false);
				setMic(false);
				setCamera(false);
			}
		);
	};
	const LeaveLiveStream = (channel) => {
		socketRef.current.emit(
			"exit_Livestream",
			{ channel: channel },
			async (error, message) => {
				if (error) return toast.error(error);
				setCalling(false);
				setChannel("");
				setjoinLive(false);
				setStartLive(false);
				if (client) {
					// Stop playing all tracks

					// Leave the channel
					await client.leave();

					console.log("Audience has left the livestream.");
				}
			}
		);
	};
	const Go_Live = (liveData) => {
		socketRef.current.emit("Start_Livestream", liveData, (error, liveInfo) => {
			if (error) return toast.error(error);
			console.log(liveInfo, "liveInfo");
			setChannel(liveInfo.channel);
			client.setClientRole("host");
			setCalling(true);
			setMic(true);
			setCamera(true);
			setjoinLive(false);
		});
	};
	const loadMorePosts = () => {
		const pageNo = currentPage + 1;
		socketRef.current.emit("get_livestreams", pageNo, (err, res) => {
			setAllStreams(res);
			console.log("minimie");
			setTimeout(() => {
				setCurrentPage((prev) => prev + 1);
			}, 2000);
		});
	};

	return (
		<div className="livestart">
			{startLive && (
				<LivePage
					stopLive={EndLiveStream}
					Go_Live={Go_Live}
					audioTrack={localMicrophoneTrack}
					cameraOn={cameraOn}
					micOn={micOn}
					videoTrack={localCameraTrack}
					setMic={setMic}
					setCamera={setCamera}
					setCalling={setCalling}
					calling={calling}
					setStartLive={setStartLive}
					channel={channel}
				/>
			)}
			{joinLive && (
				<UserLiveNow
					LeaveLiveStream={LeaveLiveStream}
					joinLive={joinLive}
					liveInfo={liveInfo}
					channel={channel}
				/>
			)}

			{startLive === false && joinLive === false && (
				<div className="livestart_start">
					<div className="start-live">
						<span
							onClick={() => {
								setStartLive(true);
							}}
							className="create_live"
						>
							{" "}
							Create new Livestream
						</span>
					</div>
					{loadingLive ? (
						<p className="No-post">
							{Loader("syncloader", loadingLive, undefined, 10, "gray")}
						</p>
					) : allStreams?.length > 0 ? (
						<>
							{allStreams.map((live) => {
								return (
									<div className="livestart__active__users">
										<div className="livestart__user">
											<div className="livestart__avatar">
												<img
													src={live.user.picture}
													alt="card 1"
												/>
											</div>
											<div className="livestart__usernames">
												<div className="livestart__usernames_name">
													<p>
														{user_Helper.username(
															live.user.username,
															"name",
															live.user.country,
															"code"
														)}{" "}
														{live.user.subscription.role === "subscribed" && (
															<MdVerified className="badge" />
														)}
													</p>
												</div>
												<p className="livestart__usernames_time">
													Went <span>Live</span>{" "}
													{moment(live.startTime).fromNow()}
												</p>
											</div>
										</div>
										<div className="livestart__user-live__action">
											<div className="livestart__video">
												<img
													src={VideoLive}
													alt="card 2"
												/>
												<p>LIVE</p>
											</div>
											<div className="user__views">
												<p>
													{live?.currentViewers <= 0 ? 0 : live?.currentViewers}
												</p>
												<img
													src={eye}
													alt="card 3"
												/>
											</div>

											<button
												onClick={() => {
													JoinLivestream(
														live.channel,
														live.description,
														live.title
													);
													setChannel(live.channel);
												}}
											>
												Join
											</button>
										</div>
									</div>
								);
							})}
							<Pagination loadMorePosts={loadMorePosts} />
						</>
					) : (
						<div>No Livestream available at the moment</div>
					)}
				</div>
			)}
		</div>
	);
};

export default LiveStart;
