import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import ShareModal from "../../components/ShareModal/ShareModal";
import PostComponent from "../../components/post/post";
import { useDispatch, useSelector } from "react-redux";
import { decodedAuthToken } from "../../store/helpers/helper";
import {
	save as timelineSave,
	react as timelineReact,
	Load__timeline,
} from "../../store/slices/time_capsule";
import {
	Load__following,
	save as followingSave,
	react as followingReact,
} from "../../store/slices/following";
import "./timeCapsule.css";
import { Loader } from "../../utils/loader/loader";

const TimeCapsule = () => {
	const [activeTab, setActiveTab] = useState("foryou");
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [currentPost, setCurrentPost] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);

	const TimeCapsule_posts = useSelector(
		(state) => state.entities.posts.timeCapsule
	);
	const following_posts = useSelector(
		(state) => state.entities.posts.following
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(Load__timeline());
		dispatch(Load__following({ userId: decodedAuthToken().id, pageNo: 1 }));
	}, [dispatch]);
	useEffect(() => {
		return () => {};
	}, []);

	const handleTabChange = (tabName) => {
		setActiveTab(tabName);
		setCurrentPage(1); // Reset pagination on tab change
	};

	const loadMorePosts = () => {
		setLoading(true);
		if (activeTab === "foryou") {
			dispatch(Load__timeline(currentPage + 1));
		} else {
			dispatch(
				Load__following({
					userId: decodedAuthToken().id,
					pageNo: currentPage + 1,
				})
			);
		}
		setTimeout(() => {
			setCurrentPage((prev) => prev + 1);
			setLoading(false);
		}, 2000);
	};

	return (
		<div className="extend-width">
			<div className="navigation">
				<button
					className={`tab ${activeTab === "foryou" ? "active" : ""}`}
					onClick={() => handleTabChange("foryou")}
				>
					For You
				</button>
				<button
					className={`tab ${activeTab === "following" ? "active" : ""}`}
					onClick={() => handleTabChange("following")}
				>
					Following
				</button>
			</div>
			<div className="h-90">
				{activeTab === "foryou" &&
					(TimeCapsule_posts.loading ? (
						<p className="No-post">
							{Loader(
								"syncloader",
								TimeCapsule_posts.loading,
								undefined,
								10,
								"gray"
							)}
						</p>
					) : TimeCapsule_posts.posts?.length > 0 ? (
						<PostComponent
							posts={TimeCapsule_posts.posts}
							react={timelineReact}
							save={timelineSave}
							dispatch={dispatch}
							loadMorePosts={loadMorePosts}
							parentId={"postFeedScroll"}
						/>
					) : (
						<p className="No-post">No posts available.</p>
					))}

				{activeTab === "following" &&
					(following_posts.loading ? (
						<p className="No-post">
							{Loader(
								"syncloader",
								following_posts.loading,
								undefined,
								10,
								"gray"
							)}
						</p>
					) : following_posts.posts?.length > 0 ? (
						<PostComponent
							posts={following_posts.posts}
							react={followingReact}
							save={followingSave}
							dispatch={dispatch}
							loadMorePosts={loadMorePosts}
							parentId={"postFeedScroll"}
						/>
					) : (
						<p className="No-post">No posts available </p>
					))}
			</div>
			{/* <ShareModal
				isOpen={isShareModalOpen}
				onClose={() => setIsShareModalOpen(false)}
				post={currentPost}
			/> */}
		</div>
	);
};

export default TimeCapsule;
