import React, { useState } from "react";
import { FaArrowLeft, FaSearch, FaTimes } from "react-icons/fa";
import SearchResultsPage from "./index";
import { dummySearchResults } from "./dummy";
import "./style.css";
import "./search.css";
import { useDispatch, useSelector } from "react-redux";
import { SearchItem } from "../../store/slices/search";
import toast from "react-hot-toast";

const Search = ({ onClose }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [showResults, setShowResults] = useState(false);
	const search = useSelector((state) => state.entities.posts.search);
	const dispatch = useDispatch();
	const handleSearch = (e) => {
		e.preventDefault();

		if (!searchQuery.trim()) {
			return toast.error("search cannot be empty");
		}
		dispatch(SearchItem(searchQuery));
		setShowResults(true);
	};

	const handleBackToSearch = () => {
		setShowResults(false);
		setSearchQuery("");
		dispatch({ type: "search/clearSearch" });
	};

	return (
		<div className="search-wrapper">
			<div className="app-search">
				<header className="app-header">
					{!showResults ? (
						<form
							onSubmit={handleSearch}
							className="search-form"
						>
							<div
								className="close-button__search"
								onClick={onClose}
							>
								<FaTimes />
							</div>
							<input
								type="text"
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								placeholder="Enter search query"
								className="search-input"
							/>
							{search.loading ? (
								"loading"
							) : (
								<button
									type="submit"
									className="search-button"
								>
									Search <FaSearch />
								</button>
							)}
						</form>
					) : (
						<button
							onClick={handleBackToSearch}
							className="back-button__search"
						>
							<FaArrowLeft /> <span>Back to Search</span>
						</button>
					)}
				</header>
				<main>
					{showResults && (
						<SearchResultsPage
							onClose={onClose}
							searchResults={search}
							searchQuery={searchQuery}
						/>
					)}
				</main>
			</div>
		</div>
	);
};

export default Search;

// // Simulate a search by filtering the dummy data
// const filteredResults = dummySearchResults.filter((result) =>
// 	result.type === "post"
// 		? result.title.toLowerCase().includes(searchQuery.toLowerCase())
// 		: result.name.toLowerCase().includes(searchQuery.toLowerCase())
// );
// setSearchResults(filteredResults);
// setShowResults(true);
