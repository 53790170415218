import React from "react";
import { Navigate } from "react-router-dom";
import { getAuthToken, isAuthTokenExpired } from "./store/helpers/helper";

const ProtectedRoute = ({ children }) => {

	return !!getAuthToken() && !isAuthTokenExpired() ? (
		<div>{children}</div>
	) : (
		<Navigate
			to="/login"
			replace
		/>
	);
};

export default ProtectedRoute;
