import { combineReducers } from "@reduxjs/toolkit";
import { explore_Reducer } from "./slices/explore";
import { discover_Reducer } from "./slices/discover";
import { trending_Reducer } from "./slices/trending";
import { timeCapsule_Reducer } from "./slices/time_capsule";
import { postDetail_Reducer } from "./slices/post_details";
import { following_Reducer } from "./slices/following";
import { search_Reducer } from "./slices/search";

export default combineReducers({
	explore: explore_Reducer,
	discover: discover_Reducer,
	trending: trending_Reducer, 
	timeCapsule: timeCapsule_Reducer,
	postDetail: postDetail_Reducer,
	following: following_Reducer,
  search: search_Reducer,
  
});
