import React, { useEffect, useState } from "react";
import "./explore.css";
import ExploreCard from "./ExploreCard";
import explorePosts from "./ExplorePosts";
import SuggestedCatalog from "../../components/suggestedcatalog/SuggestedCatalog";
import { useDispatch, useSelector } from "react-redux";
import {
	Load__explore,
	react,
	save,
	delete_post,
	toggleFavouriteStatus,
} from "../../store/slices/explore";
import PostComponent from "../../components/post/post";
import Pagination from "../../components/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../utils/loader/loader";
import AdvertWidget from "../../components/Widgets/Advert Widget/AdvertWidget";

const Explore = () => {
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [message, setMessage] = useState(null);
	const [showMessage, setShowMessage] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const subscriptions = [
		"Shows",
		"Concerts",
		"Stores",
		"Art",
		"Museums",
		"Tourism",
		"Beauty & Kits",
		"Fitness",
		"News",
		"Fashion TV",
		"Religion",
		"Others",
	];
	const explore = useSelector((state) => state.entities.posts.explore);
	const auth = useSelector((state) => state.entities.auth);
	const dispatch = useDispatch();
	const navigator = useNavigate();

	const handleCardClick = (catalog, pageNo = 1) => {
		setSelectedCategory(catalog);
		if (!auth.verifyStatus.verified && subscriptions.includes(catalog)) {
			setMessage("Subscribe to view this catalog");
			setShowMessage(true);
		} else {
			setMessage(null);
			dispatch(Load__explore({ pageNo, catalog }));
			setShowMessage(true);
		}
	};

	const handleBackClick = () => {
		setShowMessage(false);
		setSelectedCategory(null);
		dispatch({ type: "explore/postReset", action: { post: [] } });
		setMessage(null);
	};
	const loadMorePosts = () => {
		const pageNo = currentPage + 1;
		dispatch(Load__explore({ pageNo: pageNo, catalog: selectedCategory }));
		setTimeout(() => {
			setCurrentPage((prev) => prev + 1);
		}, 2000);
	};

	const categories = [
		{ name: "Music", style: { background: "var(--music-linear)" } },
		{ name: "Concert", style: { background: "var(--basketball-linear)" } },
		{ name: "Photography", style: { background: "var(--technology-linear)" } },
		{ name: "Politics", style: { background: "var(--movies-linear)" } },
		{ name: "Science", style: { background: "var(--cars-linear)" } },
		{
			name: "Nature",
			style: { background: "var(--housedec-linear)" },
		},
		{ name: "Pet", style: { background: "var(--music-linear)" } },

		{ name: "Afrobeats", style: { background: "var(--afrobeats-linear)" } },
		{ name: "Comedy", style: { background: "var(--comedy-linear)" } },
		{ name: "Dance", style: { background: "var(--dance-linear)" } },
		{ name: "Tag Stories", style: { background: "var(--tag-stories-linear)" } },
		{ name: "Fashion", style: { background: "var(--fashion-linear)" } },
		{ name: "Sports", style: { background: "var(--sports-linear)" } },
		{ name: "Football", style: { background: "var(--football-linear)" } },
		{ name: "Basketball", style: { background: "var(--basketball-linear)" } },
		{ name: "Technology", style: { background: "var(--technology-linear)" } },
		{ name: "Movies", style: { background: "var(--movies-linear)" } },
		{ name: "Cars", style: { background: "var(--cars-linear)" } },
		{
			name: "House Decoration",
			style: { background: "var(--housedec-linear)" },
		},
		{ name: "Space", style: { background: "var(--space-linear)" } },
		{ name: "Education", style: { background: "var(--education-linear)" } },
		{ name: "Economy", style: { background: "var(--economy-linear)" } },
		{ name: "Health", style: { background: "var(--health-linear)" } },
		{ name: "Food", style: { background: "var(--food-linear)" } },
		{ name: "Games", style: { background: "var(--games-linear)" } },
		{ name: "Others", style: { background: "var(--others-linear)" } },
		{
			name: "Quotes",
			style: { background: "var(--shows-linear)" },
		},
		{
			name: "Fashion TV",
			style: { background: "var(--fashion-tv-linear)" },
			restricted: true,
		},

		{
			name: "Art",
			style: { background: "var(--art-linear)" },
			restricted: true,
		},
		{
			name: "Museums",
			style: { background: "var(--museum-linear)" },
			restricted: true,
		},

		{
			name: "Shows",
			style: { background: "var(--shows-linear)" },
			restricted: true,
		},
		{
			name: "Stores",
			style: { background: "var(--stores-linear)" },
			restricted: true,
		},
		{
			name: "Tourism",
			style: { background: "var(--tourism-linear)" },
			restricted: true,
		},
		{
			name: "Beauty & Kits",
			style: { background: "var(--beauty-linear)" },
			restricted: true,
		},
		{
			name: "Fitness",
			style: { background: "var(--fitness-linear)" },
			restricted: true,
		},
		{
			name: "Yoga",
			style: { background: "var(--yoga-linear)" },
			restricted: true,
		},
		{
			name: "Religion",
			style: { background: "var(--religion-linear)" },
			restricted: true,
		},
		{
			name: "News",
			style: { background: "var(--news-linear)" },
			restricted: true,
		},
	];

	return (
		<div
			id="exploreScroll"
			className=" post-feed__explorer"
		>
			{showMessage ? (
				<div className="">
					<div
						onClick={handleBackClick}
						className="back-button"
					>
						← Back
					</div>

					{message ? (
						<p
							className="No-post"
							onClick={() => {
								navigator("/subscribe");
							}}
						>
							{message}
						</p>
					) : (
						<div>
							{explore.loading ? (
								<p className="No-post">
									{Loader("syncloader", explore.loading, undefined, 10, "gray")}
								</p>
							) : explore.posts?.length > 0 ? (
								<>
									<PostComponent
										dispatch={dispatch}
										deletePost={delete_post}
										star={toggleFavouriteStatus}
										posts={explore.posts}
										save={save}
										react={react}
										removeScroll
										parentId="exploreScroll"
										loadMorePosts={loadMorePosts}
									/>
								</>
							) : (
								<p className="No-post">No posts available for this category.</p>
							)}
						</div>
					)}
				</div>
			) : (
				<div className="explore">
					{categories.map((category) => (
						<ExploreCard
							key={category.name}
							category={category.name}
							style={category.style}
							onClick={() => handleCardClick(category.name)}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default Explore;
