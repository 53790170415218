import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const paymentSlice = createSlice({
	name: "payment",
	initialState: {
		paymentInfo: {},
		redirect: {
			promoteCatalog: false,
			mePremium: false,
			boostAccount: false,
		},
		loading: {
			promoteCatalog: false,
			mePremium: false,
			boostAccount: false,
		},
	},
	reducers: {
		initiatePromoteCatalogPayment: (data, action) => {
			data.loading.promoteCatalog = true;
		},
		promoteCatalogInfoSuccessful: (info, action) => {
			const { data } = action.payload;
			info.paymentInfo = data;
			info.loading.promoteCatalog = false;
			info.redirect.promoteCatalog = true;
		},
		promoteCatalogFailed: (data, action) => {
			data.loading.promoteCatalog = false;
		},
		initiateMePremPayment: (data, action) => {
			data.loading.mePremium = true;
		},
		premiumInfoSuccessful: (info, action) => {
			const { data } = action.payload;
			info.paymentInfo = data;
			info.loading.mePremium = false;
			info.redirect.mePremium = true;
		},
		mePremiumFailed: (data, action) => {
			data.loading.mePremium = false;
		},
		initiateboostAccPayment: (data, action) => {
			data.loading.boostAccount = true;
		},
		boostAccountInfoSuccessful: (info, action) => {
			const { data } = action.payload;
			info.paymentInfo = data;
			info.loading.boostAccount = false;
			info.redirect.boostAccount = true;
		},
		boostAccountFailed: (data, action) => {
			data.loading.boostAccount = false;
		},
		clearPayments: (data, action) => {
			data.paymentInfo = {};
		},
	},
});

const {
	boostAccountFailed,
	boostAccountInfoSuccessful,
	clearPayments,
	initiateMePremPayment,
	initiatePromoteCatalogPayment,
	initiateboostAccPayment,
	mePremiumFailed,
	premiumInfoSuccessful,
	promoteCatalogFailed,
	promoteCatalogInfoSuccessful,
} = paymentSlice.actions;

export const payment_Reducer = paymentSlice.reducer;

export const payForPremuim = ({isOneTime}) => (dispatch, getstate) => {
	dispatch(
		apiCallBegan({
			url: `subscribe/0?isOneTime=${isOneTime}`,
			method: "post",
			onStart: initiateMePremPayment.type,
			onSuccess: premiumInfoSuccessful.type,
			onError: mePremiumFailed.type,
		})
	);
};

export const PayForPromoteCatalogue = (state) => (dispatch, getstate) => {
	const { catalog, postId,isOneTime } = state;
	dispatch(
		apiCallBegan({
			url: `subscribe/1/${catalog}?postId=${postId}&isOneTime=${isOneTime}`,
			method: "post",
			onStart: initiatePromoteCatalogPayment.type,
			onSuccess: promoteCatalogInfoSuccessful.type,
			onError: promoteCatalogFailed.type,
		})
	);
};
export const PayForBoostAccount = ({isOneTime}) => (dispatch, getstate) => {
	dispatch(
		apiCallBegan({
			url: `subscribe/2?isOneTime=${isOneTime}`,
			method: "post",
			onStart: initiateboostAccPayment.type,
			onSuccess: boostAccountInfoSuccessful.type,
			onError: boostAccountFailed.type,
		})
	);
};
