import React from "react";
import "./chat.css";
import ChatList from "./ChatList/ChatList";
import ChatPage from "./ChatPage/ChatPage";
import ChatWidget from "./ChatWidget/ChatWidget";
import { Routes, Route, useLocation } from "react-router-dom";

const Chat = () => {
  const location = useLocation();
  const selectedUser = location.state;

  return (
    <div className="chat-container">
      <div className="chat-list-container">
        <ChatList />
      </div>
      <div className="chat-page-container">
        <Routes>
          <Route path="/" element={<ChatPage />} />
          <Route path="/:id" element={<ChatPage />} />
        </Routes>
      </div>
      <div className="chat-widget-container">
        <ChatWidget selectedUser={selectedUser} />
      </div>
    </div>
  );
};

export default Chat;
