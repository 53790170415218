import { combineReducers } from '@reduxjs/toolkit'
import entityReducer from "./entityReducer";
import { auth_Reducer } from "./slices/auth";



export default combineReducers({
 entities: entityReducer,
//  UI: UI reducer
// add reducer to UI states e.g Theme, apperance and UUU
})