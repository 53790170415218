// Comment.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import {
	FaHeart,
	FaRegHeart,
	FaRegComment,
	FaRegTrashAlt,
} from "react-icons/fa";
import { decodedAuthToken } from "../../../store/helpers/helper";
import {
	delete_comment,
	delete_reply,
	react_to_comment,
	reply_to_comment,
} from "../../../store/slices/post_details";
import "./comment.css";

const Comment = ({ text, comment, pinDetails }) => {
	const [newComment, setNewComment] = useState("");
	const user = useSelector((state) => state.entities.auth);
	const dispatch = useDispatch();
	const [showReplies, setShowReplies] = useState(false);

	const handleCommentChange = (e) => {
		setNewComment(e.target.value);
	};

	const handleReplySubmit = () => {
		console.log(comment._id, "comment ID");
		if (newComment.trim() !== "") {
			dispatch(
				reply_to_comment({
					postId: pinDetails.post._id,
					commentId: comment._id,
					text: newComment,
				})
			);
			setNewComment("");
		} else {
			toast.error("Textbox cannot be empty");
		}
	};

	return (
		<div className="comment">
			<div className="comment-header">
				<img
					src={comment.userImage}
					alt=""
					className="avatar"
				/>
				<div className="comment-info">
					<span className="username">@{comment.username}</span>
					<span className="date">{moment(comment.date).fromNow()}</span>
				</div>
			</div>
			<p className="comment-body">{text}</p>
			<div className="comment-actions">
				<button
					className={`action-btn ${
						comment?.likes.includes(user.userId) ? "liked" : ""
					}`}
					onClick={() => {
						dispatch(
							react_to_comment({
								postId: pinDetails.post._id,
								commentId: comment._id,
							})
						);
					}}
				>
					{comment?.likes.includes(user.userId) ? (
						<FaHeart className="icon" />
					) : (
						<FaRegHeart className="icon" />
					)}
					<span>{comment?.likes.length}</span>
				</button>
				<button
					className="action-btn"
					onClick={() => setShowReplies((v) => !v)}
				>
					<FaRegComment className="icon" />
					<span>{comment.replies.length}</span>
				</button>
				{(comment.username === decodedAuthToken().username ||
					pinDetails.post.user.username === decodedAuthToken().username) && (
					<button
						className="action-btn delete"
						onClick={() => {
							dispatch(
								delete_comment({
									postId: pinDetails.post._id,
									commentId: comment._id,
								})
							);
						}}
					>
						<FaRegTrashAlt className="icon" />
					</button>
				)}
			</div>
			{showReplies && (
				<div className="replies-section">
					{comment.replies.map((reply, replyIndex) => (
						<div
							key={replyIndex}
							className="reply"
						>
							<div className="reply-header">
								<img
									src={reply.userImage}
									alt=""
									className="avatar"
								/>
								<div className="reply-info">
									<span className="username">@{reply.username}</span>
									<span className="date">{moment(reply.date).fromNow()}</span>
								</div>
							</div>
							<p className="reply-body">{reply.text}</p>
							{(reply.username === decodedAuthToken().username ||
								pinDetails.post.user.username ===
									decodedAuthToken().username) && (
								<button
									className="delete-reply"
									onClick={() => {
										dispatch(
											delete_reply({
												postId: pinDetails.post._id,
												commentId: comment._id,
												replyId: reply._id,
											})
										);
									}}
								>
									<FaRegTrashAlt className="icon" />
								</button>
							)}
						</div>
					))}
					<div className="reply-input">
						<textarea
							placeholder="Write a reply..."
							value={newComment}
							onChange={handleCommentChange}
							onKeyDown={(e) => {
								if (e.key === "Enter" && !e.shiftKey) {
									e.preventDefault();
									handleReplySubmit();
								}
							}}
						/>
						<button
							onClick={() => {
								handleReplySubmit();
							}}
							className="reply-btn"
						>
							Post reply
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Comment;
