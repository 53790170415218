import { createSlice } from "@reduxjs/toolkit";
import { countries } from "../../pages/Profile/countries";
import { apiCallBegan } from "../api";
import {
	isAuthTokenExpired,
	removeAuthToken,
	setAuthToken,
} from "../helpers/helper";
import { baseUrl } from "../middleware/api";
import { user_Helper } from "../helpers/user";

const subUrl = "/login";

const auth_slice = createSlice({
	name: "auth",
	initialState: {
		userId: "",
		username: "",
		coverPicture: "",
		styledUsername: "",
		occupation: "",
		dateOfBirth: "",
		picture: "",
		liked: "",
		showAgeClock: false,
		saved: [],
		verifyStatus: {
			verified: false,
			loading: true,
		},
		isBoosted: false,
		status: {
			text: "",
			loading: false,
		},
		followers: {
			members: [],

			loading: false,
		},
		following: {
			members: [],
			loading: false,
		},
		suggestedAccounts: [],
		catalogue_preferences: [],

		profilePicture: "",
		login: {
			isLoggedIn: false,
			loading: false,
			shouldRedirect: false,
		},
		signup: {
			loading: false,
			shouldRedirect: false,
		},
		pswdReset: {
			loading: false,
			shouldRedirect: false,
		},
		pswdResetToken: {
			loading: false,
			shouldRedirect: false,
		},
		logout: {
			loading: false,
			shouldRedirect: false,
		},
		otherLoadings: {
			updatingProfile: false,
			deletingAccount: false,
		},
	},
	reducers: {
		follow_unfollow: (profile, action) => {
			profile.followers.members = action.payload.followersCount;
			profile.following.members = action.payload.followingCount;
		},
		suggestedAccounts: (profile, action) => {
			const { accounts } = action.payload;
			profile.suggestedAccounts = accounts;
		},
		addCataloguePreference: (profile, action) => {
			const { catalogue } = action.payload;
			profile.catalogue_preferences.push(catalogue);
		},
		onLogout: (user, actions) => {
			user.logout.shouldRedirect = true;
			user.login.isLoggedIn = false;
		},
		initateLogin: (user, action) => {
			user.login.loading = true;
		},
		LoginFalied: (user, action) => {
			user.login.loading = false;
		},
		LoginSuccessful: (user, action) => {
			const {
				username,
				picture,
				followers,
				following,
				status,
				verified,
				catalogue_preferences,
				token,
				country,
				userId,
				coverPicture,
				name,
				occupation,
				liked,
				saved,
				isBoosted,
				dateOfBirth,
				showAgeClock,
			} = action.payload;
			user.followers.members = followers;
			user.following.members = following;
			user.status.text = status;
			user.verifyStatus.verified = user_Helper.isUserSubscribed(verified);
			user.username = username;
			user.userId = userId;
			user.occupation = occupation;
			user.coverPicture = coverPicture;
			user.liked = liked;
			user.saved = saved;
			user.showAgeClock = showAgeClock;
			user.isBoosted = isBoosted;
			user.dateOfBirth = dateOfBirth;
			user.styledUsername = user_Helper.username(
				username,

				"name",
				country,
				"code"
			);
			user.picture = picture;
			user.name = name;
			user.catalogue_preferences = catalogue_preferences;
			user.login.loading = false;
			user.login.isLoggedIn = true;
			if (token) {
				setAuthToken(token);
				user.login.shouldRedirect = true;
			}
		},
		initatesignup: (user, action) => {
			user.signup.loading = true;
		},
		signupFailed: (user, action) => {
			user.signup.loading = false;
		},
		signupSuccessful: (user, action) => {
			const {
				username,
				picture,
				followers,
				following,
				status,
				verified,
				catalogue_preferences,
				token,
				country,
				coverPicture,
				name,
				occupation,
				liked,
				saved,
				isBoosted,
				dateOfBirth,
				showAgeClock,
			} = action.payload;

			user.signup.loading = false;
			user.signup.shouldRedirect = true;
			user.name = name;
			user.followers.members = followers;
			user.following.members = following;
			user.status.text = status;
			user.verifyStatus.verified = user_Helper.isUserSubscribed(verified);
			user.isBoosted = isBoosted;
			user.username = username;
			user.occupation = occupation;
			user.liked = liked;
			user.saved = saved;
			user.dateOfBirth = dateOfBirth;
			user.showAgeClock = showAgeClock;
			user.styledUsername = user_Helper.username(
				username,
				countries,
				"name",
				country,
				"code"
			);
			user.picture = picture;
			user.catalogue_preferences = catalogue_preferences;

			setAuthToken(token);
			user.coverPhoto = coverPicture;
			user.login.isLoggedIn = true;
			user.signup.loading = false;

			user.signup.shouldRedirect = true;
		},
		initateUpdate: (user, action) => {
			user.otherLoadings.updatingProfile = true;
		},

		UpdateFailed: (user, action) => {
			user.otherLoadings.updatingProfile = false;
		},
		// No provision for the display of date of birth yet
		updateDOBSuccessful: () => {},
		// DOB is separate since subscription is required
		updateProfileSuccessful: (user, action) => {
			user.otherLoadings.updatingProfile = false;
			const {
				username,
				picture,
				country,
				coverPicture,
				name,
				occupation,
				showAgeClock,
			} = action.payload;

			user.coverPicture = coverPicture;
			user.name = name;
			user.username = username;
			user.occupation = occupation;
			user.styledUsername = user_Helper.username(
				username,
				"name",
				country,
				"code"
			);
			user.picture = picture;
			user.country = country;
			user.showAgeClock = showAgeClock;
		},
		initatePswdReset: (user, action) => {
			user.pswdReset.loading = true;
		},
		updateSaved: (user, action) => {
			const { saved } = action.payload;
			user.saved = saved;
		},
		PswdResetFalied: (user, action) => {
			user.pswdReset.loading = false;
		},
		PswdResetSuccessful: (user, action) => {
			user.pswdReset.loading = false;
			user.pswdReset.shouldRedirect = true;
		},
		initatePswdResetToken: (user, action) => {
			user.pswdResetToken.loading = true;
		},
		PswdResetTokenFalied: (user, action) => {
			user.pswdResetToken.loading = false;
		},
		PswdResetTokenSuccessful: (user, action) => {
			user.pswdResetToken.loading = false;
		},
		initateAccountDelete: (user, action) => {
			user.otherLoadings.deletingAccount = true;
		},
		accountDeleteFailed: (user, action) => {
			user.otherLoadings.deletingAccount = false;
		},
		accountDeleteSuccessful: (user, action) => {
			user.otherLoadings.deletingAccount = false;
		},
	},
});

export const {
	LoginFalied,
	LoginSuccessful,
	initateLogin,
	onLogout,
	initatesignup,
	PswdResetFalied,
	PswdResetSuccessful,
	initatePswdReset,
	signupFailed,
	signupSuccessful,
	PswdResetTokenFalied,
	PswdResetTokenSuccessful,
	initatePswdResetToken,
	follow_unfollow,
	suggestedAccounts,
	addCataloguePreference,
	updateSaved,
	UpdateFailed,
	initateUpdate,
	updateDOBSuccessful,
	updateProfileSuccessful,
	accountDeleteFailed,
	accountDeleteSuccessful,
	initateAccountDelete,
} = auth_slice.actions;

export const auth_Reducer = auth_slice.reducer;

export const login = (loginData) => (dispatch, getState) => {
	const isExpired = isAuthTokenExpired();

	// if (!isExpired) {
	// 	return;
	// }
	// check if there is authToken and token is valid
	dispatch(
		apiCallBegan({
			url: `${subUrl}`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			values: loginData,
			onStart: initateLogin.type,
			onError: LoginFalied.type,
			onSuccess: LoginSuccessful.type,
		})
	);
};

export const signup = (data) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${baseUrl}/signup`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			onStart: initatesignup.type,
			onSuccess: signupSuccessful.type,
			onError: signupFailed.type,
			values: data,
		})
	);
};
export const resetPswd = (data) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `/reset-password`,
			method: "post",
			onStart: initatePswdReset.type,
			onSuccess: PswdResetSuccessful.type,
			onError: PswdResetFalied.type,
			values: data,
		})
	);
};
export const logout = () => (dispatch, getState) => {
	removeAuthToken();
	setTimeout(() => {
		dispatch({
			type: onLogout.type,
		});
	}, 3000);
};
export const pswdResetToken = (token) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `/reset-password/:${token}`,
			method: "post",
			onStart: initatePswdResetToken.type,
			onSuccess: PswdResetTokenSuccessful.type,
			onError: PswdResetTokenFalied.type,
		})
	);
};
export const followOrUnfollow = (username) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			method: "put",
			url: `/${username}/follow`,
			onSuccess: follow_unfollow.type,
		})
	);
};
export const suggestAccounts = () => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			method: "get",
			url: `/user/suggested`,
			onSuccess: suggestedAccounts.type,
		})
	);
};
export const addCatalog = (catalogueName) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			method: "put",
			url: `/catalogue/:${catalogueName}/follow`,
			onSuccess: addCataloguePreference.type,
		})
	);
};
// /update-profile/:username
export const updateOtherProfile = (data) => (dispatch, getState) => {
	console.log(data.username);
	dispatch(
		apiCallBegan({
			url: `/update-profile/${data.username}`,
			method: "patch",
			headers: { "Content-Type": "multipart/form-data" },
			onStart: initateUpdate.type,
			onSuccess: updateProfileSuccessful.type,
			onError: UpdateFailed.type,
			values: data.profileData,
		})
	);
};
export const Send_PasswordResetOtp =
	(userEmailOrUsername) => (dispatch, getState) => {
		dispatch(
			apiCallBegan({
				url: `reset-password`,
				method: "post",
				onStart: initatePswdResetToken.type,
				onSuccess: PswdResetTokenSuccessful.type,
				onError: PswdResetTokenFalied.type,
				// email is the identifer used for it at the backend
				values: { email: userEmailOrUsername },
			})
		);
	};
export const ResetPswd = (data) => (dispatch, getState) => {
	const { otp, password } = data;
	dispatch(
		apiCallBegan({
			url: `reset-password/${otp}`,
			method: "post",
			onStart: initatePswdReset.type,
			onSuccess: PswdResetSuccessful.type,
			onError: PswdResetFalied.type,
			values: { password },
		})
	);
};
export const DeleteAccount = (data) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `account/delete`,
			method: "delete",
			onStart: initateAccountDelete.type,
			onSuccess: accountDeleteSuccessful.type,
			onError: accountDeleteFailed.type,
			values: data,
		})
	);
};
