import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { PayForPromoteCatalogue } from "../../store/slices/payment";
import { FaCcAmex, FaCcMastercard, FaCreditCard, FaUber } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import "./promoteCatalog.css";
import { RiVisaLine } from "react-icons/ri";

const PromoteCatalog = () => {
	const dispatch = useDispatch();
	const payment = useSelector((state) => state.entities.payment);
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedOption, setSelectedOption] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState("");

	const { search } = useLocation();
	const queryString = useMemo(() => new URLSearchParams(search), [search]);
	const postId = queryString.get("postId");

	const handleAutoRenew = () => {
		setSelectedOption("Auto-Renew");
		setConfirmationMessage("Redirecting to Auto-Renew payment page...");
		dispatch(
			PayForPromoteCatalogue({
				catalog: "Music",
				postId: postId,
				isOneTime: false,
			})
		);
	};

	const handleOneTimePurchase = () => {
		setSelectedOption("One-Time Purchase");
		setConfirmationMessage("Redirecting to One-Time Purchase payment page...");
		dispatch(
			PayForPromoteCatalogue({
				catalog: "Music",
				postId: postId,
				isOneTime: true,
			})
		);
	};

	useEffect(() => {
		if (payment.redirect.promoteCatalog) {
			setTimeout(() => {
				window.location.href = payment.paymentInfo.authorization_url;
			}, 2000);
		}
		return () => {};
	}, [dispatch, payment.redirect]);

	return (
		<div className="promote-catalog-container">
			<h1 className="promote-catalog-title">Promote Catalog</h1>
			<div className="promote-catalog-content">
				<p className="promote-catalog-description">
					In ME promotions we are committed to promoting our users catalog or
					business catalog via the platform. Please use our self-service button
					below to subscribe and then we promote your catalog.
				</p>

				<p className="promote-catalog-highlight">
					Supercharge and power up your post!
				</p>
				<div className="promote-catalog-price">
					<h2>Promote Catalog: ₦59,776</h2>
					<p>Other currencies are also supported</p>
				</div>
				<div className="payment-methods">
					<h3>We accept:</h3>
					<div className="card-icons">
						<div className="card-icons">
							<RiVisaLine className="card-icon visa" />
							<FaCcMastercard className="card-icon mastercard" />
							<FaCcAmex className="card-icon amex" />
						</div>
					</div>
				</div>

				<p className="promotion-duration">Promotion is for 30 days.</p>
				<p>Please use our self-service button below to promote your catalog.</p>
				<div className="payment-options">
					<button
						onClick={handleAutoRenew}
						className="payment-option"
					>
						<BsCheckCircleFill className="check-icon" />
						Auto-Renew
					</button>
					<button
						onClick={handleOneTimePurchase}
						className="payment-option"
					>
						<BsCheckCircleFill className="check-icon" />
						One-Time Purchase
					</button>
				</div>

				{selectedOption && (
					<p className="selected-option">You selected: {selectedOption}</p>
				)}
				{confirmationMessage && (
					<p className="confirmation-message">{confirmationMessage}</p>
				)}

				<div className="enquiries">
					<MdEmail className="email-icon" />
					<p>
						For enquiries, send us a mail at{" "}
						<a href="mailto:ads@me-snapme.com">ads@me-snapme.com</a>
					</p>
				</div>

				<div className="ads-info">
					<span>Here for you, </span>
					<span className="ads-team">Ads Team</span>
				</div>

				<div className="support-ukraine">
					<FaUber className="ukraine-icon" />
					<p>Support Ukraine in time of need</p>
				</div>
			</div>
		</div>
	);
};

export default PromoteCatalog;
