import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { updateSaved } from "./auth";

const subUrl = "/pins";

// state manager for the postDetail page
const postDetail_slice = createSlice({
	name: "postDetail",
	initialState: {
		post: {},
		suggestedPosts: [],
		loading: true,
		// sub_loading actions
		otherLoadingOptions: {
			comment: false,
			react: false,
			save: false,
		},
	},
	reducers: {
		// post detail loading handlers
		postRequested: (post, action) => {
			post.loading = true;
		},
		postRecieved: (post_specifier, action) => {
			const { post } = action.payload;
			post_specifier.post = post;
			post_specifier.loading = false;
		},
		postRequestFailed: (post, action) => {
			post.loading = false;
		},

		// reaction loading handlers
		// for comments
		commentInitiated: (post, action) => {
			post.otherLoadingOptions.comment = true;
		},
		commentSuccessful: (post, action) => {
			const { postId, comment } = action.payload;
			post.post.comment = comment;
			post.otherLoadingOptions.comment = false;
		},
		commentFailed: (post, action) => {
			post.otherLoadingOptions.comment = false;
		},
		suggestedPinsInitiated: (post, action) => {
			post.otherLoadingOptions.comment = true;
		},
		suggestedPinsSuccessful: (posts, action) => {
			const { postId, post } = action.payload;
			posts.suggestedPosts = post;
			posts.otherLoadingOptions.comment = false;
		},
		suggestedPinsFailed: (post, action) => {
			post.otherLoadingOptions.comment = false;
		},
		// new
		deletecomment: (post, action) => {
			const { commentId, postId } = action.payload;
			post.post = post.comment.filter((a) => a._id.toString() !== commentId);
		},
		// for saves
		saveInitiated: (post, action) => {
			post.otherLoadingOptions.save = true;
		},
		saveSuccessful: (post, action) => {
			const { postId } = action.payload;

			post.post.saves++;

			post.otherLoadingOptions.save = false;
		},
		saveFailed: (post, action) => {
			post.otherLoadingOptions.save = false;
		},
		// for suggestion block
		suggestionReactionInitiated: (post, action) => {
			post.otherLoadingOptions.react = true;
		},
		suggestionReactionSuccessful: (posts, action) => {
			const { postId, likes } = action.payload;
			const postIndex = posts.suggestedPosts.findIndex(
				(a) => a._id.toString() === postId
			);
			if (postIndex !== -1) {
				posts.suggestedPosts[postIndex].likes = likes;
			}

			posts.otherLoadingOptions.react = false;
		},
		suggestionReactionFailed: (post, action) => {
			post.otherLoadingOptions.react = false;
		},
		// for reactions
		reactionInitiated: (post, action) => {
			post.otherLoadingOptions.react = true;
		},
		reactionSuccessful: (posts, action) => {
			const { postId, likes } = action.payload;

			posts.post.likes = likes;

			posts.otherLoadingOptions.react = false;
		},
		reactionFailed: (post, action) => {
			post.otherLoadingOptions.react = false;
		},
		// new
		reactToComment: (post, action) => {
			const { commentId, postId, userId, likes } = action.payload;
			console.log(commentId, "commentId");
			const commentIndex = post.post.comment.findIndex(
				(a) => a._id.toString() === commentId
			);
			post.post.comment[commentIndex].likes = likes;
		},
		shareSuccessful: (post, action) => {
			const { shares } = action.payload;
			post.post.shares = shares;
		},
		// new
		replyComment: (post, action) => {
			const { commentId, postId, text, username, userImage, date, _id, likes } =
				action.payload;

			const commentIndex = post.post.comment.findIndex(
				(a) => a._id.toString() === commentId
			);

			post.post.comment[commentIndex].replies.unshift({
				text,
				username,
				userImage,
				date,
				_id,
				likes,
			});
		},
		deleteReply: (posts, action) => {
			const { commentId, postId, replyId,replies } = action.payload;
			const commentIndex = posts.post.comment.findIndex(
				(a) => a._id.toString() === commentId
			);

			posts.post.comment[commentIndex].replies = replies
		},

		// Authenticate before performing action
		deleteComment: (posts, action) => {
			const { commentId, postId } = action.payload;
			posts.post.comment = posts.post.comment.filter((a) => {
				return a._id !== commentId;
			});
		},

			// Protected actions
   
      toggleFavourite: (posts, action) => {
        const { post } = action.payload;
        const postId = post._id;
  
        // Find the post by its ID
        const postIndex = posts.suggestedPosts.findIndex(
          (a) => a._id.toString() === postId
        );
  
        if (postIndex !== -1) {
          // Update the isFavourite property for the post
          posts.suggestedPosts[postIndex].isUserFavourite = post.isUserFavourite;
        }
      },
	},
});

export const {
	commentFailed,
	commentInitiated,
	commentSuccessful,
	postRecieved,
	postRequestFailed,
	postRequested,
	reactionFailed,
	reactionInitiated,
	reactionSuccessful,
	deleteComment,
	deleteReply,
	reactToComment,
	replyComment,
	suggestedPinsFailed,
	suggestedPinsInitiated,
	suggestedPinsSuccessful,
	saveFailed,
	deletecomment,
	saveInitiated,
	saveSuccessful,
	suggestionReactionFailed,
	suggestionReactionInitiated,
	suggestionReactionSuccessful,
	shareSuccessful,
  deletePost,
  toggleFavourite
} = postDetail_slice.actions;

export const postDetail_Reducer = postDetail_slice.reducer;
// Action creators
export const Load__pinDetails = (postId) => (dispatch, getState) => {
	console.log(postId, "postId");
	dispatch(
		apiCallBegan({
			url: `/pin-details/${postId}`,
			method: "get",
			onStart: postRequested.type,
			onSuccess: postRecieved.type,
			onError: postRequestFailed.type,
		})
	);
};
export const Load__suggestedPosts = (postId) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `/catalog/suggested/${postId}`,
			method: "get",
			onStart: suggestedPinsInitiated.type,
			onSuccess: suggestedPinsSuccessful.type,
			onError: suggestedPinsFailed.type,
		})
	);
};

export const comment = (commentInfo) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/comment/${commentInfo.postId}`,
			method: "post",
			onStart: commentInitiated.type,
			onSuccess: commentSuccessful.type,
			onError: commentFailed.type,
			values: { text: commentInfo.text },
		})
	);
};

export const react = (postId) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/like`,
			method: "put",
			onStart: reactionInitiated.type,
			onSuccess: reactionSuccessful.type,
			onError: reactionFailed.type,
		})
	);
};
export const suggeation_react = (postId) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/like`,
			method: "put",
			onStart: suggestionReactionInitiated.type,
			onSuccess: suggestionReactionSuccessful.type,
			onError: suggestionReactionFailed.type,
		})
	);
};

export const delete_comment = (commentInfo) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${commentInfo.postId}/delete/${commentInfo.commentId}`,
			method: "delete",

			onSuccess: deleteComment.type,
		})
	);
};
export const react_to_comment = (commentInfo) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${commentInfo.postId}/like/${commentInfo.commentId}`,
			method: "put",
			onSuccess: reactToComment.type,
		})
	);
};
export const reply_to_comment = (ReplyInfo) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${ReplyInfo.postId}/comment/${ReplyInfo.commentId}/reply`,
			method: "post",
			onSuccess: replyComment.type,
			values: { text: ReplyInfo.text },
		})
	);
};
export const delete_reply = (ReplyInfo) => (dispatch, getState) => {
	// commentId, postId, replyId

	dispatch(
		apiCallBegan({
			url: `${subUrl}/${ReplyInfo.postId}/${ReplyInfo.commentId}/delete/${ReplyInfo.replyId}`,
			method: "delete",
			onSuccess: deleteReply.type,
		})
	);
};

export const share = (data) => (dispatch, getState) => {
	const { postId } = data;

	dispatch(
		apiCallBegan({
			url: `${subUrl}/share-pin/${postId}`,
			method: "put",
			onSuccess: shareSuccessful.type,
		})
	);
};
export const delete_post = (postId) => (dispatch, getState) => {
	// commentId, postId, replyId
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}`, 
			method: "delete",
			onSuccess: deletePost.type,
		})
	);
};

export const toggleFavouriteStatus = (postId) => (dispatch, getState) => {
	// commentId, postId, replyId

	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/favourite-pins`,
			method: "patch",
			onSuccess: toggleFavourite.type,
		})
	);
};
