import React, { useState, useEffect } from 'react';
import './splashPage.css';
import MeLogo from "../../assets/me-logo.jpg";

const SplashPage = () => {
  const [downloads, setDownloads] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    // Initialize loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleInstall = () => {
    const isInstallable = window.confirm("Do you want to install the ME app?");
    if (isInstallable) {
      alert("ME app is being installed...");
      setDownloads(prevDownloads => prevDownloads + 1);
    } else {
      setIsNavigating(true);
      setTimeout(() => {
        window.location.href = "/discover";
      }, 3000); 
    }
  };

  const handleDiscover = () => {
    setIsNavigating(true);
    setTimeout(() => {
      window.location.href = "/discover";
    }, 2000); 
  };

  return (
    <div className="splash__page">
      {isLoading || isNavigating ? (
        <div className="loading__animation">
          <img src={MeLogo} alt="Loading..." className="loading__logo" />
        </div>
      ) : (
        <div className="splash__page-logo">
          <div className="splash__page-logo-img">
            <img src={MeLogo} alt="snapme_logo" />
          </div>

          <h3>Me</h3>
          <p>Cataloging & Microblogging</p>

          <button onClick={handleDiscover} className="splash__page-btn">
            Discover
          </button>

          <p>Powered from Ukraine & Africa</p>

          <button onClick={handleInstall} className="splash__page-btn">
            Install
          </button>

          <p>Downloads: {downloads}</p>
        </div>
      )}
    </div>
  );
}

export default SplashPage;
