import axios from "axios";
import toast from "react-hot-toast";
import * as actions from "../api";
import { getAuthToken } from "../helpers/helper";

// ["http://localhost:5000/api/v1" | "https://api.snapme-ng.com/api/v1" | "https://me-be.onrender.com/api/v1" | "http://185.224.139.225/api/v1" | "https://snapme-ng.com/spi/v1" | "https://api.snapme-ng.com" ]
export const baseUrl = "https://snapme-ng.com/api/v1";

const api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {
        console.log(action.type, "action.type");
        if (action.type !== actions.apiCallBegan.type) return next(action);
        const { url, method, values, headers, onStart, onSuccess, onError } =
          action.payload;
        if (onStart) dispatch({ type: onStart });
        next(action);

        try {
          const response = await axios.request({
            baseURL: baseUrl,
            headers: { ...headers, Authorization: `Bearer ${getAuthToken()}` },
            url,
            method,
            data: values,
          });
          if (response.data.message) toast.success(response.data.message);
          console.log(response.data, "suceess");
          if (onSuccess) dispatch({ type: onSuccess, payload: response.data });

          // handle specific action for certain responses
          if (onSuccess === "discover/saveSuccessful") {
            dispatch({ type: "auth/updateSaved", payload: response.data });
          }
        } catch (error) {
          console.log("failure", error, "error");
          if (onError) dispatch({ type: onError, payload: error.message });
          if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
          ) {
            console.log("1");
            // Default error message
            if (
              error.response.data.message &&
              typeof error.response.data.message === "string"
            ) {
              console.log("2");
              return toast.error(error.response.data.message);
            } // Show server-side validation errors

            //  detailed error message

            const errors = error.response.data.errors;
            if (errors && typeof errors === "object" && errors?.length > 0) {
              console.log("3");
              console.log(
                error.response.data.message.length,
                "error.response.data.message"
              );
              let count = 0;
              const timedErrors = setInterval(() => {
                if (count === errors.length) {
                  clearInterval(timedErrors);
                  return;
                } else {
                  toast.error(errors[count].message);
                }
                count++;
              }, 5000);
              return;
            }
          }
          console.log("4");
          return toast.error(error.message);

          // General
          // dispatch(actions.apiCallFailed(error.message));
          // Specific
        }
      };

export default api;
