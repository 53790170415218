import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { payForPremuim } from "../../store/slices/payment";
import { FaCcAmex, FaCcMastercard, FaCreditCard, FaUber } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import "./subscribe.css";
import { RiVisaLine } from "react-icons/ri";

const Subscribe = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const navigate = useNavigate();
  const payment = useSelector((state) => state.entities.payment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (payment.redirect.mePremium) {
      setTimeout(() => {
        window.location.href = payment.paymentInfo.authorization_url;
      }, 2000);
    }
    return () => {};
  }, [dispatch, payment.redirect]);

  const handleAutoRenew = () => {
    setSelectedOption("Auto-Renew");
    setConfirmationMessage("Redirecting to Auto-Renew payment page...");
    dispatch(payForPremuim({ isOneTime: false }));
  };

  const handleOneTimePayment = () => {
    setSelectedOption("One-Time Payment");
    setConfirmationMessage("Redirecting to One-Time Payment page...");
    dispatch(payForPremuim({ isOneTime: true }));
  };

  return (
    <div className="subscribe-container">
      <h1 className="subscribe-title">Subscribe</h1>
      <div className="subscribe-content">
        <p className="subscribe-description">
          You're about to go premium and join elite users with unique privileges
          to get verified on ME, create posts in all catalogs, access exclusive
          posts, and more…
        </p>
        <div className="subscribe-price">
          <h2>Subscribe: ₦12,800</h2>
          <p>Other currencies are also supported</p>
        </div>
        <div className="payment-methods">
          <h3>We accept:</h3>
          <div className="card-icons">
            <RiVisaLine className="card-icon visa" />
            <FaCcMastercard className="card-icon mastercard" />
            <FaCcAmex className="card-icon amex" />
          </div>
        </div>
        <p className="subscription-duration">Subscription is for 30 days.</p>
        <p>Please choose your preferred subscription billing system below.</p>
        <div className="payment-options">
          <button
            className={`payment-option ${selectedOption === "Auto-Renew" ? "selected" : ""}`}
            onClick={handleAutoRenew}
          >
            <BsCheckCircleFill className="check-icon" />
            Auto-Renew Payment
          </button>
          <button
            className={`payment-option ${selectedOption === "One-Time Payment" ? "selected" : ""}`}
            onClick={handleOneTimePayment}
          >
            <BsCheckCircleFill className="check-icon" />
            One-Time Payment
          </button>
        </div>
        {selectedOption && (
          <p className="selected-option">You selected: {selectedOption}</p>
        )}
        {confirmationMessage && (
          <p className="confirmation-message">{confirmationMessage}</p>
        )}
        <div className="support-ukraine">
          <FaUber className="ukraine-icon" />
          <p>Support Ukraine in time of need</p>
        </div>
      </div>
    </div>
  ); 
};

export default Subscribe;