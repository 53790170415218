import React, { useState } from "react";
import Comment from "./Comment";
import "./comment.css";
import { Feed } from "../../../components";
import {
	comment,
	react,
	delete_comment,
	reply_to_comment,
  
} from "../../../store/slices/post_details";
import { useDispatch } from "react-redux";
import moment from "moment";

const CommentSection = ({ comments, postId, pinDetails }) => {
	const [newComment, setNewComment] = useState("");
	const dispatch = useDispatch();

	const handleCommentChange = (e) => {
		setNewComment(e.target.value);
	};

	const handleCommentSubmit = (e) => {
		e.preventDefault();
		if (newComment.trim() !== "") {
			dispatch(
				comment({
					postId: postId,
					text: newComment,
				})
			);

			setNewComment("");
		}
	};

	// Function to format timestamp
	const formatFullDateTime = (dateString) => {
		const date = new Date(dateString);
		return date.toDateString() + " " + date.toLocaleTimeString();
	};

	return (
		<div className="comment-section">
			<div className="comments-list">
				{comments.map((comment) => (
					<Comment
						key={comment.id}
						text={comment.text}
						pinDetails={pinDetails}
						comment={comment}
						timestamp={formatFullDateTime(comment.date)} // Pass formatted timestamp
					/>
				))}
			</div>
			<form
				onSubmit={handleCommentSubmit}
				className="comment-form"
			>
				<textarea
					id="comment"
					placeholder="Add a comment..."
					value={newComment}
					onClick={(e) => e.stopPropagation()}
					onKeyDown={(e) => {
						if (e.key === "Enter" && !e.shiftKey) {
							e.preventDefault();
						}
					}}
					onChange={handleCommentChange}
				/>
				<button
					className="comment-btn"
					type="submit"
				>
					Post Comment
				</button>
			</form>
		</div>
	);
};

export default CommentSection;
