import "./fullScreen.css";
function FullscreenModal({ src, onClose }) {
	return (
		<div
			className="fullscreen-modal"
			onClick={onClose}
		>
			<img
				src={src}
				alt="Fullscreen"
				className="fullscreen-image"
			/>
		</div>
	);
}
export default FullscreenModal;
