import React from "react";
import "./aboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-container">
      <div className="text-primary about bg-white">
        <div className="body p-[24px] lg:px-[80px] flex flex-col items-center justify-center">
          <h1 className="text-2xl pt-12 pb-8 lg:text-5xl font-extrabold lg:pt-24 lg:pb-16">
            About Us
          </h1>
          <div className="terms_body text-base leading-7 lg:text-xl lg:leading-10">
            About Us <span>ME</span> is a platform of Flooter Pvt Ltd founded
            in 2021 in the port city of Kherson in South Ukraine & Africa. Flooter
            Pvt Ltd the parent company of <span>ME</span> offers virtual
            services & APIs that transcends across the globe such as; Networking,
            Space Technology, Al, Blockchain & Metaverse. <span>ME</span> as a
            global social square is premised on & inspired by the concept that
            “Every idea, inspiration or opinion in any social setting is a catalog
            arranged according to preferred lifestyle genres and then shared with
            the public as a unique catalog”. <span>ME</span> is robustly
            committed to users Privacy, Safety & Sanity. Welcome to the new era of
            cataloging & microblogging customization.
            <br />
            Happy cataloging with ME😀! We stand with Ukraine 
            <br />
            <span> ©2024 ME Flooter Pvt Ltd </span>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
