import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";
import api from "./middleware/api.js";
export default function () {
	return configureStore({
		reducer: reducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api),
		devTools: true,
	});
}
