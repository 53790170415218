import React from "react";
import "./activityWidgets.css";
import UserWidgets from "../User Widgets/UserWidgets";
import { user2, user3, user4} from "../../../assets";

const ActivityWidgets = () => {
  return (
    <div className="activity-widgets">
      <div className="activity-widgets__header">
        <h3>Activity</h3>
        <p>See all</p>
      </div>
      <div className="activity-widgets__flow">
        <UserWidgets
          userImage={user2}
          userName="anika_.UK"
          isVerified={true}
          isFollowing={false}
          notification="followed you"
        />
        <UserWidgets 
          userImage={user3}
          userName="talanX.SW"
          isVerified={true}
          isFollowing={true}
          notification="liked your comment"
        />
        <UserWidgets
          userImage={user4}
          userName="hayvac.SA"
          isVerified={true}
          isFollowing={true}
          notification="liked your post"
        />
        <UserWidgets
          userImage={user3}
          userName="justsar.CA"
          isVerified={false}
          isFollowing={true}
          notification="commented"
        />
      </div>
      <h4>yesterday</h4>
      <div className="activity-widgets__overflow">
        <UserWidgets
          userImage={user3}
          userName="talanX.SW"
          isVerified={true}
          isFollowing={true}
          notification="liked your comment"
        />
        <UserWidgets
          userImage={user2}
          userName="justsar.CA"
          isVerified={false}
          isFollowing={true}
          notification="commented"
        />
      </div>
    </div>
  );
};

export default ActivityWidgets;
