import React, { useEffect, useRef, useState } from "react";
import "./advertPage.css";
import { Link } from "react-router-dom";
import { CiCircleInfo } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import backupImg from "../../assets/asian-lady.jpg"
import { LoadMyMetaData } from "../../store/slices/profile";
// I use an opensource public ads
const ads = [
	"https://www.w3schools.com/html/mov_bbb.mp4",
	"https://www.w3schools.com/html/movie.mp4",
	"https://www.w3schools.com/html/mov_bbb.mp4",
];

const AdvertPage = () => {
	const [currentAd, setCurrentAd] = useState(0);
	const videoRef = useRef(null);
  const dispatch = useDispatch()
	const auth  = useSelector((state) => {
		return state.entities.auth;
	});
  useEffect(() => {
		dispatch(LoadMyMetaData());
		return () => {};
	}, [dispatch]); 
  console.log(auth,"auth")
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
		}, 180000); // 3 minutes in milliseconds

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (videoRef.current) {
				const rect = videoRef.current.getBoundingClientRect();
				if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
					videoRef.current.play();
				} else {
					videoRef.current.pause();
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<div className="ad">
			<div className="ad-nav">
				<div className="ad-nav__left">
					<a href="/">Create New Ad</a>
					<a href="/">Monitor Ad</a>
				</div>
				<Link
					to="/profile"
					className="ad-nav__right-avatar"
				>
          <img src={auth?.picture|| backupImg} alt="" />
        </Link>
			</div>
			<div className="ad-page-container">
				<div className="ad-page">
					<div className="ad-page__header">
						<div className="ad-page__header-text">
							<p>Step 1 of 4</p>
							<h1>Add basic information</h1>
						</div>
						<CiCircleInfo />
					</div>
					<div className="ad-page__content">
						<div className="ad-page__section">
							<h2>Advert name</h2>
							<input
								type="text"
								placeholder="Enter advert name"
								className="ad-input"
							/>
						</div>
						<div className="ad-page__section">
							<h2>Add publicly tags</h2>
							<p>
								These tags will allow you to filter more effectively on the
								advert view section such as Tech, Apple, MacBook, Gadgets
							</p>
						</div>
						<div className="ad-page__section">
							<h2>Timing</h2>
							<p>Set advert launch and end dates</p>
							<div className="ad-timing">
								<div>
									<label>Start Date</label>
									<input
										type="date"
										className="ad-input"
									/>
								</div>
								<div>
									<label>End Date</label>
									<input
										type="date"
										className="ad-input"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="ad-container">
						<video
							ref={videoRef}
							src={ads[currentAd]}
							className="ad-video"
							muted
							loop
							controls
						/>
					</div>
					<div className="ad-login">
						<p>
							<b>Place Advert here?</b>
							<br />
							For enquires and payments, send us an email to ads@me-snapme.com
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdvertPage;
