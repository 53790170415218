import React from "react";
import "./exploreCard.css";

const ExploreCard = ({ category, style, onClick, restricted, isSubscribed }) => {
  const handleClick = () => {
    if (!restricted || (restricted && isSubscribed)) {
      onClick();
    } else {
      alert(`You need to subscribe to view posts in the ${category} category.`);
    }
  };

  return (
    <div className="explore-card" style={style} onClick={handleClick}>
      {category}
    </div>
  );
};

export default ExploreCard;
