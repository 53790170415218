import React from "react";
import ActivityWidgets from "../../../components/Widgets/Activity Widgets/ActivityWidgets";
import SuggestedWidgets from "../../../components/Widgets/Suggested Widgets/SuggestedWidgets";
import AdvertWidget from "../../../components/Widgets/Advert Widget/AdvertWidget";
import "./activity.css";

function ActivityPage() {
  return (
    <div className="activity-page">
      <ActivityWidgets />
      <SuggestedWidgets />
      <AdvertWidget />
    </div>
  );
}

export default ActivityPage;
