import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Sidebar from '../../components/Sidebar/Sidebar';
import Widgets from '../../components/Widgets/Widgets';
import './termsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-page-container">
      {/* Sidebar on the left */}
      <div className="side_bar">
        <Sidebar />
      </div>

      {/* Main content in the middle */}
      <div className="terms-content">
        <h2 className="terms-title">Terms of Service</h2>
        <ul className="terms-list">
          <li className="terms-item">
            <FaArrowRight className="terms-icon" />
            <div>
              <h3 className="terms-subtitle">Introduction</h3>
              <p>
                These terms of service ("Terms", "Agreement") are an agreement between the Application and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the web application and any of its products or services (collectively, "Application" or "Services").
              </p>
            </div>
          </li>

          <li className="terms-item">
            <FaArrowRight className="terms-icon" />
            <div>
              <h3 className="terms-subtitle">Account Terms</h3>
              <p>
                You must be at least 13 years of age to use this Application. You must be a human. Accounts registered by "bots" or other automated methods are not permitted. You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process. You are responsible for maintaining the security of your account and password. The Application cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. You may not use the Application for any illegal or unauthorized purpose. You must not, in the use of the Application, violate any laws in your jurisdiction (including but not limited to copyright laws).
              </p>
            </div>
          </li>

          <li className="terms-item">
            <FaArrowRight className="terms-icon" />
            <div>
              <h3 className="terms-subtitle">Use of the Service</h3>
              <p>
                The Application grants you a personal, non-transferable, non-exclusive, revocable, limited license to use the Application and the Services provided. You may use the Application and the Services only for personal, non-commercial use. You shall not copy, distribute, make derivative works, display, perform, or sell any part of the Application or the Services, except as permitted by the Application. The Application retains all right, title, and interest in the Application, including all intellectual property rights. Your use of the Application is subject to all applicable laws and regulations, and you are solely responsible for the substance of your communications through the Application.
              </p>
            </div>
          </li>

          <li className="terms-item">
            <FaArrowRight className="terms-icon" />
            <div>
              <h3 className="terms-subtitle">Content</h3>
              <p>
                You understand that all information (such as data files, written text, computer software, music, audio files or other sounds, photographs, videos or other images) which you may have access to as part of, or through your use of, the Services are the sole responsibility of the person from which such content originated. All such information is referred to below as the "Content". The Application shall not be held responsible for any errors or omissions in any Content or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted, or otherwise made available via the Services.
              </p>
            </div>
          </li>

        </ul>
      </div>

      {/* Widgets on the right */}
      <div className="wid_get">
        <Widgets />
      </div>
    </div>
  );
};

export default TermsOfService;
