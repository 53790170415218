import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { LoginFalied, initateLogin, LoginSuccessful } from "./auth";
import { useSelector } from "react-redux";
import { user_Helper } from "../helpers/user";

const subUrl = "/pins";

const all_tabs = [
	"allPosts",
	"imagePosts",
	"audioPosts",
	"videoPosts",
	"starredPosts",
];
const OthersProfile = createSlice({
	name: "userProfile",
	initialState: {
		allPosts: {
			posts: [],
			totalPosts: 0,
			loading: false,
			show: "allposts",
		},
		saved: [],
		showAgeClock: false,
		dateOfBirth: "",
		userId: "",
		username: "",
		coverPicture: "",
		styledUsername: "",
		occupation: "",
		picture: "",
		verifyStatus: {
			verified: false,
			loading: true,
		},
		isBoosted: false,
		status: {
			text: "",
			loading: false,
		},
		followers: {
			members: [],
			loading: false,
		},
		following: {
			members: [],
			loading: false,
		},

		starredPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "starredposts",
		},
		textPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "textposts",
		},
		imagePosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "imageposts",
		},
		videoPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "videoposts",
		},
		audioPosts: {
			posts: [],
			loading: false,
			pageNo: 0,
			show: "audioposts",
		},

		otherLoadingOptions: {
			comment: false,
			react: false,
			save: false,
		},
	},

	reducers: {
		// videoposts
		initiateLoadVideoPost: (profile, action) => {
			profile.videoPosts.loading = true;
		},
		loadVideoPostSuccessful: (profile, action) => {
			profile.videoPosts.posts = action.payload.posts;
			profile.videoPosts.pageNo = action.payload.pageNo;
			profile.videoPosts.loading = false;
		},
		loadVideoPostFailed: (profile, action) => {
			profile.videoPosts.loading = false;
		},

		// audio posts
		initateLoadAudioPost: (profile, action) => {
			profile.audioPosts.loading = true;
		},
		loadAudioPostSuccessful: (profile, action) => {
			profile.audioPosts.posts = action.payload.posts;
			profile.audioPosts.pageNo = action.payload.pageNo;
			profile.audioPosts.loading = false;
		},
		loadAudioPostFailed: (profile, action) => {
			profile.audioPosts.loading = false;
		},

		// for starred posts
		initiateLoadStarredPost: (profile, action) => {
			profile.starredPosts.loading = true;
		},
		loadStarredPostSuccessful: (profile, action) => {
			profile.starredPosts.posts = action.payload.posts;
			profile.starredPosts.pageNo = action.payload.pageNo;
			profile.starredPosts.loading = false;
		},
		LoadStarredPostFailed: (profile, action) => {
			profile.starredPosts.loading = true;
		},
		initiateLoadImagePost: (profile, action) => {
			profile.imagePosts.loading = true;
		},
		loadImagePostSuccessful: (profile, action) => {
			profile.imagePosts.posts = action.payload.posts;
			profile.imagePosts.pageNo = action.payload.pageNo;
			profile.imagePosts.loading = false;
		},
		loadImagePostFailed: (profile, action) => {
			profile.imagePosts.loading = false;
		},

		// New actions for text posts
		initiateLoadTextPost: (profile, action) => {
			profile.textPosts.loading = true;
		},
		loadTextPostSuccessful: (profile, action) => {
			profile.textPosts.posts = action.payload.posts;
			profile.textPosts.pageNo = action.payload.pageNo;
			profile.textPosts.loading = false;
		},
		loadTextPostFailed: (profile, action) => {
			profile.textPosts.loading = false;
		},

		// Actions for all posts
		initiateLoadAllPost: (profile, action) => {
			profile.allPosts.loading = true;
		},
		loadAllPostSuccessful: (profile, action) => {
			const { posts, totalPosts } = action.payload;
			profile.allPosts.posts = posts;
			profile.allPosts.totalPosts = totalPosts;
			profile.allPosts.loading = false;
		},
		loadAllPostFailed: (profile, action) => {
			profile.allPosts.loading = false;
		},
		saveInitiated: (profile, action) => {
			profile.otherLoadingOptions.save = true;
		},
		saveSuccessful: (profile, action) => {
			const { postId } = action.payload;
			all_tabs.forEach((v) => {
				let postIndex = profile[v].posts.findIndex(
					(a) => a._id.toString() === postId
				);
				if (postIndex !== -1) {
					profile[v].posts[postIndex].saves++;
				}
			});

			profile.otherLoadingOptions.save = false;
		},
		saveFailed: (profile, action) => {
			profile.otherLoadingOptions.save = false;
		},
		reactionInitiated: (profile, action) => {
			profile.otherLoadingOptions.react = true;
		},
		reactionSuccessful: (profile, action) => {
			const { postId, likes } = action.payload;

			all_tabs.forEach((v) => {
				let postIndex = profile[v].posts.findIndex(
					(a) => a._id.toString() === postId
				);
				if (postIndex !== -1) {
					profile[v].posts[postIndex].likes = likes;
				}
			});

			profile.otherLoadingOptions.react = false;
		},
		reactionFailed: (profile, action) => {
			profile.otherLoadingOptions.react = false;
		},
		loadUserData: (user, action) => {
			const {
				username,
				picture,
				followers,
				following,
				status,
				verified,
				catalogue_preferences,
				country,
				userId,
				coverPicture,
				name,
				saved,
				occupation,
				isBoosted,
				showAgeClock,
        dateOfBirth
			} = action.payload;
			user.followers.members = followers;
			user.following.members = following;
			user.status.text = status;
			user.verifyStatus.verified = user_Helper.isUserSubscribed(verified);
			user.username = username;
			user.userId = userId;
			user.occupation = occupation;
			user.saved = saved;
			user.coverPicture = coverPicture;
			user.isBoosted = isBoosted;
      user.dateOfBirth = dateOfBirth;
			user.styledUsername = user_Helper.username(
				username,

				"name",
				country,
				"code"
			);
			user.picture = picture;
			user.name = name;
			user.showAgeClock = showAgeClock;
			user.catalogue_preferences = catalogue_preferences;
		},
	},
});

export const {
	initateLoadAudioPost,
	initiateLoadAllPost,
	initiateLoadImagePost,
	initiateLoadTextPost,
	initiateLoadVideoPost,
	initiateLoadStarredPost,
	LoadStarredPostFailed,
	loadAllPostFailed,
	loadAllPostSuccessful,
	loadAudioPostFailed,
	loadAudioPostSuccessful,
	loadImagePostFailed,
	loadImagePostSuccessful,
	loadTextPostFailed,
	loadTextPostSuccessful,
	loadVideoPostFailed,
	loadVideoPostSuccessful,
	loadStarredPostSuccessful,
	reactionInitiated,
	reactionSuccessful,
	reactionFailed,
	saveInitiated,
	saveSuccessful,
	saveFailed,
	loadUserData,
} = OthersProfile.actions;

export const Othersprofile_Reducer = OthersProfile.reducer;

export const LoadMyPosts = (data) => (dispatch, getState) => {
	// const currentState = getState().entities.profile;
	// if (currentState.allPosts.posts.length > 0) return;
	const { pageNo, username } = data;

	dispatch(
		apiCallBegan({
			url: `${subUrl}/otherspins/${username}?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadAllPost.type,
			onSuccess: loadAllPostSuccessful.type,
			onError: loadAllPostFailed.type,
		})
	);
};
export const LoadMyMetaData = (username) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `profile?username=${username}`,
			method: "get",
			onSuccess: loadUserData.type,
		})
	);
};

export const loadImagePosts = (data) => (dispatch, getSatate) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/${"image"}?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadImagePost.type,
			onSuccess: loadImagePostSuccessful.type,
			onError: loadImagePostFailed.type,
		})
	);
};

export const loadVideoPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/${"video"}?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadVideoPost.type,
			onSuccess: loadVideoPostSuccessful.type,
			onError: loadVideoPostFailed.type,
		})
	);
};

export const loadAudioPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/${"audio"}?page=${pageNo}`,
			method: "get",
			onStart: initateLoadAudioPost.type,
			onSuccess: loadAudioPostSuccessful.type,
			onError: loadAudioPostFailed.type,
		})
	);
};

export const loadTextPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${userId}/text?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadTextPost.type,
			onSuccess: loadTextPostSuccessful.type,
			onError: loadTextPostFailed.type,
		})
	);
};

// starred posts are the same as favourite posts
export const loadStarredPosts = (data) => (dispatch, getState) => {
	const { pageNo, userId } = data;
	dispatch(
		apiCallBegan({
			url: `/pins/${userId}/favourite-pins?page=${pageNo}`,
			method: "get",
			onStart: initiateLoadStarredPost.type,
			onSuccess: loadStarredPostSuccessful.type,
			onError: LoadStarredPostFailed.type,
		})
	);
};

export const save = (postId) => (dispatch, getState) => {
	// const { userId } = getState().entities.profile;

	dispatch(
		apiCallBegan({
			url: `${subUrl}/save/${postId}`,
			method: "post",
			onStart: saveInitiated.type,
			onSuccess: saveSuccessful.type,
			onError: saveFailed.type,
		})
	);
};
export const react = (postId) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/like`,
			method: "put",
			onStart: reactionInitiated.type,
			onSuccess: reactionSuccessful.type,
			onError: reactionFailed.type,
		})
	);
};
