import React from 'react';
import './support.css';

const Support = () => {
  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h1 className="text-2xl font-bold text-gray-900">Support</h1>
      <p className="text-gray-700">
        At ME we’re always here to respond to your complaints swiftly and then accord a commensurate attention in response.
      </p>
      <p className="text-gray-700">
        We’re also here to guide, support and answer your frequently asked questions (FAQs) which may arise as a result of you consuming our API services.
      </p>
      <p className="text-gray-700">
        Kindly reach out to us on: 
        <a href="mailto:support@me-snapme.com" className="text-blue-500 hover:underline">support@me-snapme.com</a>
      </p>
      <p className="text-gray-700">
        Warm Regards,
      </p>
      <p className="text-gray-900 font-semibold">ME Team</p>
    </div>
  );
}

export default Support;
