export const allowedMediaExte = {
	VideoExt: [
		"mp4",
		"MP4",
		"m4a",
		"3gp",
		"avi",
		"mkv",
		"wmv",
		"flv",
		"mov",
		"webm",
	],
	ImageExt: [
		"jpg",
		"jpeg",
		"png",
		"gif",
		"webp",
		"bmp",
		"tiff",
		"tif",
		"ico",
		"pdf",
		"svg",
		"psd",
		"eps",
	],
	audioExt: ["mp3", "wav", "ogg", "flac"],
};

// Function to get file extension
export const getFileExtension = (url) => {
	if (!url) return;
	console.log(url);
	return url.split(".").pop().toLowerCase();
};

// Function to determine media type based on extension
export const getMediaType = (url) => {
	const extension = getFileExtension(url);
	if (allowedMediaExte.VideoExt.includes(extension)) return "video";
	if (allowedMediaExte.audioExt.includes(extension)) return "audio";
	if (allowedMediaExte.ImageExt.includes(extension)) return "image";
	return null;
};
