import { combineReducers } from "@reduxjs/toolkit";
import { auth_Reducer } from "./slices/auth";
import { postDetail_Reducer } from "./slices/post_details";
import posts from "./postReducer";
import { search_Reducer } from "./slices/search";
import { profile_Reducer } from "./slices/profile";
import { payment_Reducer } from "./slices/payment";
import { Othersprofile_Reducer } from "./slices/OtherProfile";
export default combineReducers({
	auth: auth_Reducer,
	profile: profile_Reducer,
	othersprofile: Othersprofile_Reducer,
	posts: posts,
	payment: payment_Reducer,
});
