import React from "react";
import "./chatWindow.css";
import { FaImage } from "react-icons/fa6";
import { FaMicrophone, FaVideo } from "react-icons/fa";
import { BsCheckAll, BsCheck } from "react-icons/bs";
import { ImSpinner } from "react-icons/im";

const ChatWindow = ({
  avatar,
  userName,
  message,
  timeStamp,
  count,
  type,
  media,
  messageStatus,
  onClick
}) => {
  const classNames = `base-style ${type === "message" ? "message-style" : ""} ${
    type === "message" && media ? "message-media-style" : ""
  } ${type === "notification" ? "notification-style" : ""}`;

  const countClassName = count ? "chat-window__right-message-count" : "";

  let mediaIcon;
  if (media === "image") {
    mediaIcon = <FaImage color="var(--cerulean-blue)" />;
  } else if (media === "voiceNote") {
    mediaIcon = <FaMicrophone color="var(--cerulean-blue)" />;
  } else if (media === "video") {
    mediaIcon = <FaVideo color="var(--cerulean-blue)" />;
  } else {
    mediaIcon = null;
  }

  let messageIcon;
  if (messageStatus === "sent") {
    messageIcon = <BsCheck />;
  } else if (messageStatus === "delivered") {
    messageIcon = <BsCheckAll />;
  } else if (messageStatus === "read") {
    messageIcon = <BsCheckAll color="var(--green)" className="read-size"/>;
  } else if (messageStatus === "sending") {
    messageIcon = <ImSpinner className="spin" />;
  } else {
    messageIcon = null;
  }
  return (
    <div className="chat-window" onClick={onClick}>
      <div
        className="chat-window__avatar"
        style={{ backgroundImage: `url(${avatar})` }}
      ></div>
      <div className="chat-window__details">
        <div className="chat-window__left">
          <h4 className="chat-window__left-name">{userName}</h4>
          <p className={`${classNames} fuck`}>
            {messageIcon}
            {mediaIcon}
            {message}
          </p>
        </div>
        <div className="chat-window__right">
          <div className="chat-window__right-time">{timeStamp}</div>
          <div className={countClassName}>{count}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
