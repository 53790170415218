// src/components/Pagination/Pagination.jsx
import React, { useState, useEffect } from "react";
import "./pagination.css";

const Pagination = ({ loadMorePosts, parentId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasMorePosts, setHasMorePosts] = useState(true); // Assume more posts are available

	const handleScroll = () => {
		if (
			window.innerHeight + document.documentElement.scrollTop - 1 >=
			document.documentElement.offsetHeight
		) {
			if (!isLoading && hasMorePosts) {
				setIsLoading(true);
				loadMorePosts();
				// setTimeout(() => {
				// 	if (parentId && document.getElementById(parentId)) {
				// 		document
				// 			.getElementById(parentId)
				// 			.scroll({ top: 0, behavior: "smooth" });
				// 	}
				// 	setIsLoading(false);
				// }, 2000); // 2-second delay before loading more posts
			}
		}
	};

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => window.removeEventListener("scroll", handleScroll);
	// }, [isLoading, hasMorePosts]);

	const handleViewMoreClick = () => {
		if (!isLoading && hasMorePosts) {
			setIsLoading(true);
			loadMorePosts();
			setTimeout(() => {
				if (parentId !== null && document.getElementById(parentId)) {
					document
						.getElementById(parentId)
						.scroll({ top: 0, behavior: "smooth" });
				}
				setIsLoading(false);
			}, 1000);
		}
	};

	return (
		<div className="pagination">
			{hasMorePosts && !isLoading && (
				<button
					className="view-more-btn"
					onClick={handleViewMoreClick}
				>
					<span>view more</span>
					<span className="arrow-down">↓</span>
				</button>
			)}
			{isLoading && (
				<div className="loading-container">
					<span className="loading-text">Loading More Posts...</span>
				</div>
			)}
		</div>
	);
};

export default Pagination;

/* import React, { useState, useEffect } from 'react';
import './pagination.css';

const Pagination = ({ loadMorePosts }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10) {
      if (!isLoading) {
        setIsLoading(true);
        setTimeout(() => {
          loadMorePosts();
          setIsLoading(false);
        }, 2000); // 2-second delay before loading more posts
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  return (
    <div className="pagination">
      {isLoading && (
        <div className="loading-container">
          <span className="loading-text">Loading More Posts...</span>
        </div>
      )}
    </div>
  );
};

export default Pagination;
 */
