import React from "react";
import "./sidebarUser.css";
import { MdVerified } from "react-icons/md";
import { useSelector } from "react-redux";

const SidebarUser = () => {
	const auth = useSelector((state) => {
		return state.entities.auth;
	});
	return (
		<div className="sidebar-user">
			<div className="sidebar-user__avatar">
				<img
					src={auth.picture}
					alt={auth.name}
				/>
			</div>
			<div className="sidebar-user__names">
				<div className="sidebar-user__names_name">
					<p>{auth.styledUsername}</p>
					{auth.verifyStatus?.verified && <MdVerified className="badge" />}
				</div>
				<p className="sidebar-user__names_full-name">
					{auth.name?.slice(0, 14) || "new user"}
				</p>
			</div>
		</div>
	);
};

export default SidebarUser;
