import React, { useEffect, useRef, useState } from "react";
import {
	load,
	react,
	save,
	delete_post,
	toggleFavouriteStatus,
} from "../../store/slices/discover";
import { useDispatch, useSelector } from "react-redux";
import PostComponent from "../post/post";
import Pagination from "../pagination/Pagination";
import { Loader } from "../../utils/loader/loader";
import AdvertWidget from "../Widgets/Advert Widget/AdvertWidget";
import logo from "../../assets/logo2.jpg";
import "./postfeed.css";

const PostFeed = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [isScrollingDown, setIsScrollingDown] = useState(false);
	const discover = useSelector((state) => state.entities.posts.discover);
	const user = useSelector((state) => state.entities.auth);
	const containerRef = useRef(null);

	useEffect(() => {
		// Initial load of posts
		dispatch(load(1));
	}, [dispatch]);

	useEffect(() => {
		const container = containerRef.current;
		let lastScrollTop = 0;

		const handleScroll = () => {
			const scrollTop = container.scrollTop;

			// Check if scrolling down or back to the top
			if (scrollTop > lastScrollTop) {
				setIsScrollingDown(true); // Hide logo on scroll down
			} else if (scrollTop === 0) {
				setIsScrollingDown(false); // Show logo when scrolled to the top
			}

			lastScrollTop = scrollTop;
		};

		// Attach scroll event to the container
		if (container) container.addEventListener("scroll", handleScroll);

		// Clean up event listener on component unmount
		return () => {
			if (container) container.removeEventListener("scroll", handleScroll);
		};
	}, [discover.loading]);

	const loadMorePosts = () => {
		dispatch(load(currentPage + 1));
		setTimeout(() => {
			setCurrentPage((prev) => prev + 1);
		}, 2000);
	};

	return (
		<>
			<div className={`logo-container ${isScrollingDown ? "slide-up " : ""}`}>
				<div className="sidebar-user__avatar__explore">
					<img
						src={user.picture}
						alt=""
					/>
				</div>
				<div className="logo-discover">
					<img
						src={logo}
						alt="Discover Logo"
					/>
				</div>
			</div>

			{discover.loading ? (
				<p className="No-post">
					{Loader("syncloader", discover.loading, undefined, 10, "gray")}
				</p>
			) : discover.posts.length > 0 ? (
				<div
					className={`post-feed__container content-spreader ${
						isScrollingDown ? " " : " addMarginTop"
					}`}
				>
					<PostComponent
						contRef={containerRef}
						dispatch={dispatch}
						posts={discover.posts}
						react={react}
						save={save}
						user={user}
						star={toggleFavouriteStatus}
						deletePost={delete_post}
						loadMorePosts={loadMorePosts}
						parentId="postFeedScroll"
					/>
				</div>
			) : (
				<p className="No-post suggestion-block">No Posts</p>
			)}
		</>
	);
};

export default PostFeed;
