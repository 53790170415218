import React, { useState, useEffect } from "react";
import { FaBullhorn, FaCcAmex, FaCcMastercard, FaCcVisa, FaCreditCard, FaUber } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import "./boostAccount.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PayForBoostAccount } from "../../store/slices/payment";
import { RiMastercardFill, RiMastercardLine, RiVisaLine } from "react-icons/ri";

const BoostAccount = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const navigate = useNavigate();
  const payment = useSelector((state) => state.entities.payment);
  const dispatch = useDispatch();

  const handleAutoRenew = () => {
    setSelectedOption("Auto-Renew");
    setConfirmationMessage("Redirecting to Auto-Renew payment page...");
    dispatch(PayForBoostAccount({ isOneTime: false }));
  };

  const handleOneTimePurchase = () => {
    setSelectedOption("One-Time Purchase");
    setConfirmationMessage("Redirecting to One-Time Purchase payment page...");
    dispatch(PayForBoostAccount({ isOneTime: true }));
  };

  useEffect(() => {
    if (payment.redirect.boostAccount) {
      setTimeout(() => {
        window.location.href = payment.paymentInfo.authorization_url;
      }, 2000);
    }
    return () => {};
  }, [dispatch, payment.redirect]);

  return (
    <div className="boost-account-container">
      <h1 className="boost-account-title">Boost Account</h1>
      <div className="boost-account-content">
        <div className="shout-container">
          <p className="boost-account-highlight">Let your account speak volumes about you!</p>
          <FaBullhorn className="shout-icon" />
        </div>
  
        <div className="boost-account-price">
          <h2>Boost Account: ₦80,000</h2>
          <p>Other currencies are also supported</p>
        </div>

        <div className="payment-methods">
          <h3>We accept:</h3>
          <div className="card-icons">
            <RiVisaLine className="card-icon visa" />
            <FaCcMastercard className="card-icon mastercard" />
            <FaCcAmex className="card-icon amex" />
          </div>
        </div>

        <p className="boost-duration">Account Boosting is for 30 days.</p>
        <p>Please use our self-service button below to promote your account.</p>

        <div className="payment-options">
          <button onClick={handleAutoRenew} className="payment-option">
            <BsCheckCircleFill className="check-icon" />
            Auto-Renew
          </button>
          <button onClick={handleOneTimePurchase} className="payment-option">
            <BsCheckCircleFill className="check-icon" />
            One-Time Purchase
          </button>
        </div>

        {selectedOption && (
          <p className="selected-option">You selected: {selectedOption}</p>
        )}
        {confirmationMessage && (
          <p className="confirmation-message">{confirmationMessage}</p>
        )}

        <div className="support-ukraine">
          <FaUber className="ukraine-icon" />
          <p>Support Ukraine in time of need</p>
        </div>
      </div>
    </div>
  );
};

export default BoostAccount;