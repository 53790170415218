import React, { useEffect } from "react";
import "./trendingPost.css";
import TrendingCard from "./TrendingCard";
import { useDispatch, useSelector } from "react-redux";
import { load } from "../../../store/slices/trending";
import moment from "moment";
import { Loader } from "../../../utils/loader/loader";
const TrendingPost = () => {
	const backgroundImageURL1 = "../../../assets/halland.jpg";
	const dispatch = useDispatch();
	const trendingPosts = useSelector((state) => state.entities.posts.trending);
	useEffect(() => {
		dispatch(load());
		return () => {};
	}, [dispatch]);

	return (
		<div className="trending-post">
			{trendingPosts.loading ? (
				<p className="No-post">
					{Loader("syncloader", trendingPosts.loading, undefined, 10, "gray")}
				</p>
			) : trendingPosts.posts.length > 0 ? (
				trendingPosts.posts.map((post, index) => {
					return (
						<div className="trending_white-bg">
							<TrendingCard
								number={index + 1}
								backgroundImage={post.media[0] || backgroundImageURL1}
								category={post.catalog}
								time={moment(post.date).fromNow()}
								post={post.message.slice(0, 50)}
								postId={post._id}
							/>
						</div>
					);
				})
			) : (
				<p className="No-post">No Post Available</p>
			)}
		</div>
	);
};

export default TrendingPost;
