import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOtherProfile } from "../../store/slices/auth";
import { decodedAuthToken } from "../../store/helpers/helper";
import { countries } from "../Profile/countries";
import { FaCamera } from "react-icons/fa";
import "./updateProfile.css";
import { useNavigate } from "react-router-dom";

const UpdateProfile = () => {
	const dispatch = useDispatch();
	const [profileData, setProfileData] = useState({
		name: "",
		username: "",
		email: "",
		coverPicture: null,
		picture: null,
		country: "",
		occupation: "",
		dateOfBirth: "",
		phoneX: "",
		gender: "",
		showAgeClock: false, // Add this line
	});
	const navigate = useNavigate();
	const [coverPicturePreview, setCoverPicturePreview] = useState(null);
	const [picturePreview, setPicturePreview] = useState(null);
	const auth = useSelector((state) => state.entities.auth);

	const handleChange = (e) => {
		const { name, value, files, type, checked } = e.target;
		if (files) {
			const file = files[0];
			setProfileData({ ...profileData, [name]: file });
			const reader = new FileReader();
			reader.onloadend = () => {
				if (name === "coverPicture") {
					setCoverPicturePreview(reader.result);
				} else if (name === "picture") {
					setPicturePreview(reader.result);
				}
			};
			reader.readAsDataURL(file);
		} else if (type === "checkbox") {
			setProfileData({ ...profileData, [name]: checked });
		} else {
			setProfileData({ ...profileData, [name]: value });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(profileData);
		dispatch(
			updateOtherProfile({ profileData, username: decodedAuthToken().username })
		);
	};

	const handleCancel = () => {
		if (window.confirm("Are you sure you want to cancel?")) {
			setProfileData({
				name: "",
				username: "",
				email: "",
				coverPicture: null,
				picture: null,
				country: "",
				occupation: "",
				dateOfBirth: "",
				phoneX: "",
				gender: "",
				showAgeClock: false, 
			});
			setCoverPicturePreview(null);
			setPicturePreview(null);
			navigate(-1);
		}
	};

	return (
		<div className="update-profile-container">
			<div className="update-profile-form">
				<h2 className="form-title">Update Profile</h2>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<label htmlFor="name">Full Name</label>
						<input
							type="text"
							name="name"
							id="name"
							value={profileData.name}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="username">Username</label>
						<input
							type="text"
							name="username"
							id="username"
							value={profileData.username}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							name="email"
							id="email"
							value={profileData.email}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label>Background Cover</label>
						<div className="image-upload">
							<div className="image-preview">
								{coverPicturePreview ? (
									<img
										src={coverPicturePreview}
										alt="Cover Preview"
									/>
								) : (
									<FaCamera className="camera-icon" />
								)}
							</div>
							<label
								htmlFor="coverPicture"
								className="file-input-label"
							>
								Change
							</label>
							<input
								type="file"
								name="coverPicture"
								id="coverPicture"
								onChange={handleChange}
								className="file-input"
							/>
						</div>
					</div>
					<div className="form-group">
						<label>Profile Photo</label>
						<div className="image-upload">
							<div className="image-preview">
								{picturePreview ? (
									<img
										src={picturePreview}
										alt="Profile Preview"
									/>
								) : (
									<FaCamera className="camera-icon" />
								)}
							</div>
							<label
								htmlFor="picture"
								className="file-input-label"
							>
								Change
							</label>
							<input
								type="file"
								name="picture"
								id="picture"
								onChange={handleChange}
								className="file-input"
							/>
						</div>
					</div>
					<div className="form-group">
						<label htmlFor="country">Country</label>
						<select
							name="country"
							id="country"
							value={profileData.country}
							onChange={handleChange}
						>
							<option
								value=""
								disabled
							>
								Select Your Country
							</option>
							{countries.map((country, index) => (
								<option
									key={index}
									value={country.name}
								>
									{country.name}
								</option>
							))}
						</select>
					</div>
					<div className="form-group">
						<label htmlFor="dateOfBirth">Date of Birth</label>
						<input
							type="date"
							name="dateOfBirth"
							id="dateOfBirth"
							value={profileData.dateOfBirth}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="occupation">Occupation</label>
						<input
							type="text"
							name="occupation"
							id="occupation"
							value={profileData.occupation}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="phoneX">Phone Number</label>
						<input
							type="text"
							name="phoneX"
							id="phoneX"
							placeholder="E.g +380..."
							value={profileData.phoneX}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="gender">Gender</label>
						<select
							name="gender"
							id="gender"
							value={profileData.gender}
							onChange={handleChange}
						>
							<option value="">Select your gender</option>
							<option value="male">Male</option>
							<option value="female">Female</option>
							<option value="custom">Custom</option>
						</select>
					</div>
					<div className="form-group checkbox-group">
						<label
							htmlFor="showAgeClock"
							className="checkbox-label"
						>
							<input
								type="checkbox"
								name="showAgeClock"
								id="showAgeClock"
								checked={profileData.showAgeClock}
								onChange={handleChange}
							/>
							Show Age Clock
						</label>
					</div>

					<div className="form-actions">
						<button
							type="button"
							onClick={handleCancel}
							className="cancel-btn"
						>
							Cancel
						</button>
						<button
							type="submit"
							className="submit-btn"
						>
							Update
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateProfile;
