import { jwtDecode } from "jwt-decode";

// ensures token is not expired
export const isAuthTokenExpired = () => {
	let token = localStorage.getItem("Authorization");
	if (!token) {
		return true;
	}
	const decodedToken = jwtDecode(token);
	const currentTime = Date.now() / 1000; // Current time in seconds

	return decodedToken.exp < currentTime;
};

// to set authToken
export const setAuthToken = (token) => {
	return localStorage.setItem("Authorization", token);
};
// to set authToken
export const getAuthToken = () => localStorage.getItem("Authorization");

// get decodedToken values
export const decodedAuthToken = () => {
	const token = localStorage.getItem("Authorization");
	return jwtDecode(token);
};

// remove auth token
export const removeAuthToken = () => localStorage.removeItem("Authorization");
