import toast from "react-hot-toast";
import { io } from "socket.io-client";
// https://me-be.onrender.com/${route},
//"http://185.224.139.225/"
//https://snapme-ng.com/

const Socket = (token, route) => {
	console.log(token, "token");
	try {
		const socketInst = io(`https://snapme-ng.com/${route}`, {
			transports: ["websocket"],
			query: { authorization: token },
		});

		return socketInst;
	} catch (error) {
		toast.error("Socket connection failed:", error.message);
		console.log("Error: Socket connection failed");
	}
};

export default Socket;
 