import React, { useEffect } from "react";
import "./userLiveNow.css";
import userlive from "../../assets/userlive-rect.png";
import eye from "../../assets/eye.svg";
import call from "../../assets/Call.png";

const UserLiveNow = ({ joinLive, LeaveLiveStream, liveInfo, channel }) => {
	useEffect(() => {
		return () => {
			LeaveLiveStream(channel);
		};
	}, []);

	return (
		<div className="userlive ">
			<div className="userlive__rectangle">
				{joinLive ? (
					<video
						src=""
						id="vid1"
					></video>
				) : (
					<img
						src={userlive}
						alt="live rect"
					/>
				)}
				{joinLive === false && (
					<div className="userlive-view">
						<p>32K</p>
						<img
							src={eye}
							alt="userlive-view"
						/>
					</div>
				)}
				{joinLive && (
					<div>
						<img
							src={call}
							alt="call"
							className="call"
							onClick={() => {
								LeaveLiveStream(channel);
							}}
						/>
					</div>
				)}
			</div>
			<h2>{liveInfo.title}</h2>
			<p>{liveInfo.description}</p>
		</div>
	);
};

export default UserLiveNow;
