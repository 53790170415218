import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Sidebar from '../../components/Sidebar/Sidebar';
import Widgets from '../../components/Widgets/Widgets';
import './privacyPolicy.css'; 

const PrivacyPolicy = () => {
  return (
    <div className="privacy-page-container">
      <div className="side_bar">
        <Sidebar />
      </div>

      <div className="privacy-content">
        <div className="text-container">
          <h2 className="privacy-title">Privacy Policy</h2>
          <ul className="privacy-list">
            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Introduction</h3>
                <p>
                  This privacy policy ("Policy") describes how we collect, protect, and use the personally identifiable information ("Personal Information") you ("User", "you" or "your") provide on the web application and any of its products or services (collectively, "Application"). It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Collection of Personal Information</h3>
                <p>
                  When you sign in using social login methods such as Facebook, Google, Twitter, and Instagram, the Application collects certain Personal Information from you, including your name, username, email address, and profile picture.
                </p>
                <p>
                  We receive and store any information you knowingly provide to us when you fill any online forms in the Application. You can choose not to provide us with certain information, but then you may not be able to take advantage of some of the Application's features.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Use of Collected Information</h3>
                <p>
                  Any of the information we collect from you may be used to personalize your experience, improve our Application, improve customer service, process transactions, send periodic emails, or run and operate our Application.
                </p>
                <p>
                  Personal Information collected via our Application will be stored on secure servers, and we will take all reasonable measures to protect your Personal Information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Sharing Personal Information</h3>
                <p>
                  We do not sell, trade, or otherwise transfer to outside parties your Personal Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our Application, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
                </p>
                <p>
                  We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Retention of Personal Information</h3>
                <p>
                  We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Protection of Information</h3>
                <p>
                  We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody.
                </p>
                <p>
                  However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and our Application cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Privacy of Children</h3>
                <p>
                  We do not knowingly collect any Personal Information from children under the age of 13. If you are under the age of 13, please do not submit any Personal Information through our Application. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through our Application without their permission.
                </p>
                <p>
                  If you have reason to believe that a child under the age of 13 has provided Personal Information to us through our Application, please contact us, and we will use commercially reasonable efforts to delete that information from our records.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Changes and Amendments</h3>
                <p>
                  We reserve the right to modify this Policy relating to the Application or services at any time, effective upon posting of an updated version of this Policy in the Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Application after any such changes shall constitute your consent to such changes.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Acceptance of This Policy</h3>
                <p>
                  You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Application or its services, you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Application and its services.
                </p>
              </div>
            </li>

            <li className="privacy-item">
              <FaArrowRight className="privacy-icon" />
              <div>
                <h3 className="privacy-subtitle">Contacting Us</h3>
                <p>
                  If you have any questions about this Policy, please contact us: <br />
                 <a href="mailto:support@me-snapme.com" className="text-blue-500 hover:underline">support@me-snapme.com</a>
                 </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="wid_get">
        <Widgets />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
