import React, { useEffect, useState } from "react";
import "./profile.css";
import { MdVerified } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoProfilePost from "./ProfilePost/NoProfilePost";
import defaults from "../../defaults";
import { decodedAuthToken } from "../../store/helpers/helper";
import PostComponent from "../../components/post/post";
import {
	loadAudioPosts,
	loadImagePosts,
	LoadMyPosts,
	loadStarredPosts,
	loadTextPosts,
	loadVideoPosts,
	react,
	save,
	delete_post,
	toggleFavouriteStatus,
} from "../../store/slices/profile";
import Pagination from "../../components/pagination/Pagination";
import { Loader } from "../../utils/loader/loader";

const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const profile_default = defaults.userProfile;
	const auth = useSelector((state) => state.entities.auth);
	const myPosts = useSelector((state) => state.entities.profile);
	const [activeTab, setActiveTab] = useState("allPosts");
	const [boosted, setBoosted] = useState(true);
	const [ageClock, setAgeClock] = useState("");
	const [showAgeClock, setShowAgeClock] = useState(false); // New state to toggle age clock visibility
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [toggleAgeClock, setToggleAgeClock] = useState(false);

	useEffect(() => {
		dispatch(LoadMyPosts({ pageNo: 1, userId: decodedAuthToken().id }));
	}, [dispatch, decodedAuthToken()._id]);
	useEffect(() => {
		let ageClockInterval;
		if (auth.dateOfBirth && toggleAgeClock && auth.showAgeClock) {
			ageClockInterval = setInterval(() => {
				calculateUserData(auth.dateOfBirth);
			}, 1000);
		} else {
			clearInterval(ageClockInterval);
		}

		return () => {
			if (auth.dateOfBirth && toggleAgeClock) {
				clearInterval(ageClockInterval);
			}
		};
	}, [dispatch, toggleAgeClock]);

	const handleSubscribeClick = () => {
		window.location.href = "/subscribe";
	};

	const handleBoostAccountClick = () => {
		setBoosted(true);
		window.location.href = "/boostaccount";
	};

	// Function to toggle the display of age clock features
	function calculateUserData(birthdate) {
		birthdate = new Date(birthdate);
		const now = new Date();
		const currentYear = now.getFullYear();
		const birthYear = birthdate.getFullYear();
		// const birthYear = birthdate.getFullYear();
		const nextBirthday = new Date(birthdate);
		nextBirthday.setFullYear(currentYear);

		if (now >= nextBirthday) {
			nextBirthday.setFullYear(currentYear + 1);
		}

		const lastBirthday = new Date(
			currentYear,
			birthdate.getMonth(),
			birthdate.getDate()
		);
		if (now < lastBirthday) lastBirthday.setFullYear(currentYear - 1);

		const age = currentYear - birthYear - (now < nextBirthday ? 1 : 0);
		const daysAccumulated = Math.floor(
			(now - lastBirthday) / (1000 * 60 * 60 * 24)
		);
		const currentHour = now.getUTCHours();
		const currentMinute = now.getUTCMinutes();
		const currentSecond = now.getUTCSeconds();

		document.getElementById("my-age").textContent = age;
		document.getElementById("daysAccumulated").textContent = daysAccumulated;
		document.getElementById("hours").textContent = String(currentHour).padStart(
			2,
			"0"
		);
		document.getElementById("minutes").textContent = String(
			currentMinute
		).padStart(2, "0");
		document.getElementById("seconds").textContent = String(
			currentSecond
		).padStart(2, "0");
	}
	const specifucPosts = (pageNo, type) => {
		const data = { pageNo: pageNo || 1, userId: decodedAuthToken().id };

		if (type === "allPosts") {
			dispatch(LoadMyPosts(data));
		} else if (type === "imagePosts") {
			dispatch(loadImagePosts(data));
		} else if (type === "videoPosts") {
			dispatch(loadVideoPosts(data));
		} else if (type === "audioPosts") {
			dispatch(loadAudioPosts(data));
		} else if (type === "textPosts") {
			dispatch(loadTextPosts(data));
		} else if (type === "starredPosts") {
			dispatch(loadStarredPosts(data));
		}
	};

	const loadMorePosts = () => {
		setLoading(true);
		specifucPosts(currentPage + 1, activeTab);
		setTimeout(() => {
			setCurrentPage((prev) => prev + 1);
		}, 2000);
	};

	return (
		<div
			className="profile"
			id="postScroll"
		>
			<div className="profile-header-profile">
				<div className="profile-header__banner">
					<img
						src={auth.coverPicture || profile_default.coverPhoto}
						alt=""
					/>
				</div>
				<div className="profile-header__avatar">
					<img
						src={auth.picture || profile_default.profileImage}
						alt="profile image"
					/>
				</div>
				<div className="profile-header__space"></div>
			</div>
			<div className="profile-details">
				<div className="profile-details__profile-name">
					<div className="profile-details__profile-name_name">
						<h4>{auth.styledUsername || profile_default.username}</h4>
						{auth.verifyStatus?.verified && (
							<MdVerified
								className="verified"
								color="var(--orange)"
								size={12}
							/>
						)}
					</div>
					<h1>{auth.name || profile_default.username}</h1>
				</div>
				<div className="age-clock__wrapper">
					<details>
						<summary
							onClick={() => {
								setToggleAgeClock((v) => !v);
							}}
						>
							Age clock
						</summary>
						{auth.showAgeClock ? (
							<div className="age-clock">
								<span id="my-age">0</span>:
								<span class="daysAccumulated">
									<span id="daysAccumulated">0</span>
								</span>
								:<span id="hours">00</span>:<span id="minutes">00</span>:
								<span id="seconds">00</span>
							</div>
						) : (
							<div className="locked_ageclock">Locked</div>
						)}
					</details>
				</div>
				<div className="profile-details__bio">
					<p>{auth.occupation}</p>
				</div>
				<div className="profile-info__actions-top">
					{!auth.verifyStatus?.verified && (
						<button onClick={handleSubscribeClick}>Subscribe</button>
					)}
					<button
						onClick={() => (auth.isBoosted ? "" : handleBoostAccountClick())}
					>
						{auth.isBoosted ? "Boosted" : "Boost Account"}
					</button>
				</div>
			</div>
			<div className="profile-info">
				<div className="profile-info__data">
					<div className="profile-info__data-posts">
						<h3>{myPosts.allPosts?.totalPosts || 0}</h3>
						<p>Posts</p>
					</div>
					<div className="profile-info__data-follow">
						<h3>{auth.followers?.members?.length || 0}</h3>
						<p>Followers</p>
					</div>
					<div className="profile-info__data-follows">
						<h3>{auth.following?.members?.length || 0}</h3>
						<p>Following</p>
					</div>
				</div>
				<div className="profile-info__tabs">
					<div className="tabs">
						<button
							className={activeTab === "allPosts" ? "active" : ""}
							onClick={() => {
								setActiveTab("allPosts");
								specifucPosts(0, "allPosts");
							}}
						>
							Posts
						</button>
						<button
							className={activeTab === "textPosts" ? "active" : ""}
							onClick={() => {
								setActiveTab("textPosts");
								specifucPosts(1, "textPosts");
							}}
						>
							Texts
						</button>
						<button
							className={activeTab === "imagePosts" ? "active" : ""}
							onClick={() => {
								setActiveTab("imagePosts");
								specifucPosts(1, "imagePosts");
							}}
						>
							Images
						</button>
						<button
							className={activeTab === "videoPosts" ? "active" : ""}
							onClick={() => {
								setActiveTab("videoPosts");
								specifucPosts(1, "videoPosts");
							}}
						>
							Videos
						</button>
						<button
							className={activeTab === "audioPosts" ? "active" : ""}
							onClick={() => {
								setActiveTab("audioPosts");
								specifucPosts(1, "audioPosts");
							}}
						>
							Audios
						</button>
						<button
							className={activeTab === "starredPosts" ? "active" : ""}
							onClick={() => {
								setActiveTab("starredPosts");
								specifucPosts(1, "starredPosts");
							}}
						>
							Favorites
						</button>
					</div>
				</div>
			</div>
			{myPosts[activeTab].loading ? (
				<p className="No-post">
					{Loader(
						"syncloader",
						myPosts[activeTab].loading,
						undefined,
						10,
						"gray"
					)}
				</p>
			) : myPosts[activeTab].posts?.length > 0 ? (
				<>
					<PostComponent
						posts={myPosts[activeTab].posts}
						removeScroll
						react={react}
						save={save}
						dispatch={dispatch}
						deletePost={delete_post}
						star={toggleFavouriteStatus}
            parentId="postScroll"
						loadMorePosts={loadMorePosts}
					/>
				</>
			) : (
				<NoProfilePost />
			)}
		</div>
	);
};

export default Profile;
