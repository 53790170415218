import React from "react";
import "./trendingCard.css";
import { Link } from "react-router-dom";

const TrendingCard = ({
	backgroundImage,
	number,
	category,
	time,
	post,
	postId,
}) => {
	const styles = {
		background: `url(${backgroundImage})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	};
	return (
		<div className="trending-card">
			<Link to={`../post/${postId}`}>
				<div className="trending-card__head">
					<div className="trending-card__head-id">{number}</div>
					<div className="trending-card__head-flex">
						<div className="trending-card__head-flex_type">{category}</div>
						<div className="trending-card__head-flex_time">{time}</div>
					</div>
				</div>
				<div className="trending-card__context">
					<p>{post}</p>
					<div
						className="trending-card__context-banner"
						style={styles}
					></div>
				</div>
			</Link>
		</div>
	);
};

export default TrendingCard;
