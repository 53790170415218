import React, { useState } from 'react';
import './deleteAccount.css';

const DeleteAccount = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    // Handle account deletion logic here
    console.log(`Deleting account for ${username}`);
    setIsModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="delete-account-page">
      <h2>Delete Account</h2>
      
      <div className="input-container">
        <label>
          Username:
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
        </label>

        <label>
          Password:
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </label>

      </div>
      
      <button type="button" onClick={handleDeleteClick}>Delete Account</button>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to delete your account?</p>
            <button type="button" onClick={handleConfirmDelete}>Yes</button>
            <button type="button" onClick={handleCancelDelete}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;
