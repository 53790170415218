import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

// state manager for search bar

const subUrl = "/search";

const search_slice = createSlice({
	name: "search",
	initialState: {
		posts: [],
		users: [],
		loading: false,
		pageNum: 0,
	},
	reducers: {
		initiateSearch: (posts, actions) => {
			posts.loading = true;
		},
		searchSuccessful: (post, actions) => {
			const { posts, pageNum, users } = actions.payload;
			post.posts = posts;
			post.users = users;
			post.pageNum = pageNum;
			post.loading = false;
		},
		searchFailed: (posts, actions) => {
			posts.loading = false;
		},
		clearSearch: (post, actions) => {
			post.posts = [];
			post.users = [];
			post.loading = false;
		},
	},
});
export const { clearSearch, searchFailed, initiateSearch, searchSuccessful } =
	search_slice.actions;
export const search_Reducer = search_slice.reducer;

export const SearchItem = (name) => (dispatch, setState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}?query=${name}`,
			method: "post",
			onerror: searchFailed.type,
			onStart: initiateSearch.type,
			onSuccess: searchSuccessful.type,
		})
	);
};
export const resetSearch = () => (dispatch, setState) => {};
