import profile_image from "./assets/asian-lady.jpg";
import cover_picture from "./assets/profile-banner.jpg";
export default {
	userProfile: {
		profileImage: profile_image,
		coverPhoto: cover_picture,
		username: "User 🤔",
	},
};

