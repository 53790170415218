import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

const AccountBoostSuccessful = () => {
  const navigate = useNavigate()
	return (
		<div className="promoted-message">
			<div className="promoted-message__content">
				<h2 className="promoted-message__title">Congratulations!</h2>
				<p className="promoted-message__text">
					You have successfully <b>Boosted</b> your Account.
				</p>
			</div>
			<button className="promoted-message__close-btn" onClick={()=> navigate("/profile")}>x</button>
		</div>
	);
};

export default AccountBoostSuccessful;
