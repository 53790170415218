import React from "react";
import "./userWidgets.css";
import { MdVerified } from "react-icons/md";
import { user_Helper } from "../../../store/helpers/user";
import { followOrUnfollow } from "../../../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { decodedAuthToken } from "../../../store/helpers/helper";
import { Link } from "react-router-dom";

const UserWidgets = ({
	userImage,
	userName,
	isVerified,
	notification,
	country,
	userId,
	isBoosted,
}) => {
	const user = useSelector((state) => state.entities.auth);
	const dispatch = useDispatch();
	return (
		<div className="user-widget">
			<div className="user-widget__user">
				<div className="user-widget__user-image">
					<img
						src={userImage}
						alt={`${userName}'s pic`}
						className={`${isBoosted ? "orange-border" : ""} `}
					/>
				</div>
				<div className="user-widget__user-name-status">
					<div className="user-widget__user-name-status_verify">
						<h4>
							{country ? (
								<Link to={`/user?username=${userName}`}>
									{user_Helper.username(userName, "name", country, "code")}
								</Link>
							) : (
								userName
							)}
						</h4>
						{isVerified && (
							<div className="verification-icon">
								<MdVerified />
							</div>
						)}
					</div>
					<p>{notification}</p>
				</div>
			</div>
			<div className="user-widget__follow">
				<button
					className={`${isBoosted ? "orange-button" : ""} `}
					onClick={(e) => {
						e.stopPropagation();
						dispatch(followOrUnfollow(userName));
					}}
				>
					{user.following.members.includes(userId)
						? "Unfollow"
						: decodedAuthToken().id === userId
						? ""
						: "Follow"}
				</button>
			</div>
		</div>
	);
};

export default UserWidgets;
