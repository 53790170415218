import React, { useState } from "react";
import "./resetPassword.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ResetPswd, Send_PasswordResetOtp } from "../../../store/slices/auth";
import toast from "react-hot-toast";

const ResetPassword = () => {
	const [usernameOrEmail, setUsernameOrEmail] = useState("");
	const [otp, setOtp] = useState(""); // Added OTP state
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.entities.auth);

	const handleGetOTP = () => {
		dispatch(Send_PasswordResetOtp(usernameOrEmail));
		// Handle OTP success
	};

	const handleResetPassword = async (event) => {
		event.preventDefault();
		if (password !== confirmPassword) {
			toast.error("Passwords do not match");
			return;
		}
		dispatch(ResetPswd({ password, otp })); // Included OTP in reset
		setPassword("");
		setConfirmPassword("");
		setOtp("");
	};

	return (
		<div className="reset__password">
			<h2>Reset Password</h2>
			<form
				className="reset__password__form"
				onSubmit={handleResetPassword}
			>
				<span className="input-group">
					<input
						type="text"
						className="username"
						placeholder="Enter Username or Email"
						value={usernameOrEmail}
						onChange={(e) => setUsernameOrEmail(e.target.value)}
					/>
					<button
						type="button"
						onClick={handleGetOTP}
						disabled={auth.pswdResetToken.loading}
					>
						Get OTP
					</button>
				</span>
				<input
					type="text"
					placeholder="Enter OTP here" // Added OTP input
					value={otp}
					onChange={(e) => setOtp(e.target.value)}
				/>
				<input
					type="password"
					placeholder="Enter Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<input
					type="password"
					placeholder="Confirm Password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
				<button
					className="nrml-btn"
					type="submit"
				>
					Save
				</button>
				<p className="reset-paragraph">
					Have an account?{" "}
					<span>
						<Link to="/login">Log In</Link>
					</span>
				</p>
			</form>
		</div>
	);
};

export default ResetPassword;
