import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { updateSaved } from "./auth";

const subUrl = "/pins";

// state manager for the trending page
const trending_slice = createSlice({
	name: "trending",
	initialState: {
		posts: [],
		loading: true,
		pageNum: 0,
		// sub_loading actions
		otherLoadingOptions: {
			comment: false,
			react: false,
			save: false,
		},
	},
	reducers: {
		// post detail loading handlers
		postsRequested: (posts, action) => {
			posts.loading = true;
		},
		postsRecieved: (posts, action) => {
			const { post, pageNum } = action.payload;
			posts.posts = post;
			posts.pageNum = pageNum;
			posts.loading = false;
		},
		postsRequestFailed: (posts, action) => {
			posts.loading = false;
		},

		// reaction loading handlers
		// for comments
		commentInitiated: (posts, action) => {
			posts.otherLoadingOptions.comment = true;
		},
		commentSuccessful: (posts, action) => {
			const { postId, comment } = action.payload;

			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			posts.posts[postIndex].comment.push(comment);
			posts.otherLoadingOptions.comment = false;
		},
		// new
		deletecomment: (posts, action) => {
			const { commentId, postId } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			posts.posts[postIndex].comment = posts.posts[postIndex].comment.filter(
				(a) => a._id.toString() !== commentId
			);
		},
		commentFailed: (posts, action) => {
			posts.otherLoadingOptions.comment = false;
		},
		// for saves
		saveInitiated: (posts, action) => {
			posts.otherLoadingOptions.save = true;
		},
		saveSuccessful: (posts, action) => {
			const { postId } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			if (postIndex !== -1) {
				posts.posts[postIndex].saves++;
			}
			posts.otherLoadingOptions.save = false;
		},
		saveFailed: (posts, action) => {
			posts.otherLoadingOptions.save = false;
		},
		// for reactions
		reactionInitiated: (posts, action) => {
			posts.otherLoadingOptions.react = true;
		},
		reactionSuccessful: (posts, action) => {
			const { postId, likes } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			if (postIndex !== -1) {
				posts.posts[postIndex].likes = likes;
			}
			console.log("postIndex", postIndex);
			console.log("post", posts.posts[postIndex].likes);

			posts.otherLoadingOptions.react = false;
		},
		reactionFailed: (posts, action) => {
			posts.otherLoadingOptions.react = false;
		},
		// new
		reactToComment: (posts, action) => {
			const { commentId, postId, userId } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);

			const commentIndex = posts.posts[postIndex].comment.findIndex(
				(a) => a._id.toString() !== commentId
			);

			if (posts.posts[postIndex].comment[commentIndex].likes.includes(userId)) {
				posts.posts[postIndex].comment[commentIndex].likes = posts.posts[
					postIndex
				].comment[commentIndex].likes.filter(
					(a) => a._id.toString() !== userId
				);
			} else {
				posts.posts[postIndex].comment[commentIndex].likes.push(userId);
			}
		},
		// new
		replyComment: (posts, action) => {
			const { commentId, postId, text, username, userImage } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			const commentIndex = posts.posts[postIndex].comment.findIndex(
				(a) => a._id.toString() === commentId
			);

			posts.posts[postIndex].comment[commentIndex].replies.push({
				text,
				username,
				userImage,
			});
		},
		deleteReply: (posts, action) => {
			const { commentId, postId, replyId } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			const commentIndex = posts.posts[postIndex].comment.findIndex(
				(a) => a._id.toString() === commentId
			);

			posts.posts[postIndex].comment[commentIndex].replies = posts.posts[
				postIndex
			].comment[commentIndex].replies.filter((a) => {
				return a.id !== replyId;
			});
		},

		// Authenticate before performing action
		deleteComment: (posts, action) => {
			const { commentId, postId } = action.payload;
			const postIndex = posts.posts.findIndex(
				(a) => a._id.toString() === postId
			);
			posts.posts[postIndex].comment = posts.posts[postIndex].comment.filter(
				(a) => {
					return a.id !== commentId;
				}
			);
		},

		// Protected actions
		// deletePost: (posts, action) => {
		// 	const { postId } = action.payload;
		// 	posts.posts = posts.posts.filter((a) => a._id.toString() !== postId);
		// },
	},
});

export const {
	commentFailed,
	commentInitiated,
	commentSuccessful,
	postsRecieved,
	postsRequestFailed,
	postsRequested,
	reactionFailed,
	reactionInitiated,
	reactionSuccessful,
	saveFailed,
	saveInitiated,
	saveSuccessful,
	deleteComment,
	deleteReply,
	reactToComment,
	replyComment,
} = trending_slice.actions;

export const trending_Reducer = trending_slice.reducer;
// Action creators
export const load =
	(pageNo = 1) =>
	(dispatch, getState) => {
		dispatch(
			apiCallBegan({
				url: `${subUrl}/trending`,
				method: "get",
				onStart: postsRequested.type,
				onSuccess: postsRecieved.type,
				onError: postsRequestFailed.type,
				pageNo: pageNo,
			})
		);
	};

export const comment = (commentInfo) => (dispatch, getState) => {
	const { username, userId, userImage } = getState().entities.profile;
	const spreadComment = { username, userImage, userId, ...commentInfo };

	dispatch(
		apiCallBegan({
			url: `${subUrl}/comment/:${commentInfo.postId}`,
			method: "post",
			onStart: commentInitiated.type,
			onSuccess: commentSuccessful.type,
			onError: commentFailed.type,
			spreadComment,
		})
	);
};

export const save = (postId) => (dispatch, getState) => {
	// const { userId } = getState().entities.profile;

	dispatch(
		apiCallBegan({
			url: `${subUrl}/save/${postId}`,
			method: "post",
			onStart: saveInitiated.type,
			onSuccess: saveSuccessful.type,
			onError: saveFailed.type,
		})
	);
};
export const react = (postId) => (dispatch, getState) => {
	dispatch(
		apiCallBegan({
			url: `${subUrl}/${postId}/like`,
			method: "put",
			onStart: reactionInitiated.type,
			onSuccess: reactionSuccessful.type,
			onError: reactionFailed.type,
		})
	);
};

export const delete_comment = (commentInfo) => (dispatch, getState) => {
	const { userId } = getState().entities.profile;
	const spreadCommentInfo = { ...commentInfo, userId };
	dispatch(
		apiCallBegan({
			url: `${subUrl}/:${commentInfo.postId}/delete/:${commentInfo.commentId}`,
			method: "delete",

			onSuccess: deleteComment.type,

			spreadCommentInfo,
		})
	);
};
export const react_to_comment = (commentInfo) => (dispatch, getState) => {
	const { userId } = getState().entities.profile;
	const spreadCommentInfo = { ...commentInfo, userId };
	dispatch(
		apiCallBegan({
			url: `${subUrl}/:${commentInfo.postId}/like/:${commentInfo.commentId}`,
			method: "put",
			onSuccess: reactToComment.type,
			spreadCommentInfo,
		})
	);
};
export const reply_to_comment = (ReplyInfo) => (dispatch, getState) => {
	// commentId, postId, text, username, userImage
	const { username, userImage } = getState().entities.profile;
	const spreadReplyInfo = { ...ReplyInfo, username, userImage };
	dispatch(
		apiCallBegan({
			url: `${subUrl}/:${ReplyInfo.postId}/comment/:${ReplyInfo.commentId}/reply`,
			method: "put",
			onSuccess: replyComment.type,
			spreadReplyInfo,
		})
	);
};
export const delete_reply = (ReplyInfo) => (dispatch, getState) => {
	// commentId, postId, replyId
	const { username, userImage } = getState().entities.profile;
	const spreadReplyInfo = { ...ReplyInfo, username, userImage };

	dispatch(
		apiCallBegan({
			url: `${subUrl}/:${ReplyInfo.postId}/:${ReplyInfo.commentId}/delete/:${ReplyInfo.replyId}`,
			method: "delete",
			onSuccess: replyComment.type,
			spreadReplyInfo,
		})
	);
};
