import React, { useEffect, useState } from "react";
import "./profile.css";
import { MdVerified } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoProfilePost from "./ProfilePost/NoProfilePost";
import defaults from "../../defaults";
import PostComponent from "./post/post";
import {
	loadAudioPosts,
	loadImagePosts,
	LoadMyMetaData,
	LoadMyPosts,
	loadStarredPosts,
	loadTextPosts,
	loadVideoPosts,
	react,
	save,
} from "../../store/slices/OtherProfile";
import Pagination from "../../components/pagination/Pagination";
import { decodedAuthToken } from "../../store/helpers/helper";
import { followOrUnfollow } from "../../store/slices/auth";
import { Loader } from "../../utils/loader/loader";

const OthersProfile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const profile_default = defaults.userProfile;
	const myPosts = useSelector((state) => state.entities.othersprofile);
	const [activeTab, setActiveTab] = useState("allPosts");
	const [boosted, setBoosted] = useState(true);
	const [ageClock, setAgeClock] = useState("");
	const [showAgeClock, setShowAgeClock] = useState(false); // New state to toggle age clock visibility
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [toggleAgeClock, setToggleAgeClock] = useState(false);

	const userData = useSelector((state) => state.entities.othersprofile);
	const user = useSelector((state) => state.entities.auth);
	const { search } = useLocation();
	const queryString = React.useMemo(
		() => new URLSearchParams(search),
		[search]
	);
	const username = queryString.get("username");

	useEffect(() => {
		if (!username) {
			navigate("/discover", { replace: true });
		} else if (decodedAuthToken().username === username) {
			navigate("/profile", { replace: true });
		}
		dispatch(LoadMyMetaData(username));
		dispatch(LoadMyPosts({ username, pageNo: 1 }));
	}, [dispatch]);
	useEffect(() => {
		let ageClockInterval;
		if (userData.dateOfBirth && toggleAgeClock && userData.showAgeClock) {
			ageClockInterval = setInterval(() => {
				calculateUserData(userData.dateOfBirth);
				console.log("counting");
			}, 1000);
		} else {
			clearInterval(ageClockInterval);
		}

		return () => {
			if (userData.dateOfBirth && toggleAgeClock) {
				clearInterval(ageClockInterval);
			}
		};
	}, [dispatch, toggleAgeClock]);

	const handleFollowClick = () => {
		// We will add logic for following a user here
	};

	// Function to toggle the display of age clock features
	function calculateUserData(birthdate) {
		birthdate = new Date(birthdate);
		const now = new Date();
		const currentYear = now.getFullYear();
		const birthYear = birthdate.getFullYear();
		const nextBirthday = new Date(birthdate);
		nextBirthday.setFullYear(currentYear);

		if (now >= nextBirthday) {
			nextBirthday.setFullYear(currentYear + 1);
		}

		const lastBirthday = new Date(
			currentYear,
			birthdate.getMonth(),
			birthdate.getDate()
		);
		if (now < lastBirthday) lastBirthday.setFullYear(currentYear - 1);
		const age = currentYear - birthYear - (now < nextBirthday ? 1 : 0);
		const daysAccumulated = Math.floor(
			(now - lastBirthday) / (1000 * 60 * 60 * 24)
		);

		const currentHour = now.getUTCHours();
		const currentMinute = now.getUTCMinutes();
		const currentSecond = now.getUTCSeconds();

		document.getElementById("your-age").textContent = age;
		document.getElementById("daysAccumulated").textContent = daysAccumulated;
		document.getElementById("hours").textContent = String(currentHour).padStart(
			2,
			"0"
		);
		document.getElementById("minutes").textContent = String(
			currentMinute
		).padStart(2, "0");
		document.getElementById("seconds").textContent = String(
			currentSecond
		).padStart(2, "0");
	}
	const specifucPosts = (pageNo, type) => {
		const data = { pageNo: pageNo || 1, userId: userData.userId };

		if (type === "allPosts") {
			dispatch(LoadMyPosts({ ...data, username: username }));
		} else if (type === "imagePosts") {
			dispatch(loadImagePosts(data));
		} else if (type === "videoPosts") {
			dispatch(loadVideoPosts(data));
		} else if (type === "audioPosts") {
			dispatch(loadAudioPosts(data));
		} else if (type === "textPosts") {
			dispatch(loadTextPosts(data));
		} else if (type === "starredPosts") {
			dispatch(loadStarredPosts(data));
		}
	};

	const loadMorePosts = () => {
		setLoading(true);
		specifucPosts(currentPage + 1, activeTab);
		setTimeout(() => {
			setCurrentPage((prev) => prev + 1);
		}, 2000);
	};

	return (
		<div
			className="profile"
			id="postScroll"
		>
			{userData?.userId && (
				<>
					<div className="profile-header-profile">
						<div className="profile-header__banner">
							<img
								src={userData.coverPicture || profile_default.coverPhoto}
								alt=""
							/>
						</div>
						<div className="profile-header__avatar">
							<img
								src={userData.picture || profile_default.profileImage}
								alt="profile image"
							/>
						</div>
						<div className="profile-header__space"></div>
					</div>
					<div className="profile-details">
						<div className="profile-details__profile-name">
							<div className="profile-details__profile-name_name">
								<h4>{userData.styledUsername || profile_default.username}</h4>
								{userData.verifyStatus?.verified && (
									<MdVerified
										className="verified"
										color="var(--orange)"
										size={12}
									/>
								)}
							</div>
							<h1>{userData.name || profile_default.username}</h1>
						</div>
						<div className="age-clock__wrapper">
							<details>
								<summary
									onClick={() => {
										setToggleAgeClock((v) => !v);
									}}
								>
									Age clock
								</summary>
								{userData.showAgeClock ? (
									<div className="age-clock">
										<span id="your-age">0</span>:
										<span class="daysAccumulated">
											<span id="daysAccumulated">0</span>
										</span>
										:<span id="hours">00</span>:<span id="minutes">00</span>:
										<span id="seconds">00</span>
									</div>
								) : (
									<div className="locked_ageclock">Locked</div>
								)}
							</details>
						</div>
						<div className="profile-details__bio">
							<p>{userData.occupation}</p>
						</div>

						<div className="profile-info__actions-top">
							{userData.isBoosted && <button>Boosted Account</button>}
							<button
								onClick={() => {
									dispatch(followOrUnfollow(userData.username));
								}}
							>
								{user.following.members.includes(userData.userId)
									? "unfollow"
									: "Follow"}
							</button>
						</div>
					</div>
					<div className="profile-info">
						<div className="profile-info__data">
							<div className="profile-info__data-posts">
								<h3>{myPosts.allPosts?.totalPosts || 0}</h3>
								<p>Posts</p>
							</div>
							<div className="profile-info__data-follow">
								<h3>{userData.followers?.members?.length || 0}</h3>
								<p>Followers</p>
							</div>
							<div className="profile-info__data-follows">
								<h3>{userData.following?.members?.length || 0}</h3>
								<p>Following</p>
							</div>
						</div>
						<div className="profile-info__tabs">
							<div className="tabs">
								<button
									className={activeTab === "allPosts" ? "active" : ""}
									onClick={() => {
										setActiveTab("allPosts");
										specifucPosts(0, "allPosts");
									}}
								>
									Posts
								</button>
								<button
									className={activeTab === "textPosts" ? "active" : ""}
									onClick={() => {
										setActiveTab("textPosts");
										specifucPosts(1, "textPosts");
									}}
								>
									Texts
								</button>
								<button
									className={activeTab === "imagePosts" ? "active" : ""}
									onClick={() => {
										setActiveTab("imagePosts");
										specifucPosts(1, "imagePosts");
									}}
								>
									Images
								</button>
								<button
									className={activeTab === "videoPosts" ? "active" : ""}
									onClick={() => {
										setActiveTab("videoPosts");
										specifucPosts(1, "videoPosts");
									}}
								>
									Videos
								</button>
								<button
									className={activeTab === "audioPosts" ? "active" : ""}
									onClick={() => {
										setActiveTab("audioPosts");
										specifucPosts(1, "audioPosts");
									}}
								>
									Audios
								</button>
								<button
									className={activeTab === "starredPosts" ? "active" : ""}
									onClick={() => {
										setActiveTab("starredPosts");
										specifucPosts(1, "starredPosts");
									}}
								>
									Favorites
								</button>
							</div>
						</div>
						<div className="profile-info__actions">
							{/* <button
								className="chat"
								onClick={() => navigate("/chat")}
							>
								Chat
							</button> */}
						</div>
					</div>
					{myPosts[activeTab].loading ? (
						<p className="No-post">
							{Loader(
								"syncloader",
								myPosts[activeTab].loading,
								undefined,
								10,
								"gray"
							)}
						</p>
					) : myPosts[activeTab].posts?.length > 0 ? (
						<>
							<PostComponent
								posts={myPosts[activeTab].posts}
								removeScroll
								react={react}
								save={save}
								dispatch={dispatch}
								parentId="postScroll"
								loadMorePosts={loadMorePosts}
							/>
						</>
					) : (
						<NoProfilePost />
					)}
				</>
			)}
		</div>
	);
};

export default OthersProfile;
