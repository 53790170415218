import React, { useEffect, useState } from "react";
import "./widgets.css";
import ActivityWidgets from "./Activity Widgets/ActivityWidgets";
import SuggestedWidgets from "./Suggested Widgets/SuggestedWidgets";
import AdvertWidget from "./Advert Widget/AdvertWidget";

const Widgets = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold based on your tablet size
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize); 

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Your logic when screen size is less than tablet size
    if (isMobile) {
      console.log("Mobile or Tablet Screen");
    }
  }, [isMobile]);
  
  return !isMobile ? (
    <div className="widgets">
      <ActivityWidgets />
      <SuggestedWidgets />
      <AdvertWidget />
    </div>
  ) : null;
};

export default Widgets;
