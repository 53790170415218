import React, { useEffect } from "react";
import "./suggestedWidgets.css";
import UserWidgets from "../User Widgets/UserWidgets";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { suggestAccounts } from "../../../store/slices/auth";

const SuggestedWidgets = () => {
	const dispatch = useDispatch();
	const suggestedAccounts = useSelector(
		(state) => state.entities.auth.suggestedAccounts
	);

	useEffect(() => {
		dispatch(suggestAccounts());
		return () => {};
	}, [dispatch]);

	return (
		<div className="suggested-widgets">
			<div className="suggested-widgets__header">
				<h3>Suggested </h3>
				{/* <Link
					to=""
					className="suggested-widgets__see-all"
				> */}
				<p>See all</p>
				{/* </Link> */}
			</div>
			<div className="suggested-widgets__flow">
				{suggestedAccounts.length > 0
					? suggestedAccounts.map((user) => {
							return (
								<UserWidgets
									userImage={user.picture}
									userName={user.username}
									isVerified={user.isVerified}
									isFollowing={false}
									notification="suggested for you"
									country={user.country}
									userId={user._id}
                  isBoosted={user.isBoosted}
								/>
							);
					  })
					: "No accounts"}
			</div>
		</div>
	);
};

export default SuggestedWidgets;
