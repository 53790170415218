import { useNavigate } from "react-router-dom";
import React from "react";
import "./chatList.css";
import { BsSearch } from "react-icons/bs";
import { TiArrowBack } from "react-icons/ti";
import ChatWindow from "./ChatWindow/ChatWindow";
import {
  allisonCarder,
  anikaGeorge,
  beckyAnderson,
  jocelynBator,
  joeclynMango,
  kadinVaccaro,
  mariaBaptista,
} from "../../../assets";

const ChatList = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleChatWindow = (id, avatar, userName) => {
    // Pass the selected user's data via state
    navigate(`/chat/${id}`, { state: { avatar, userName } });
  };

  const ChatWindowsData = [
    {
      id: "1",
      userName: "Anika George",
      type: "notification",
      message: "typing...",
      timeStamp: "5 min",
      count: "1",
      avatar: anikaGeorge,
      onClick: () => handleChatWindow("1", anikaGeorge, "Anika George"),
    },
    {
      id: "2",
      userName: "Becky Anderson",
      message: "You can proceed with the stuff",
      timeStamp: "8 min",
      avatar: beckyAnderson,
      onClick: () => handleChatWindow("2", beckyAnderson, "Becky Anderson"),
    },
    {
      id: "3",
      userName: "Maria Baptista",
      messageStatus: "read",
      message: "I can see you, I’m close",
      timeStamp: "12 min",
      avatar: mariaBaptista,
      onClick: () => handleChatWindow("3", mariaBaptista, "Maria Baptista"),
    },
    {
      id: "4",
      userName: "Allison Carder",
      type: "message",
      media: "voiceNote",
      message: "1:31",
      timeStamp: "19 min",
      avatar: allisonCarder,
      onClick: () => handleChatWindow("4", allisonCarder, "Allison Carder"),
    },
    {
      id: "5",
      userName: "Jocelyn Bator",
      type: "message",
      messageStatus: "sent",
      message: "I applied it already",
      timeStamp: "19 min",
      avatar: jocelynBator,
      onClick: () => handleChatWindow("5", jocelynBator, "Jocelyn Bator"),
    },
    {
      id: "6",
      userName: "Kadin Vaccaro",
      type: "media",
      media: "video",
      message: "Sent a video",
      timeStamp: "37 min",
      avatar: kadinVaccaro,
      onClick: () => handleChatWindow("6", kadinVaccaro, "Kadin Vaccaro"),
    },
  ];

  return (
    <div className="chat-list">
      <div className="chat-list__search">
        <div className="chat-list__search-box">
          <BsSearch size={20} />
          <input
            type="search"
            name="search"
            id="search"
            placeholder="Search"
            autoComplete="off"
          />
        </div>
      </div>
      <div className="chat-window__list">
        {ChatWindowsData.map((chatWindow, index) => (
          <ChatWindow key={index} {...chatWindow} />
        ))}
      </div>
      <TiArrowBack onClick={handleBack} className="go-back" size={48} />
    </div>
  );
};

export default ChatList;
