import "./App.css";
import { Feed, Navbar } from "./components";
import { Page, Chat, AdvertPage, Profile, CreatePost } from "./pages";
import { Login, Signup, ResetPassword } from "./pages/Authentication";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UpdateProfile from "./pages/updateprofile/UpdateProfile";
/* import ChangePassword from "./pages/Authentication/ChangePassword"; */
import DeleteAccount from "./pages/deleteaccount/DeleteAccount";
import SplashPage from "./pages/splashpage/SplashPage";
import TermsOfServices from "./pages/termsofservice/TermsOfService";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import ProtectedRoute from "./protectedRoute";
import BoostAccount from "./pages/boostaccount/BoostAccount";
import Subscribe from "./pages/subscribe/Subscribe";
import PromoteCatalog from "./pages/promotecatalog/PromoteCatalog";
import PremiumSuccess from "./pages/payment-successful/Premiumsubscription";
import PromotedPostMessage from "./pages/payment-successful/Promotepost";
import AccountBoostSuccessful from "./pages/payment-successful/BoostAccount";

function App() {
	return (
		<div className="App">
			<Navbar />
			<Router>
				<Routes>
					<Route
						path="/chat"
						element={
							<ProtectedRoute>
								<Chat />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/"
						element={<SplashPage />}
					/>
					<Route
						path="advert"
						element={<AdvertPage />}
					/>
					<Route
						path="/Login"
						element={<Login />}
					/>
					<Route
						path="/SignUp"
						element={<Signup />}
					/>
					<Route
						path="/TermsOfServices"
						element={<TermsOfServices />}
					/>
					<Route
						path="/PrivacyPolicy"
						element={<PrivacyPolicy />}
					/>
					<Route
						path="/resetPassword"
						element={<ResetPassword />}
					/>
					<Route
						path="/update-profile"
						element={
							<ProtectedRoute>
								<UpdateProfile />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/change-password"
						element={<ResetPassword />}
					/>
					{/* Hemmher Experimental Routes */}
					{/* <Route path="/change-password" element={<ChangePassword />} /> */}
					<Route
						path="/delete-account"
						element={
							<ProtectedRoute>
								<DeleteAccount />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/boostaccount"
						element={
							<ProtectedRoute>
								<BoostAccount />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/subscribe"
						element={
							<ProtectedRoute>
								<Subscribe />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/promote-catalog"
						element={
							<ProtectedRoute>
								<PromoteCatalog />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/verified-premium_subscription"
						element={<PremiumSuccess />}
					/>
					<Route
						path="/promoted-post"
						element={<PromotedPostMessage />}
					/>
					<Route
						path="/account-boosted"
						element={<AccountBoostSuccessful />}
					/>

					<Route
						path="*"
						element={
							<ProtectedRoute>
								<Page />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
