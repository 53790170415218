import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	FaRegComment,
	FaHeart,
	FaRegHeart,
	FaStar,
	FaRegTrashAlt,
	FaArrowDown,
	FaArrowLeft,
	FaArrowRight,
	FaRetweet,
} from "react-icons/fa";
import { BsSend } from "react-icons/bs";
import {
	MdOutlineRemoveRedEye,
	MdOutlineShare,
	MdVerified,
} from "react-icons/md";
import "./pinDetails.css";
import { useDispatch, useSelector } from "react-redux";
import {
	comment,
	Load__pinDetails,
	Load__suggestedPosts,
	react,
	share,
	suggeation_react,
	toggleFavouriteStatus,
} from "../../store/slices/post_details";
import { user_Helper } from "../../store/helpers/user";

import moment from "moment";

import PostComponent from "../../components/post/post";
import { getFileExtension, getMediaType } from "../../utils/media";
import CommentSection from "../interactions/comments/CommentSection";
import { AiOutlineDelete } from "react-icons/ai";
import FullscreenModal from "../Profile/ProfilePost/fullScreen";
import toast from "react-hot-toast";
import { Loader } from "../../utils/loader/loader";

const PinDetails = () => {
	const pinDetails = useSelector((state) => state.entities.posts.postDetail);
	const user = useSelector((state) => state.entities.auth);
	const dispatch = useDispatch();
	const { postId } = useParams();
	const navigate = useNavigate();
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [fullscreenImageSrc, setFullscreenImageSrc] = useState("");
 console.log(pinDetails.suggestedPosts,"suggestedposts")
	const prevImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? pinDetails.post.media.length - 1 : prevIndex - 1
		);
	};

	const nextImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === pinDetails.post.media.length - 1 ? 0 : prevIndex + 1
		);
	};
	const handleImageClick = (src) => {
		setFullscreenImageSrc(src);
		setIsFullscreen(true);
	};
	const handleCloseFullscreen = () => {
		setIsFullscreen(false);
		setFullscreenImageSrc("");
	};
	const copyToClipboard = (postId) => {
		const url = `${window.location.origin}/post/${postId}`;
		navigator.clipboard.writeText(url).then(() => {
			toast.success("Link copied");
		});
	};

	const currentMediaUrl =
		pinDetails.post?.media?.length > 0
			? pinDetails.post.media[currentImageIndex]
			: "";
	const mediaType = getMediaType(currentMediaUrl);

	useEffect(() => {
		dispatch(Load__pinDetails(postId));
		dispatch(Load__suggestedPosts(postId));
	}, [dispatch, postId]);

	// Format full date-time
	const formatFullDateTime = (date) => {
		return moment(date).format("ddd MMM DD YYYY hh:mmA");
	};

	return (
		<>
			{pinDetails.loading ? (
				<p className="No-post">
					{Loader("syncloader", pinDetails.loading, undefined, 10, "gray")}
				</p>
			) : pinDetails?.post?._id ? (
				<div
					className="post-feed1"
					id="post-detail_top"
				>
					<div className="light_bg">
						{isFullscreen && (
							<FullscreenModal
								src={fullscreenImageSrc}
								onClose={handleCloseFullscreen}
							/>
						)}
						{/* <div className="back-button_2">
							<button onClick={() => navigate(-1)}>Back</button>
						</div> */}

						<div className="post-container">
							<div>
								<img
									src={pinDetails.post.user.picture}
									alt={`${pinDetails.post.user.name}'s avatar`}
									className="avatar_pinDetails
avatar_pinDetails"
									onClick={() => {
										navigate(`/user?username=${pinDetails.post.user.username}`);
									}}
								/>
							</div>
							<div
								className="user-detail"
								onClick={() => {
									navigate(`/user?username=${pinDetails.post.user.username}`);
								}}
							>
								<div className="displayName">
									<div>
										{pinDetails.post.user.name} {"  "}
									</div>
									<div>
										{pinDetails.post.user.subscription?.role ===
										"subscribed" ? (
											<MdVerified
												className="verified ml-1"
												color="var(--orange)"
												size={14}
											/>
										) : (
											""
										)}
									</div>
								</div>
								<div className="username_pindetails">
									@
									{user_Helper.username(
										pinDetails.post.user.username,
										"name",
										pinDetails.post.user.country,
										"code"
									)}
									<span className="timestamp">
										{" "}
										<span className="dot">·</span>{" "}
										{formatFullDateTime(pinDetails.post.date)}
									</span>
								</div>
							</div>
						</div>
						<div className="post-body">
							<div className="post-content">
								{pinDetails.post?.message}

								{pinDetails.post?.hashtags && (
									<div>
										<br />
										{pinDetails.post?.hashtags}
									</div>
								)}
							</div>
							<div className="post__media">
								<div className="image-carousel">
									{pinDetails.post.media.length > 1 && (
										<FaArrowLeft
											className="carousel-arrow left"
											onClick={prevImage}
										/>
									)}
									{mediaType === "image" && (
										<img
											src={currentMediaUrl}
											alt={`Media ${currentImageIndex + 1}`}
											className="fullscreen-media"
											onDoubleClick={() => {
												handleImageClick(currentMediaUrl);
											}}
										/>
									)}
									{mediaType === "video" && (
										<video
											controls
											className="fullscreen-media"
										>
											<source
												src={currentMediaUrl}
												type={`video/${getFileExtension(currentMediaUrl)}`}
											/>
											Your browser does not support the video tag.
										</video>
									)}
									{mediaType === "audio" && (
										<div className="fullscreen-media_audio">
											<audio controls>
												<source
													src={currentMediaUrl}
													type={`audio/${getFileExtension(currentMediaUrl)}`}
												/>
												Your browser does not support the audio tag.
											</audio>
										</div>
									)}
									{pinDetails.post.media.length > 1 && (
										<FaArrowRight
											className="carousel-arrow right"
											onClick={nextImage}
										/>
									)}
								</div>
							</div>
							<div className="post-actions_2">
								<div className="profile-post__reactions-comments">
									{pinDetails.post.likes.includes(user.userId) ? (
										<FaHeart
											onClick={() => dispatch(react(pinDetails.post._id))}
											color="var(--crimson)"
										/>
									) : (
										<FaRegHeart
											onClick={() => dispatch(react(pinDetails.post._id))}
										/>
									)}
									<p>{pinDetails.post.likes.length}</p>
								</div>

								<div className="profile-post__reactions-comments">
									<a href="#comment">
										<FaRegComment size={18} />
									</a>
								</div>

								<div className="profile-post__reactions-star">
									<MdOutlineRemoveRedEye size={18} />
									<p>{pinDetails.post.views}</p>
								</div>
								<div className="profile-post__reactions-star">
									<MdOutlineShare
										size={18}
										onClick={() => {
											dispatch(share({ postId: pinDetails.post._id }));
											copyToClipboard(pinDetails.post._id);
										}}
									/>
									<p>{pinDetails.post.shares}</p>
								</div>
							</div>

							<CommentSection
								pinDetails={pinDetails}
								postId={pinDetails.post._id}
								comment={comment}
								comments={pinDetails.post.comment}
							/>
							<div className="expand-button">
								<button onClick={() => setIsExpanded((v) => !v)}>
									{isExpanded ? "Hide Catalogs" : "See More Catalogs"}{" "}
								</button>
							</div>
						</div>
					</div>
					{isExpanded && (
						<PostComponent
							dispatch={dispatch}
							posts={pinDetails.suggestedPosts}
							react={suggeation_react}
							removeScroll={true}
							scrollTo={"post-detail_top"}
							star={toggleFavouriteStatus}
							loadMorePosts=""
							parentId=""
						/>
					)}
				</div>
			) : (
				<p className="No-post-container">No post found</p>
			)}
		</>
	);
};

export default PinDetails;
