import React, { useEffect, useState } from "react";
import { BiX } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "../../../store/slices/profile";
import toast from "react-hot-toast";
import "./createPost.css";
import { Loader } from "../../../utils/loader/loader";

const CreatePost = ({ onClose }) => {
	const dispatch = useDispatch();
	const [postContent, setPostContent] = useState("");
	const [category, setCategory] = useState("");
	const [images, setImages] = useState([]);
	const auth = useSelector((state) => state.entities.auth);
	const loading = useSelector(
		(state) => state.entities.profile.makePost.loading
	);

	const [inputText, setInputText] = useState("");
	const [hashtagText, setHashtagText] = useState("");

	const openWidget = (e) => {
		e.preventDefault();
		document.getElementById("fileUpload").click();
	};

	const handleInputChange = (event) => {
		const text = event.target.value;
		setInputText(text);
		processHashtags(text);
	};

	const processHashtags = (text) => {
		const words = text
			.trim()
			.split(" ")
			.filter((word) => word !== "");
		const hashtags = words
			.map((word) => {
				return `#${word.trim()}`;
			})
			.join(" ");
		setHashtagText(hashtags);
		console.log(hashtagText);
	};

	const categories = [
		"Music",
		"Shows",
		"Concerts",
		"Afrobeats",
		"Dance",
		"Comedy",
		"Tag Stories",
		"Movies",
		"Arts",
		"Museums",
		"Photography",
		"Sports",
		"Football",
		"Basketball",
		"Food",
		"Fitness",
		"Quotes",
		"Health",
		"Yoga",
		"Religion",
		"Beauty & Kits",
		"Fashion",
		"FashionTV",
		"Stores",
		"Economy",
		"Education",
		"News",
		"Tourism",
		"House Decoration",
		"Cars",
		"Politics",
		"Religion",
		"Science",
		"Games",
		"Technology",
		"Space",
		"Pets",
		"Nature",
		"Others",
	];

	const subscriptionCategories = [
		"Shows",
		"Concerts",
		"Stores",
		"Arts",
		"Museums",
		"Tourism",
		"Beauty & Kits",
		"Fitness",
		"News",
		"FashionTV",
		"Cars",
		"House Decoration",
		"Space",
	];

	const handleClose = () => {
		if (typeof onClose === "function") {
			onClose();
		}
	};

	const handleFileChange = (e) => {
		const newFiles = Array.from(e.target.files);
		setImages((prev) => [...prev, ...newFiles]);
	};

	const removeFile = (index) => {
		setImages((prev) => prev.filter((_, i) => i !== index));
	};

	useEffect(() => {
		setCategory("");
		setHashtagText("");
		setImages([]);
		setPostContent("");
		setInputText("");
	}, [loading]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (images.length < 1 && !postContent) {
			return toast.error("You can't make empty posts");
		}
		if (!category) {
			return toast.error("Please choose a category");
		}
		e.preventDefault();
		if (
			subscriptionCategories.includes(category) &&
			!auth.verifyStatus.verified
		) {
			return toast.error("You need a subscription to post in this category");
		}

		const obj = {
			category,
			caption: "Nice caption",
			message: postContent,
			hashtags: hashtagText,
		};

		const formData = new FormData();
		formData.append("data", JSON.stringify(obj));

		for (const file of images) {
			formData.append("media", file);
		}
		dispatch(Post(formData));
		setCategory("");
		setHashtagText("");
		setImages([]);
		setPostContent("");
		setInputText("");
	};

	return (
		<div className="create-post_container">
			<div
				className="create-post 
      "
			>
				<form>
					<div className="create-post__header">
						<h2>Create Post</h2>
						<BiX
							size={32}
							className="close-icon"
							onClick={handleClose}
						/>
					</div>
					<div className="create-post__content">
						<h3>Content</h3>
						<textarea
							name="post"
							id="post"
							cols="300"
							rows="4"
							placeholder="Enter your message (maximum of 300 characters)"
							value={postContent}
							onChange={(e) => setPostContent(e.target.value)}
						></textarea>
					</div>
					<div className="create-post__category">
						<h3>Category</h3>
						<select
							name="select"
							id="select"
							value={category}
							onChange={(e) => setCategory(e.target.value)}
						>
							<option
								value=""
								disabled
							>
								Select Catalog
							</option>
							{categories.map((cat) => (
								<option
									key={cat}
									value={cat}
								>
									{cat}
								</option>
							))}
						</select>
					</div>
					<div className="create-post__hashtag">
						<h3>Add Hashtag</h3>
						<input
							type="text"
							value={inputText}
							onChange={handleInputChange}
							placeholder="Enter text here"
							style={{ width: "100%", padding: "10px", fontSize: "16px" }}
						/>
						<div
							style={{
								marginTop: "20px",
								padding: "10px",
								backgroundColor: "#f0f0f0",
								borderRadius: "5px",
							}}
						>
							<p>
								<strong>Hashtags:</strong>
							</p>
							<p>{hashtagText}</p>
						</div>
					</div>
					<div className="create-post__media">
						<h3>Media:</h3>
						<div className="create-post__media-upload">
							<div className="file-previews">
								{images.length > 0 &&
									images.map((file, index) => {
										const fileURL = URL.createObjectURL(file);

										return (
											<div
												key={index}
												className="file-preview-item"
											>
												{file.type.startsWith("image/") && (
													<img
														src={fileURL}
														alt={file.name}
														width="100"
														height="100"
													/>
												)}
												{file.type.startsWith("video/") && (
													<video
														width="160"
														height="120"
														controls
													>
														<source
															src={fileURL}
															type={file.type}
														/>
														Your browser does not support the video tag.
													</video>
												)}
												{file.type.startsWith("audio/") && (
													<audio
														controls
														style={{ width: "150px" }}
													>
														<source
															src={fileURL}
															type={file.type}
														/>
														Your browser does not support the audio element.
													</audio>
												)}
												<button
													type="button"
													onClick={() => removeFile(index)}
													className="remove-file-btn"
												>
													Remove
												</button>
											</div>
										);
									})}
							</div>
							<input
								type="file"
								accept="image/*,video/*,audio/*"
								multiple
								id="fileUpload"
								hidden
								onChange={handleFileChange}
							/>
						</div>
					</div>
					{loading ? (
						<p className="No-post">
							{Loader("syncloader", loading, undefined, 10, "gray")}
						</p>
					) : (
						<div className="buttons">
							<button
								type="submit"
								onClick={openWidget}
								className="add"
							>
								Add media
							</button>
							<button
								type="submit"
								className="post"
								onClick={handleSubmit}
							>
								Post Catalog
							</button>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CreatePost;
