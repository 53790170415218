import React, { useState } from "react";
import "./profile-post.css";
import { MdVerified } from "react-icons/md";
import { FaHeart, FaRegHeart, FaRegComment, FaStar } from "react-icons/fa6";
import { BsSend } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const NoProfilePost = () => {
	const [isFilled, setIsFilled] = useState(false);
	const handleClick = () => {
		setIsFilled(!isFilled);
	};

	return <div className="profile-post text-center">No Post found</div>;
};

export default NoProfilePost;
